import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";

import { faArrowLeft, faHandPaper, faSave, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PackerSubmission } from "../../../model/Submission";
import { PackerValidationRules } from "../../../model/validation/PackerValidation";
import { EvaluateAllFormRulesToMessages } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { Disclamer } from "../../Common/Disclamer";
import LoadingWidget from "../../Common/Loading";
import SimpleTextareaField from "../../Common/simple-fields/SimpleTextareaField";
import { SimpleAlert } from "../../Common/SimpleConfirmAlert";
import { InputGroup } from "../../Common/SimpleFormComponents";
import { errorMessage, successMessage } from "../../Common/Toast";
import ContentHeader from "../../Template/ContentHeader";

interface Props {
    applicationStore?: ApplicationStore;
}

interface State {
    loading: boolean;
    wasValidated: boolean;
    signatureName: string;
}

@inject("applicationStore")
@observer
export default class PackerSignatureAndSubmission extends React.Component<Props, State> {
    private form: HTMLFormElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            wasValidated: false,
            signatureName: ""
        };
    }

    finalizeApplication = async () => {
        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.finalizeSubmission({
                signatureName: this.state.signatureName
            });
            successMessage("Application Successfully submitted.");
            SimpleAlert(
                <>
                    <p>Thank you, your application has been received. We will be in touch if we have any questions. </p>
                    <p>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                window.open(`${this.props.applicationStore!.submission!.pdfReceiptLink}?download=true`);
                            }}
                        >
                            Please download a copy of your submitted values by clicking here.
                        </a>
                    </p>
                    <p>After downloading your submission you can safely close this tab.</p>
                </>
            );
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    submit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (this.form && this.form.checkValidity()) {
            this.setState({ wasValidated: false });
            this.finalizeApplication();
        } else {
            this.setState({ wasValidated: true });
        }
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const member = applicationStore.submission!.member;
        const currentDate = moment(new Date()).format("MM/DD/YYYY");
        const completed = (applicationStore.submission as PackerSubmission).completed;
        const { form4Validated } = this.props.applicationStore!;
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Signature and Submission" />
                <div className="row content">
                    <div className="col-12 mt-3">
                        {!form4Validated && (
                            <div className="alert alert-danger">
                                <div className="row">
                                    <div className="col-12">
                                        <FontAwesomeIcon icon={faHandPaper} />
                                        &nbsp; There are data missing on &nbsp;
                                        <Link to="/packer/form-4-packers-information">Form 4 - Packer's Information</Link>. Please return to that page
                                        and fill in the following sections to continue:
                                        <ul className="mt-3">
                                            {EvaluateAllFormRulesToMessages(PackerValidationRules.form4, applicationStore.submission!).map(
                                                (msg, idx) => (
                                                    <li key={idx}>{msg}</li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                        {form4Validated && (
                            <div className="alert alert-warning">
                                Please review the information entered in the previous sections. Once the application is submitted, it cannot be edited
                                anymore.
                            </div>
                        )}
                    </div>
                    {form4Validated && (
                        <>
                            <div className="col-12 mt-3">
                                <div className="card shadow">
                                    <div className="card-header">Signature &amp; Submission</div>
                                    <div className="card-body">
                                        <div className="row mb-0">
                                            <div className="col-12 text-center">
                                                <div className="alert alert-info">
                                                    <p>
                                                        The undersigned agrees to engage in the packing of greenhouse vegetables in accordance with
                                                        the provisions of the Farm Products Marketing Act, the regulations under the Farm Products
                                                        Marketing Act, the regulations of the local board and all other regulations, orders and
                                                        directions from the local board or from the Ontario Farm Products Marketing Commission, and
                                                        understands that in default thereof, any licence to pack greenhouse vegetables may be
                                                        suspended or revoked by the local board.
                                                    </p>

                                                    <p>
                                                        The undersigned applicant(s) certifies that all information submitted herein is complete and
                                                        accurate.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <form
                                                    id="signature-and-submission"
                                                    ref={form => (this.form = form)}
                                                    className={"needs-validation" + (this.state.wasValidated ? " was-validated" : "")}
                                                    onSubmit={e => this.submit(e)}
                                                    noValidate={true}
                                                >
                                                    <InputGroup
                                                        name="signatureName"
                                                        type="text"
                                                        label="Name of Applicant:"
                                                        isRequired={true}
                                                        disabled={this.props.applicationStore!.completed}
                                                        onChange={e => this.setState({ signatureName: e.target.value })}
                                                        value={completed ? completed.signatureName : this.state.signatureName || ""}
                                                    />
                                                </form>
                                            </div>
                                            <div className="col-6">
                                                <label>Date:</label>
                                                <input className="form-control" type="text" value={currentDate} readOnly={true} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <SimpleTextareaField label="Notes:" rows={3} fieldName="finalNotes" />
                                            </div>
                                        </div>
                                        <div className="row pt-4">
                                            <div className="col-12 text-center">
                                                <Link to="/packer/form-4-packers-information" className="btn btn-secondary px-3">
                                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                                                </Link>
                                                <button
                                                    type="submit"
                                                    disabled={this.props.applicationStore!.completed}
                                                    className="btn btn-primary px-3 ml-3"
                                                    form="signature-and-submission"
                                                >
                                                    {this.state.loading ? (
                                                        <div className="text-center">
                                                            <LoadingWidget className="mr-2" />
                                                            Submitting Application...
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faSave} className="mr-3" />
                                                            {completed && completed.dateCompleted > 0
                                                                ? `Application Submitted`
                                                                : `Submit Application`}
                                                        </>
                                                    )}
                                                </button>
                                                {this.props.applicationStore!.completed && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-success px-3 ml-3"
                                                        onClick={() =>
                                                            window.open(`${this.props.applicationStore!.submission!.pdfReceiptLink}?download=true`)
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faDownload} /> Download PDF of Responses
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <Disclamer />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
