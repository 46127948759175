import { action, computed, observable } from "mobx";

import { SubmissionEndpoints } from "../endpoints/SubmissionEndpoints";
import { Configuration } from "../model/Configuration";
import { ConfirmUpdate } from "../model/ConfirmUpdate";
import { GrowerSubmission, Submission, PackerSubmission, MarketerSubmission } from "../model/Submission";
import { GrowerValidationRules } from "../model/validation/GrowerValidation";
import { EvaluateAllRulesForForm, EvaluateAllRulesToMessages } from "../model/validation/Validation";
import { AttachmentRequest } from "../requests/AttachmentRequest";
import { CropProductionRequest } from "../requests/CropProductionRequest";
import { FinalizeRequest } from "../requests/FinalizeRequest";
import { FutureExpansionRequest } from "../requests/FutureExpansionRequest";
import { ProprietaryCommodityRequest } from "../requests/ProprietaryCommodityRequest";
import {
    ConfirmUpdateOutcomeRequest,
    ConfirmUpdateUpdatedRequest,
    ConfirmUpdateExpectedRequest,
    GenericFormRequest,
    SimpleFieldRequest
} from "../requests/SimpleFieldRequests";
import { ValidationStore } from "./ValidationStore";
import { PackerValidationRules } from "../model/validation/PackerValidation";
import { MarketerValidationRules } from "../model/validation/MarketerValidation";
import { CommodityFriendlyName } from "../model/ProprietaryCommodity";

const LOCAL_STORAGE_TOKEN = "dattivo.ogvg.currentToken";
const LOCAL_STOAGE_KEY = "dattivo.ogvg.currentLinkKey";
const ADMIN_LINK_CONSTANT = "$n03k";
const KEY_STORAGE_LENGTH = 1800000; // 30mins

export class ApplicationStore {
    // Try something new: let"s leave this one public so other widgets can observe and make changes to it
    @observable loading: boolean = false;

    @observable private _ready: boolean = false;
    @observable private _currentLinkKey: string | null = null;
    @observable private _currentToken: string = "";
    @observable private _configuration: Configuration | null = null;
    @observable private _submission: Submission | null = null;

    private _flashMessage: string | null = null;

    constructor(private submissionEndpoints: SubmissionEndpoints, private validationStore: ValidationStore) { }

    async onReady() {
        if (window.location.hash.indexOf("#/login") !== 0) {
            this.readLocalStorageToken();
            if (this._currentToken !== "") {
                try {
                    console.log("Found token in storage, trying that first...");
                    await this.refreshSubmission();
                    await this.refreshConfiguration();
                } catch (e) {
                    console.error("Error while reusing existing token", e);
                }
            }

            const linkKey = this.readLocalStorageLinkKey();
            if (linkKey) {
                this.setLinkKey(linkKey);
            }
        }

        this._ready = true;
    }

    @computed get ready(): boolean {
        return this._ready;
    }

    // ------
    // Login/token related
    // ------

    @action setLinkKey(key: string) {
        this._currentLinkKey = key;
        this.updateLocalStorageLinkKey(key);
        if (key.indexOf(ADMIN_LINK_CONSTANT) === 0) {
            try {
                console.log("Found admin token in storage, trying that first...");
                this.adminLogin(key);
            } catch (e) {
                console.error("Error while using admin token", e);
            }
        }
    }

    @action async login(postalCode: string, recaptchaResponse: string | undefined, key: string | null = this._currentLinkKey): Promise<Submission> {
        if (key === null) {
            throw new Error("Can't login without a key specified.");
        }
        this._submission = await this.submissionEndpoints.login({ postalCode, recaptchaResponse, key });
        this._configuration = await this.submissionEndpoints.refreshConfiguration();
        return this._submission;
    }

    @action async adminLogin(key: string | null = this._currentLinkKey): Promise<Submission> {
        if (key === null) {
            throw new Error("Can't login without a key specified.");
        }
        this._submission = await this.submissionEndpoints.login({ postalCode: "", key });
        this._configuration = await this.submissionEndpoints.refreshConfiguration();
        return this._submission;
    }

    @action async timedOut() {
        // On time out, we always clear the token
        this._submission = null;
        this._currentToken = "";
        this.clearLocalStorageToken();
        // And clear the saved key if it's expired too
        if (!this.readLocalStorageLinkKey()) {
            this._currentLinkKey = null;
            this.clearLocalStorageLinkKey();
        }
        // Set the flash message
        this._flashMessage = "For your security, your session has expired. Please login again to pick up where you left off.";
    }

    @action updateToken(token: string, writeLocalStorage: boolean = true) {
        this._currentToken = token;
        if (writeLocalStorage) {
            this.updateLocalStorageToken(token);
        }
    }

    @action logout(setFlashMessage: boolean = true) {
        this._submission = null;
        this._currentToken = "";
        this._currentLinkKey = null;
        this.clearLocalStorageToken();
        this.clearLocalStorageLinkKey();
        if (setFlashMessage) {
            this._flashMessage = "You have successfully logged out.";
        }
    }

    @computed get currentLinkKey(): string | null {
        return this._currentLinkKey;
    }

    @computed get loggedIn(): boolean {
        return this._currentLinkKey !== null && this._currentToken !== "" && this._submission !== null;
    }

    @computed get currentToken(): string {
        return this._currentToken;
    }

    @computed get flashMessage(): string | null {
        const message = this._flashMessage;
        this._flashMessage = null;
        return message;
    }

    // ------
    // Configuration and Submission
    // ------

    @action async refreshSubmission(): Promise<Submission> {
        this._submission = await this.submissionEndpoints.getSubmission();
        return this._submission;
    }

    @action async refreshConfiguration(): Promise<Configuration> {
        this._configuration = await this.submissionEndpoints.refreshConfiguration();
        return this._configuration;
    }

    @computed get configuration() {
        return this._configuration;
    }

    // ------
    // Submission
    // ------

    @computed get submission(): Submission | null {
        return this._submission;
    }

    @computed get completed(): boolean {
        return (
            (this.submission && this.submission.completed && this.submission.completed.dateCompleted > 0) ||
            (this.submission && this.submission.deactivated) ||
            false
        );
    }

    // ------
    // Simple Fields
    // ------

    @action async updateSimpleFields(request: SimpleFieldRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateSimpleFields(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async updateConfirmUpdateOutcome(request: ConfirmUpdateOutcomeRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateConfirmUpdateOutcome(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async updateConfirmUpdateUpdated(request: ConfirmUpdateUpdatedRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateConfirmUpdateUpdated(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }
    @action async updateConfirmUpdateExpected(request: ConfirmUpdateExpectedRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateConfirmUpdateExpected(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async updateConfirmUpdateOutcomeAndUpdated(outcomeRequest: ConfirmUpdateOutcomeRequest, updatedRequest: ConfirmUpdateUpdatedRequest) {
        try {
            this.loading = true;
            await this.submissionEndpoints.updateConfirmUpdateOutcome(outcomeRequest);
            this._submission = await this.submissionEndpoints.updateConfirmUpdateUpdated(updatedRequest);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    // ------
    // Forms
    // ------

    @action async updateGeneralForm(request: GenericFormRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateGenericForm(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    findSimpleFieldValue(fieldName: string): string | undefined {
        if (!this.submission) {
            console.error("Can't access simple field before it's ready.");
            return;
        }
        return this.submission.simpleFields[fieldName];
    }

    findConfirmUpdate(fieldName: string): ConfirmUpdate | undefined {
        if (!this.submission) {
            console.error("Can't access confirm-update field before it's ready.");
            return;
        }
        return this.submission.confirmUpdates[fieldName];
    }

    findGeneralFormValue(formName: string, fieldName: string): string | undefined {
        if (!this.submission) {
            console.error("Can't access simple field before it's ready.");
            return;
        }

        if (this.submission.generalForms[formName]) {
            var x = this.submission.generalForms[formName][fieldName];
            return x + "";
        }
        else {
            return undefined;
        }
        // return this.submission.generalForms[formName] ? this.submission.generalForms[formName][fieldName] : undefined;
    }

    // ------
    // Crop Production Details
    // ------

    @action async createProprietaryCommodity(request: ProprietaryCommodityRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.createProprietaryCommodity(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async editProprietaryCommodity(proprietaryCommodityId: number, request: ProprietaryCommodityRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateProprietaryCommodity(proprietaryCommodityId, request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async deleteProprietaryCommodity(proprietaryCommodityId: number): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.deleteProprietaryCommodity(proprietaryCommodityId);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async createCropProduction(request: CropProductionRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.createCrop(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async editCropProduction(cropId: number, request: CropProductionRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateCrop(cropId, request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async deleteCropProduction(cropId: number): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.deleteCrop(cropId);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    // ------
    // Future Expansions
    // ------

    @action async createFutureExpansion(request: FutureExpansionRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.createFutureExpansion(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async editFutureExpansion(futureExpansionId: string, request: FutureExpansionRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.updateFutureExpansion(futureExpansionId, request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async deleteFutureExpansion(futureExpansionId: string): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.deleteFutureExpansion(futureExpansionId);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    // ------
    // Attachments
    // ------

    @action async addAttachment(request: AttachmentRequest): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.addAttachment(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    @action async deleteAttachment(attachmentId: number): Promise<Submission> {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.deleteAttachment(attachmentId);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    // ------
    // Finalize submission
    // ------

    @action async finalizeSubmission(request: FinalizeRequest) {
        try {
            this.loading = true;
            this._submission = await this.submissionEndpoints.finalizeSubmission(request);
            return this._submission;
        } finally {
            this.loading = false;
        }
    }

    // ------
    // Validation fields
    // ------

    @computed get form2Validated(): boolean {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "GROWER") {
            console.error("form2Validated can only be called for growers");
            return false;
        }

        const submission = this.submission as GrowerSubmission;
        return EvaluateAllRulesForForm(GrowerValidationRules.form2, submission);
    }

    @computed get form2AValidated(): boolean | undefined {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "GROWER") {
            console.error("form2AValidated can only be called for growers");
            return false;
        }

        const validation = this.validationStore.checkFormValidity("goi", this);
        return validation;
    }

    @computed get cropProductionValidated(): boolean | undefined {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "GROWER") {
            console.error("cropProductionValidated can only be called for growers");
            return false;
        }

        const submission = this.submission as GrowerSubmission;
        return EvaluateAllRulesForForm(GrowerValidationRules.CropProduction, submission);
    }

    @computed get form4Validated(): boolean {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "PACKER") {
            console.error("form4Validated can only be called for packers");
            return false;
        }

        const submission = this.submission as PackerSubmission;
        return EvaluateAllRulesForForm(PackerValidationRules.form4, submission);
    }

    @computed get form5Validated(): boolean {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "MARKETER") {
            console.error("form5Validated can only be called for marketers");
            return false;
        }

        const submission = this.submission as MarketerSubmission;
        return EvaluateAllRulesForForm(MarketerValidationRules.form5, submission);
    }

    @computed get marketerAttachmentsValidated(): boolean {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "MARKETER") {
            console.error("marketerAttachmentsValidated can only be called for marketers");
            return false;
        }

        const submission = this.submission as MarketerSubmission;
        return EvaluateAllRulesForForm(MarketerValidationRules.attachments, submission);
    }

    @computed get form5AValidated(): boolean {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "MARKETER") {
            console.error("form5AValidated can only be called for marketers");
            return false;
        }

        const submission = this.submission as MarketerSubmission;
        return EvaluateAllRulesForForm(MarketerValidationRules.form5A, submission);
    }

    @computed get form5BValidated(): boolean {
        if (!this.submission) {
            console.error("Can't access submission before it's ready.");
            return false;
        }
        if (this.submission.member.memberType !== "MARKETER") {
            console.error("form5BValidated can only be called for marketers");
            return false;
        }

        const submission = this.submission as MarketerSubmission;
        return EvaluateAllRulesForForm(MarketerValidationRules.form5B, submission);
    }

    // ------
    // Utility methods
    // ------

    private updateLocalStorageToken(token: string) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
    }

    private clearLocalStorageToken() {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    }

    private readLocalStorageToken() {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
        if (token) {
            this.updateToken(token, false);
        }
    }

    private updateLocalStorageLinkKey(linkKey: string) {
        localStorage.setItem(LOCAL_STOAGE_KEY, JSON.stringify({ linkKey, dateCreated: new Date().getTime() }));
    }

    private clearLocalStorageLinkKey() {
        localStorage.removeItem(LOCAL_STOAGE_KEY);
    }

    private readLocalStorageLinkKey() {
        const linkKeyObjectString = localStorage.getItem(LOCAL_STOAGE_KEY);
        if (linkKeyObjectString) {
            const linkKeyObject = JSON.parse(linkKeyObjectString);
            // If it was saved in the past 30 minutes, then use it
            if (linkKeyObject.dateCreated > new Date().getTime() - KEY_STORAGE_LENGTH) {
                return linkKeyObject.linkKey;
            }
            // If we have an expired one sitting around, then clear it
            localStorage.removeItem(LOCAL_STOAGE_KEY);
        }
    }
}
