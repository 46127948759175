import * as React from "react";
import { observer } from "mobx-react";

interface State {
    value: string;
}

interface Props {
    fieldName: string;
    value: string;
    disabled?: boolean;
    placeholder?: string;
    synchronizeValue?: boolean;
    className?: string;
    strikethrough?: boolean;
    onBlur: (newValue: string) => void;
    notCheckUpdated?: boolean;
    rows?: number;
}

@observer
export default class TextareaField extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.value !== "") {
            this.setState({ value: this.props.value });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // if (this.props.synchronizeValue) {
        if ((this.props.synchronizeValue || prevProps.synchronizeValue) && this.props.value !== this.state.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        const updated = this.props.notCheckUpdated === true ? false : this.state.value !== this.props.value;

        return (
            <div className="input-group">
                <textarea
                    rows={this.props.rows ? this.props.rows : 2}
                    className={`form-control ${updated ? `text-danger` : ``} ${this.props.className ? this.props.className : ``} ${
                        this.props.strikethrough ? "strikethrough" : ""
                    }`}
                    name={this.props.fieldName}
                    placeholder={this.props.placeholder || ""}
                    value={this.state.value}
                    disabled={this.props.disabled}
                    onChange={e => this.setState({ value: e.target.value })}
                    onBlur={() => {
                        if (
                            (this.props.value !== "" && this.props.value !== this.state.value) ||
                            (this.props.value === "" && this.state.value !== "")
                        ) {
                            this.props.onBlur(this.state.value);
                        }
                    }}
                    autoComplete="off"
                />
            </div>
        );
    }
}
