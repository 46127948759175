import * as React from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MarketerGrowerUpdate, NatureOfRelationship, OneToManyDiscardedFlag } from "../../../model/ConfirmUpdate";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import InputField from "../form-elements/InputField";
import { EmptyGroup } from "../form-elements/SimpleFormComponents";

export interface State {
    seelingSideRoadStandPercentage: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    value: MarketerGrowerUpdate & OneToManyDiscardedFlag;
    loading?: boolean;
    disabled?: boolean;
    synchronizeValue?: boolean;
    onFieldChange: (fieldName: string, newValue: string | number | null) => any;
}

export default class TraceabilityCodeMarketerGrowerWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            seelingSideRoadStandPercentage: ""
        };
    }

    render() {
        const onChange = (fieldName: string, newValue: string | number | null) => {
            if (this.props.loading) {
                return;
            }
            this.props.onFieldChange(fieldName, newValue);
        };

        const disabledCalculated = this.props.disabled || /*this.props.loading ||*/ this.props.value.discarded;
        const strikethrough = !!this.props.value.discarded;

        return (
            <div style={{ backgroundColor: "#f4f4f4" }}>
                <button className="btn btn-sm btn-info one-to-many-delete-button">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
                <div className="card-body py-0">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="form-row">
                            <EmptyGroup label="Grower Name:" name="growers" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="growers"
                                    value={this.props.value.growers || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("growers", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Traceability Codes:" name="codes" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="codes"
                                    value={this.props.value.codes || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("codes", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Brand Name:" name="brandName" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="brandName"
                                    value={this.props.value.brandName || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("brandName", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            {/* TODO: Confirm that this logic mirrors what's in the GrowerMarketer reference */}
                            <div className="col-md-12 col-lg-6">
                                <label htmlFor="">
                                    Nature of Relationship:<span className="text-danger"> * </span>
                                </label>
                                <div className="row pt-1">
                                    <div className="col-6">
                                        <label className="">
                                            <input
                                                className="mr-2 ml-3"
                                                type="radio"
                                                name="relationship"
                                                disabled={disabledCalculated}
                                                checked={(this.props.value as MarketerGrowerUpdate).relationship === NatureOfRelationship.CONTRACT}
                                                onChange={() => onChange("relationship", NatureOfRelationship.CONTRACT)}
                                            />{" "}
                                            Contract
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <label>
                                            <input
                                                className="mr-2"
                                                type="radio"
                                                name="relationship"
                                                disabled={disabledCalculated}
                                                checked={
                                                    (this.props.value as MarketerGrowerUpdate).relationship === NatureOfRelationship.NOT_CONTRACT
                                                }
                                                onChange={() => onChange("relationship", NatureOfRelationship.NOT_CONTRACT)}
                                            />{" "}
                                            Non-Contract
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
