export const formatAmount = (value: number, showDollarSign?: boolean): string => {
    const amount = (value / 100).toLocaleString("en-CA", { style: "currency", currency: "CAD" });
    return showDollarSign ? amount : amount.replace(/\$/g, "");
};

export const FileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const data = (reader.result as string).split(",")[1];
            resolve(data);
        };
        reader.onerror = (e: any) => {
            console.error("error while converting file to base-64", e);
            reject(e);
        };
        reader.readAsDataURL(file);
    });
};

export const DownloadBlob = (fileName: string, blob: Blob) => {
    // For Microsoft Edge
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        // const url = (window.URL ? URL : webkitURL).createObjectURL(blob);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
};

export const CalcPercentage = (arrayOfPercentage: Array<string>): number => {
    return arrayOfPercentage
        .map((v: string) => parseFloat(v))
        .filter(v => !isNaN(v))
        .reduce((a, b) => a + b, 0);
};
