import { inject, observer } from "mobx-react";
import * as React from "react";

import { faExclamationCircle, faInfo, faTrash, faTrashRestore, faArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CalcPercentage } from "../../../assets/Common/FileUtil";
import {
    CopyGrowerMarketerCurrentToUpdate,
    GrowerMarketerCurrent,
    GrowerMarketerEmpty,
    GrowerMarketerUpdate,
    OneToManyConfirmUpdate,
    GrowerPackerCurrent,
    NatureOfRelationship
} from "../../../model/ConfirmUpdate";
import { CalculateOneToManyValidationState } from "../../../model/validation/Validation";
import InputField from "../form-elements/InputField";
import { EmptyGroup } from "../form-elements/SimpleFormComponents";
import { OneToManyWrapper } from "../OneToManyWrapper";
import { CommonProps, CommonState, OneToManyCommon } from "./OneToManyCommon";
import TraceabilityCodeGrowerMarketerWidget from "./TraceabilityCodeGrowerMarketerWidget";
import { LicenceYear } from "../../../model/Submission";

export interface State extends CommonState {}

interface Props extends CommonProps {}

@inject("applicationStore")
@observer
export default class TraceabilityCodesGrowerMarketerWidget extends OneToManyCommon<GrowerMarketerCurrent, GrowerMarketerUpdate, Props, State> {
    state: State = {
        loading: false
    };
    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission;
        if (!submission) {
            console.warn("Submission is not ready yet");
            return <div />;
        }
        const confirmUpdate = submission.confirmUpdates[this.props.confirmUpdateName];
        if (!confirmUpdate) {
            console.error(`Could not find ${this.props.confirmUpdateName} Confirm-Update in submission object`);
            return <div className="text-danger">Error while loading field. Please contact support.</div>;
        }

        const configuration = this.props.applicationStore!.configuration;
        if (!configuration) {
            return <div />;
        }

        const updated = this.buildUpdated();
        const updatedKeys = Object.keys(updated).filter(k => k !== "ROAD_SIDE");
        const roadSide = updated["ROAD_SIDE"];
        const synchronizeValue = confirmUpdate.outcome !== "UPDATED";
        const completed = applicationStore.completed;

        const calcPercentage = CalcPercentage(Object.keys(updated).map(key => (updated[key].discarded ? "0" : updated[key].percentage)));

        return (
            <OneToManyWrapper
                confirmUpdateOutcome={confirmUpdate.outcome}
                onConfirmUpdateChange={newValue => this.updateConfirmUpdateOutcome(newValue)}
                onAddNewChildren={() => this.addOneToMany()}
                label="Marketer(s)"
                loading={this.state.loading}
                validity={CalculateOneToManyValidationState(confirmUpdate)}
                hideConfirmUpdate={Object.keys(confirmUpdate.current).length === 0 || this.hideConfirmUpdateButtons(confirmUpdate)}
                onChangeDropDown={ () => function(){} }
            >
                <div className="card-body py-0 px-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-success">
                                <FontAwesomeIcon icon={faInfo} className="mr-3" />
                                If you have more than one traceablity code for each marketer, then list them all separated by a comma (e.g., 1234,
                                5678).
                                {this.hideConfirmUpdateButtons(confirmUpdate) && (
                                    <>
                                        <br />
                                        <br />
                                        <strong>Note that the Nature of Relationship field below was added in 2019.</strong> You will need to fill in
                                        this value for any existing traceability codes on file as well.
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {calcPercentage !== 100 && (
                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-warning text-center">
                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                    The sum of the percentages must be 100% (Currently {calcPercentage}%).
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="form-row">
                        <EmptyGroup label="Selling From a Side/Road Stand:" name="marketer" className="col-md-12 col-lg-6" isRequired={true}>
                            <div className="d-flex">
                                <InputField
                                    fieldName="roadSidePercentage"
                                    value={roadSide ? roadSide.percentage : ""}
                                    synchronizeValue={synchronizeValue}
                                    onBlur={(newValue: string) => this.onConfirmUpdateChangeOneToMany("ROAD_SIDE", "percentage", newValue)}
                                    disabled={confirmUpdate.outcome !== "UPDATED" || completed}
                                    type="number"
                                />
                                <div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>
                                    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>
                                        %
                                    </div>
                                </div>
                            </div>
                        </EmptyGroup>
                    </div>
                </div>

                <hr />

                {updatedKeys.length === 0 ? (
                    <div className="w-100-percent py-2 bg-one-to-many text-center">No values on file.</div>
                ) : (
                    updatedKeys.map(key => {
                        const value = updated[key];
                        return (
                            <span key={key}>
                                <div className="d-flex align-items-start w-100-percent mt-3">
                                    <div className="w-100-percent">
                                        <TraceabilityCodeGrowerMarketerWidget
                                            value={value}
                                            loading={this.state.loading}
                                            disabled={confirmUpdate.outcome !== "UPDATED" || completed}
                                            synchronizeValue={synchronizeValue || value.discarded}
                                            onFieldChange={(fieldName: string, newValue: string | number | null) => {
                                                this.onConfirmUpdateChangeOneToMany(key, fieldName, newValue);
                                            }}
                                        />
                                    </div>
                                    <div className="flex-shrink-0">
                                        {!value.discarded && (
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-danger px-2 ml-2 ${confirmUpdate.outcome !== "UPDATED" ? `disabled` : ``}`}
                                                disabled={confirmUpdate.outcome !== "UPDATED" || completed}
                                                onClick={() => this.deleteOneToMany(key)}
                                            >
                                                {parseInt(key, 10) > 0 ? (
                                                    <>
                                                        <FontAwesomeIcon icon={faArchive} className="mr-2" /> Archive
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon icon={faTrash} className="mr-2" /> Delete
                                                    </>
                                                )}
                                            </button>
                                        )}
                                        {value.discarded && (
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-secondary px-2 ml-2 ${
                                                    confirmUpdate.outcome !== "UPDATED" ? `disabled` : ``
                                                }`}
                                                disabled={confirmUpdate.outcome !== "UPDATED" || completed}
                                                onClick={() => this.undeleteOneToMany(key)}
                                            >
                                                <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
                                                Unarchive
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <hr />
                            </span>
                        );
                    })
                )}
            </OneToManyWrapper>
        );
    }

    protected emptyUpdate(): GrowerMarketerUpdate {
        return GrowerMarketerEmpty();
    }

    protected copyCurrentToUpdate(current: GrowerMarketerCurrent): GrowerMarketerUpdate {
        return CopyGrowerMarketerCurrentToUpdate(current);
    }

    protected forceUpdateCheck(confirmUpdate: OneToManyConfirmUpdate<GrowerMarketerCurrent, GrowerMarketerUpdate>): boolean {
        const licenceYear = this.props.applicationStore!.submission!.licenceYear.id;
        const currentPercentage = Object.values(confirmUpdate.current)
            .map(c => c.percentage)
            .reduce((a, b) => a + b, 0);
        return (
            // If we're in 2019 and the field is blank, then *always* force an update check
            // since the "nature of relationship" is a new field
            licenceYear === 2019 ||
            // After 2019, then we will check if the grower still has any codes where the
            // "nature of relationship" is not specified. If so, then force an update.
            (licenceYear > 2019 && Object.values(confirmUpdate.current).some(c => c.relationship === NatureOfRelationship.NOT_SPECIFIED)) ||
            // If the percentages do not sum to 100, then we shouldn't let me member accept it either
            currentPercentage < 100
        );
    }

    private hideConfirmUpdateButtons(confirmUpdate: OneToManyConfirmUpdate<GrowerMarketerCurrent, GrowerMarketerUpdate>): boolean {
        const licenceYear = this.props.applicationStore!.submission!.licenceYear.id;
        const currentPercentage = Object.values(confirmUpdate.current)
            .map(c => c.percentage)
            .reduce((a, b) => a + b, 0);
        return (
            licenceYear === 2019 ||
            (licenceYear > 2019 && Object.values(confirmUpdate.current).some(c => c.relationship === NatureOfRelationship.NOT_SPECIFIED)) ||
            currentPercentage < 100
        );
    }
}
