import * as React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

export interface FormDialogInjectable {
    closeDialog?: () => void;
}

export interface FormDialogProps {
    show: boolean;
    onClose: () => void;
    title?: string;
    modalSize?: "modal-sm" | "modal-md" | "modal-lg" | "modal-xl";
    children?: React.ReactElement;
}

export interface DialogProvider {
    showFormDialog: (props: FormDialogProps) => void;
}

export class FormDialog extends React.Component<FormDialogProps, {}> {
    close = () => {
        this.props.onClose();
    };

    render() {
        let child: any = null;
        if (this.props.children) {
            child = React.cloneElement(React.Children.only(this.props.children));
        }

        return (
            <Modal isOpen={this.props.show} toggle={this.close} className={this.props.modalSize || "modal-md"} centered={true}>
                <ModalHeader toggle={this.close}>{this.props.title || ""}</ModalHeader>
                <ModalBody>{child}</ModalBody>
            </Modal>
        );
    }
}
