import { BuildConfirmUpdateOneToManyValidityCheck, BuildSimpleFieldValidityCheck, PackerValidation } from "./Validation";
import {
    CalculateOneToManyValidationState,
    CalculateSingleValueValidationState,
    ConfirmUpdateValidity,
    GrowerValidation,
    BuildConfirmUpdateSingleValueValidityCheck
} from "./Validation";

export const PackerValidationRules: PackerValidation = {
    form4: {
        name: "Form 4 - Packer's Information",
        subsections: {
            packersInformation: {
                name: "Packer's Information",
                checks: [
                    BuildConfirmUpdateSingleValueValidityCheck("legalMemberName", "Missing legal member name"),
                    BuildSimpleFieldValidityCheck("ownAnyOtherGreenhouses", "Missing whether any other greenhouses are owned"),
                    BuildSimpleFieldValidityCheck("biosecurityInPlace", "Missing whether any biosecurity protocols are in pace")
                ]
            },
            mailingAddress: {
                name: "Mailing Address",
                checks: [BuildConfirmUpdateSingleValueValidityCheck("mailingAddress", "Missing one or more fields for the mailing address", ['commodities','totalSquareFeet'])]
            },
            physicalAddresses: {
                name: "Physical Address(es)",
                checks: [BuildConfirmUpdateOneToManyValidityCheck("physicalAddresses", "Missing one or more fields for a physical address",['commodities','totalSquareFeet'])]
            },
            contacts: {
                name: "Contacts",
                checks: [
                    BuildConfirmUpdateOneToManyValidityCheck("ownerContact", "Missing one or more fields for an owner contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("primaryContact", "Missing one or more fields for a primary contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("foodSafetyContact", "Missing one or more fields for a food safety contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("biosecurityContact", "Missing one or more fields for a biosecurity contact")
                ]
            },
            traceabilityCodes: {
                name: "Traceability Codes",
                checks: [BuildConfirmUpdateOneToManyValidityCheck("packerGrowerTraceability", "Missing one or more fields for a traceability code")]
            }
        }
    }
};
