import { ProprietaryCommodity } from "./ProprietaryCommodity";
import { AreaUnit, YieldUnit } from "./Configuration";
import moment, { Moment } from "moment";
import { WeekYearOption } from "../components/Common/CropProductionDetailsTableWidget";

export enum Commodity {
    TOMATO = "TOMATO",
    CUCUMBER = "CUCUMBER",
    PEPPER = "PEPPER",
    OTHER = "OTHER"
}
export enum CommodityDetailType{
    SIZE = "SIZE",
    COLOR = "COLOR",
    CONTAINER = "CONTAINER"
}
export interface YearWeek {
    year: number;
    week: number;
}

export const CommodityFriendlyName = (c: Commodity): string => {
    switch (c) {
        case Commodity.TOMATO:
            return "Tomato";
        case Commodity.CUCUMBER:
            return "Cucumber";
        case Commodity.PEPPER:
            return "Pepper";
        default:
            return "Other";
    }
};

export interface CommoditySquareFootCommodity {
    id: number;
    friendlyName: string;
    type: Commodity;
    orderPrecedence: number;
    details: Array<CommodityDetail>;
}
export interface CommodityDetail{
    id: number;
    friendlyName: string;
    detailType: CommodityDetailType,
    orderPrecedence: number,
}
export interface Propagator {
    id: number;
    dateDisabled: number | null;
    friendlyName: string;
    orderPrecedence: number;
}

export interface Crop {
    id: number;
    editable: boolean;

    variety: CommoditySquareFootCommodity | null;
    proprietaryVariety: ProprietaryCommodity | null;
    sizeDetail:  CommodityDetail | null;
    colorDetail: CommodityDetail | null;
    containerDetail: CommodityDetail | null;
    organic: boolean;

    rangeName: string;
    seedlingVariety: string;

    plantYearWeek: YearWeek;
    harvestYearWeek: YearWeek | null;
    pullYearWeek: YearWeek;

    enteredArea: number;
    preferredUnit: AreaUnit;
    normalizedArea: number;

    enteredExpectedYield: number;
    enteredExpectedYieldUnit: YieldUnit;
    normalizedYield: number;

    numberOfPlants: number;
    numberOfHeadsPerPlant: number;
    cucumberHighwire: boolean;

    primaryPropagator: Propagator;
    percentPlantsLostLastYear: number | null;

    notes: string;
    proprietary: boolean; // Add to help with variety and proprietaryVariety UI decisions.
    underLights: boolean;
}

export const ExtractTypeFromCrop = (crop: Crop): Commodity => {
    if (crop.variety) {
        return crop.variety.type;
    } else if (crop.proprietaryVariety) {
        return crop.proprietaryVariety.type;
    } else {
        throw new Error("Invalid crop, no variety or proprietary commodity specified.");
    }
};

export const ExtractVarietyFromCrop = (crop: Crop): string => {
    if (crop.variety) {
        return `${CommodityFriendlyName(crop.variety.type)} - ${crop.variety.friendlyName}`;
    } else if (crop.proprietaryVariety) {
        return `${CommodityFriendlyName(crop.proprietaryVariety.type)} - ${crop.proprietaryVariety.variety}`;
    } else {
        throw new Error("Invalid crop, no variety or proprietary commodity specified.");
    }
};

export const YearWeekToMoment = (yearWeek: YearWeek): Moment => {
    return moment()
        .year(yearWeek.year)
        .isoWeek(yearWeek.week)
        .startOf("week");
};

export const YearWeekEquals = (yw1: YearWeek, yw2: YearWeek): boolean => {
    return yw1.year === yw2.year && yw1.week === yw2.week;
};

export const CropDuration = (crop: Crop): number => {
    // In this hack, if we're planting in a year with 53 weeks spanning into the next year, then pad by 2 instead of 1
    const hackyPadding =
        moment()
            .year(crop.plantYearWeek.year)
            .month(11)
            .endOf("month")
            .isoWeek() === 53 && crop.pullYearWeek.year === crop.plantYearWeek.year + 1
            ? 2
            : 1;
    return YearWeekToMoment(crop.pullYearWeek).diff(YearWeekToMoment(crop.plantYearWeek), "week") + hackyPadding;
};

export const CropDurationFromStart = (w: WeekYearOption, crop: Crop): number => {
    return YearWeekToMoment(crop.pullYearWeek).diff(YearWeekToMoment(w), "week") + 1;
};

export const CropPlatToHarvestDuration = (crop: Crop): number => {
    return crop.harvestYearWeek ? YearWeekToMoment(crop.harvestYearWeek).diff(YearWeekToMoment(crop.plantYearWeek), "week") + 1 : 0;
};
