import { inject, observer } from "mobx-react";
import * as React from "react";

import { faExclamationCircle, faInfo, faTrash, faTrashRestore, faArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CalcPercentage } from "../../../assets/Common/FileUtil";
import { CopyGrowerPackerCurrentToUpdate, GrowerPackerCurrent, GrowerPackerEmpty, GrowerPackerUpdate } from "../../../model/ConfirmUpdate";
import { CalculateOneToManyValidationState } from "../../../model/validation/Validation";
import { OneToManyWrapper } from "../OneToManyWrapper";
import { CommonProps, CommonState, OneToManyCommon } from "./OneToManyCommon";
import TraceabilityCodeGrowerPackerWidget from "./TraceabilityCodeGrowerPackerWidget";

export interface State extends CommonState {}

interface Props extends CommonProps {}

@inject("applicationStore")
@observer
export default class TraceabilityCodesGrowerPackerWidget extends OneToManyCommon<GrowerPackerCurrent, GrowerPackerUpdate, Props, State> {
    state: State = {
        loading: false
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission;
        if (!submission) {
            console.warn("Submission is not ready yet");
            return <div />;
        }
        const confirmUpdate = submission.confirmUpdates[this.props.confirmUpdateName];
        if (!confirmUpdate) {
            console.error(`Could not find ${this.props.confirmUpdateName} Confirm-Update in submission object`);
            return <div className="text-danger">Error while loading field. Please contact support.</div>;
        }

        const configuration = this.props.applicationStore!.configuration;
        if (!configuration) {
            return <div />;
        }

        const updated = this.buildUpdated();
        const synchronizeValue = confirmUpdate.outcome !== "UPDATED";
        const calcPercentage = CalcPercentage(Object.keys(updated).map(key => (updated[key].discarded ? "0" : updated[key].percentage)));
        const completed = applicationStore.completed;

        return (
            <OneToManyWrapper
                confirmUpdateOutcome={confirmUpdate.outcome}
                onConfirmUpdateChange={newValue => this.updateConfirmUpdateOutcome(newValue)}
                onAddNewChildren={() => this.addOneToMany()}
                label="Packer(s)"
                loading={this.state.loading}
                validity={CalculateOneToManyValidationState(confirmUpdate)}
                hideConfirmUpdate={Object.keys(confirmUpdate.current).length === 0}
                onChangeDropDown={ () => function(){} }
            >
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-success">
                            <FontAwesomeIcon icon={faInfo} className="mr-3" />
                            If you have more than one traceablity code for each packer, then list them all separated by a comma (e.g., 1234, 5678)
                        </div>
                    </div>
                </div>

                {calcPercentage !== 100 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-warning text-center">
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                The sum of the percentages must be 100% (Currently {calcPercentage}%).
                            </div>
                        </div>
                    </div>
                )}

                {Object.keys(updated).length === 0 ? (
                    <div className="w-100-percent py-2 bg-one-to-many text-center">No values on file.</div>
                ) : (
                    Object.keys(updated).map(key => {
                        const value = updated[key];
                        return (
                            <span key={key}>
                                <div key={key} className="d-flex align-items-start w-100-percent  mt-3">
                                    <div className="w-100-percent">
                                        <TraceabilityCodeGrowerPackerWidget
                                            value={value}
                                            loading={this.state.loading}
                                            disabled={confirmUpdate.outcome !== "UPDATED" || completed}
                                            synchronizeValue={synchronizeValue || value.discarded}
                                            onFieldChange={(fieldName: string, newValue: string | number | null) => {
                                                this.onConfirmUpdateChangeOneToMany(key, fieldName, newValue);
                                            }}
                                        />
                                    </div>
                                    <div className="flex-shrink-0">
                                        {!value.discarded && (
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-danger px-2 ml-2 ${confirmUpdate.outcome !== "UPDATED" ? `disabled` : ``}`}
                                                disabled={confirmUpdate.outcome !== "UPDATED" || completed}
                                                onClick={() => this.deleteOneToMany(key)}
                                            >
                                                {parseInt(key, 10) > 0 ? (
                                                    <>
                                                        <FontAwesomeIcon icon={faArchive} className="mr-2" /> Archive
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon icon={faTrash} className="mr-2" /> Delete
                                                    </>
                                                )}
                                            </button>
                                        )}
                                        {value.discarded && (
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-secondary px-2 ml-2 ${
                                                    confirmUpdate.outcome !== "UPDATED" ? `disabled` : ``
                                                }`}
                                                disabled={confirmUpdate.outcome !== "UPDATED" || completed}
                                                onClick={() => this.undeleteOneToMany(key)}
                                            >
                                                <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
                                                Unarchive
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <hr />
                            </span>
                        );
                    })
                )}
            </OneToManyWrapper>
        );
    }

    protected emptyUpdate(): GrowerPackerUpdate {
        return GrowerPackerEmpty();
    }

    protected copyCurrentToUpdate(current: GrowerPackerCurrent): GrowerPackerUpdate {
        return CopyGrowerPackerCurrentToUpdate(current);
    }
}
