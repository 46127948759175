import { inject, observer } from "mobx-react";
import * as React from "react";

import { ApplicationStore } from "../../stores/ApplicationStore";
import { LinkMainMenu } from "../Common/LinkMenu";

export const MainNavigation = inject("applicationStore")(
    observer(({ applicationStore }: { applicationStore?: ApplicationStore }) => {
        const memberType = applicationStore!.submission!.member.memberType;
        return (
            <ul className="main-navigation">
                {memberType === "GROWER" && (
                    <>
                        <li>
                            <LinkMainMenu
                                check={applicationStore!.form2Validated}
                                path="/grower/form-2-producers-information"
                                title="Form 2 - Producer's Information"
                            />
                        </li>
                        <li>
                            <LinkMainMenu
                                check={applicationStore!.form2AValidated === true}
                                path="/grower/form-2a-greenhouse-operation-information"
                                title="Form 2A - Greenhouse Operation information"
                            />
                        </li>
                        <li>
                            <LinkMainMenu
                                check={applicationStore!.cropProductionValidated}
                                path="/grower/crop-production-details"
                                title="Crop Production Details"
                            />
                        </li>
                        <li>
                            <LinkMainMenu path="/grower/signature-and-submission" title="Signature and Submission" />
                        </li>
                    </>
                )}
                {memberType === "PACKER" && (
                    <>
                        <li>
                            <LinkMainMenu
                                path="/packer/form-4-packers-information"
                                title="Form 4 - Packer's Information"
                                check={applicationStore!.form4Validated}
                            />
                        </li>
                        <li>
                            <LinkMainMenu path="/packer/signature-and-submission" title="Signature and Submission" />
                        </li>
                    </>
                )}
                {memberType === "MARKETER" && (
                    <>
                        <li>
                            <LinkMainMenu
                                path="/marketer/form-5-marketers-information"
                                title="Form 5 - Marketer's Information"
                                check={applicationStore!.form5Validated}
                            />
                        </li>
                        {/*Removed Marketer form 5a & 5b for 2023/2024 licences as they are no longer required.*/}
                        {/*<li>*/}
                        {/*    <LinkMainMenu*/}
                        {/*        path="/marketer/form-5a-sales-reporting"*/}
                        {/*        title="Form 5A - Sales Reporting"*/}
                        {/*        check={applicationStore!.form5AValidated}*/}
                        {/*    />*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <LinkMainMenu*/}
                        {/*        path="/marketer/page-5b-business-overview"*/}
                        {/*        title="Form 5B - Business Overview"*/}
                        {/*        check={applicationStore!.form5BValidated}*/}
                        {/*    />*/}
                        {/*</li>*/}
                        <li>
                            <LinkMainMenu path="/marketer/attachments" title="Attachments" check={applicationStore!.marketerAttachmentsValidated} />
                        </li>
                        <li>
                            <LinkMainMenu path="/marketer/signature-and-submission" title="Signature and Submission" />
                        </li>
                        {/* <li>
                            <LinkMainMenu path="/marketer/" title="Appendix A" />
                        </li> */}
                    </>
                )}
            </ul>
        );
    })
);
