import * as React from "react";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, faHandPaper} from "@fortawesome/free-solid-svg-icons";
import FormInputField from "./FormInputField";
import {ApplicationStore} from "../../../stores/ApplicationStore";
import {CalcPercentage} from "../../../assets/Common/FileUtil";
import {ValidationStore} from "../../../stores/ValidationStore";

export interface Fields {
    label: string;
    name: string;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    formType: string;
    label: string;
    required?: boolean;
}


const isValid = (fieldName: string, formType: string, store: ApplicationStore) => {
    return (
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":sept:value")
            && store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":sept:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":oct:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":oct:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":nov:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":nov:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":dec:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":dec:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":jan:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":jan:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":feb:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":feb:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":mar:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":mar:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":april:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":april:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":may:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":may:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":june:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":june:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":july:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":july:value") !== "undefined") &&
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":aug:value") && store.findGeneralFormValue(formType,"amountDonated:" + fieldName+ ":aug:value") !== "undefined")
    );
};
const includeOnValidation = (fieldName: string, formType: string, store: ApplicationStore) => {

    if (
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":sept:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":oct:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":nov:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":dec:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":jan:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":feb:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":mar:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":april:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":may:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":june:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":july:value")) ||
        (!!store.findGeneralFormValue(formType, "amountDonated:" + fieldName + ":aug:value") )
    ) {
        return true;
    } else {
        return false;
    }
};

const handleValidation = (formType: string, store: ApplicationStore) => {
    let includeTomatoes = includeOnValidation("tomatoes", formType, store);
    let includeCukes = includeOnValidation("cucumbers", formType, store);
    let includePeppers = includeOnValidation("peppers", formType, store);
    let includeFoodbank = includeTomatoes || includeCukes || includePeppers;
    return (
        (includeTomatoes ? isValid("tomatoes", formType, store) : true) &&
        (includeCukes ? isValid("cucumbers", formType, store) : true) &&
        (includePeppers ? isValid("peppers", formType, store) : true) &&
        (includeFoodbank ? isValid("foodbank",formType,store):true ) &&
        (includeTomatoes
            && includeCukes
            && includePeppers)
    );
};

@inject("applicationStore")
@inject("validationStore")
@observer
export default class ProduceDonationWidget extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.required) {
            const {formType} = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, this.props.label, (store: ApplicationStore) => {
                return handleValidation(formType, store);
            });
        }
    }

    render() {
        const {formType} = this.props;
        const store = this.props.applicationStore!;
        if (!store.submission) {
            console.error("Submission is not yet ready in Form2AGreenhouseOperationInformation");
            return <div/>;
        }
        const lastYearForm = new Date(store.submission.licenceYear.startDate).getFullYear() - 1
        const thisYearForm = new Date(store.submission.licenceYear.startDate).getFullYear();

        const validated = this.props.required && handleValidation(formType, store);
        return (
            <div className="card shadow">
                <div className="card-header">
                    <div className={`d-flex ${validated ? `text-success` : ``}`}>
                        <div>
                            {this.props.label}
                            {this.props.required ? <span className="text-danger"> * </span> : ""}
                        </div>
                        {/* <div className="ml-auto">{validated && <FontAwesomeIcon icon={faCheck} className="mr-2" />}</div> */}
                        {this.props.required && (
                            <div className="ml-auto">
                                {validated ? (
                                    <FontAwesomeIcon icon={faCheck}/>
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this
                                            question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2"/>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row">
                            <div className="col-12 col-lg-12 mx-auto">
                                <label className="mx-auto">Please complete each cell, if you did not donate a commodity please enter '0'. If you did not donate to a Foodbank please enter '-' </label>
                                <table className="table donation-table">
                                    <thead>
                                    <tr>
                                        <td rowSpan={2} className="text-center align-content-around">Commodities (lbs)
                                        </td>
                                        <td colSpan={12} className="text-center">Estimated Monthly Donation in Pounds
                                            (lbs)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">Sept {lastYearForm}</td>
                                        <td className="text-center">Oct {lastYearForm}</td>
                                        <td className="text-center">Nov {lastYearForm}</td>
                                        <td className="text-center">Dec {lastYearForm}</td>
                                        <td className="text-center">Jan {thisYearForm}</td>
                                        <td className="text-center">Feb {thisYearForm}</td>
                                        <td className="text-center">March {thisYearForm}</td>
                                        <td className="text-center">April {thisYearForm}</td>
                                        <td className="text-center">May {thisYearForm}</td>
                                        <td className="text-center">June {thisYearForm}</td>
                                        <td className="text-center">July {thisYearForm}</td>
                                        <td className="text-center">Aug {thisYearForm}</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="text-center align-content-around">Tomatoes</td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:sept:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:oct:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:nov:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:dec:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:jan:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:feb:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:mar:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:april:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:may:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:june:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:july:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:tomatoes:aug:value"/></td>
                                    </tr>
                                    <tr>
                                        <td className="text-center align-content-around">Cucumbers</td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:sept:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:oct:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:nov:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:dec:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:jan:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:feb:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:mar:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:april:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:may:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:june:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:july:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:cucumbers:aug:value"/></td>
                                    </tr>
                                    <tr>
                                        <td className="text-center align-content-around">Peppers</td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:sept:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:oct:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:nov:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:dec:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:jan:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:feb:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:mar:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:april:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:may:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:june:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:july:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="number"
                                            fieldName="amountDonated:peppers:aug:value"/></td>
                                    </tr>
                                    <tr>
                                        <td className="text-center align-content-around">Food Bank Name</td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:sept:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:oct:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:nov:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:dec:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:jan:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:feb:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:mar:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:april:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:may:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:june:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:july:value"/></td>
                                        <td><FormInputField
                                            formType={this.props.formType}
                                            type="text"
                                            fieldName="amountDonated:foodbank:aug:value"/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    private lookupValue(field: string): any {
        return this.props.applicationStore!.submission!.generalForms[this.props.formType][field] || "";
    }
}
