import * as React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../../stores/ApplicationStore";

import YesNoSimpleField from "./YesNoSimpleField";
import SimpleInputField from "./SimpleInputField";

interface State {}

interface PropsComponent {
    yesNoLabel: string;
    yesNoFieldName: string;
    onYesLabel: string;
    onYesFieldName: string;
    yesNoRequired?: boolean;
    onYesRequired?: boolean;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class YesNoSimpleFieldWithInput extends React.Component<Props, State> {
    render() {
        const yesNoValue = this.props.applicationStore!.findSimpleFieldValue(this.props.yesNoFieldName) || "";
        return (
            <>
                <YesNoSimpleField label={this.props.yesNoLabel} fieldName={this.props.yesNoFieldName} required={this.props.yesNoRequired} />
                {yesNoValue.toUpperCase().includes("YES") && (
                    <div className="row">
                        <div className="col-12">
                            <SimpleInputField
                                label={this.props.onYesLabel}
                                fieldName={this.props.onYesFieldName}
                                required={this.props.onYesRequired}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}
