import { inject, observer } from "mobx-react";
import * as React from "react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import LoadingWidget from "../Loading";
import { errorMessage } from "../Toast";

interface State {
    loading: boolean;
}

interface PropsComponent {
    fieldName: string;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class SimpleCheckField extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    onChangeSimpleField = async (updatedValue: string) => {
        try {
            // Skip on no change
            if (this.props.applicationStore!.findSimpleFieldValue(this.props.fieldName) === updatedValue) {
                return;
            }

            this.setState({ loading: true });
            await this.props.applicationStore!.updateSimpleFields({
                [this.props.fieldName]: updatedValue
            });
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const simpleFieldValue = this.props.applicationStore!.findSimpleFieldValue(this.props.fieldName) || "";
        const checked = simpleFieldValue === "true" ? true : false;
        return (
            <div className="check-field-with-loading">
                <input
                    className="form-check-input pr-3"
                    type="checkbox"
                    name={this.props.fieldName}
                    checked={simpleFieldValue === "true"}
                    disabled={this.state.loading || this.props.applicationStore!.completed}
                    onChange={() => this.onChangeSimpleField((!checked).toString())}
                />
                {this.state.loading && <LoadingWidget className="loading-in-check" />}
            </div>
        );
    }
}
