import * as React from "react";
import { inject, observer } from "mobx-react";
import FormInputField from "./FormInputField";
import FormRadioField from "./FormRadioField";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faHandPaper} from "@fortawesome/free-solid-svg-icons";
import {CalcPercentage} from "../../../assets/Common/FileUtil";
import { ValidationStore } from "../../../stores/ValidationStore";
export interface Fields {
    label: string;
    value?: string;
    measure?: Measure;
}

export interface Measure {
    name: string;
    units: Array<{ label: string; value: string }>;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    label: string;
    inputClassName: string;
    radioClassName: string;
    formType: string;
    fieldName: string;
    fieldNameType?: string;
    radioName: string;
    radioOptions: Array<Fields>;
    required?: boolean;
}

@inject("applicationStore")
@inject("validationStore")
@observer
export default class InputWithRadiosWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            otherValue: ""
        };
    }
    componentDidMount() {
        if (this.props.required) {
            const { formType, label, fieldName } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, label, (store: ApplicationStore) => {
                const isValid = !!store.findGeneralFormValue(formType, fieldName);
                return isValid;
            });
        }
    }
    handleValidation = () => {
        const { formType, fieldName } = this.props;
        const store = this.props.applicationStore!;
        const isValid = !!store.findGeneralFormValue(formType, fieldName);
        return isValid;
    };

    render() {
        const isValid = this.props.required && this.handleValidation();
        return (
            <div className="card shadow">
                <div className="card-header d-flex">
                    <div className="mr-3">
                        {this.props.label}
                        {this.props.required ? <span className="text-danger"> * </span> : ""}
                    </div>
                    {this.props.required && (
                        <>
                            <div className="ml-auto">
                                {isValid ? (
                                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row col-12 col-lg-8 mx-auto">
                            <div className={`${this.props.inputClassName} mb-3 mr-5`}>
                                <FormInputField formType={this.props.formType} fieldName={this.props.fieldName} type={this.props.fieldNameType} />
                            </div>
                            {this.props.radioOptions.map(field => {
                                return (
                                    <div key={field.label} className={`${this.props.radioClassName} mb-3`}>
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField
                                                    fieldName={this.props.radioName}
                                                    fieldValue={field.value || field.label}
                                                    formType={this.props.formType}
                                                />
                                                <div className="new-label">{field.label}</div>
                                            </label>
                                        </div>
                                        {this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.radioName) ===
                                            (field.value || field.label) &&
                                            field.measure &&
                                            field.measure.units.map(unit => {
                                                return (
                                                    <div key={unit.label} className="row ml-5 mt-2">
                                                        <div className="col-12 pl-1">
                                                            <label>
                                                                <FormRadioField
                                                                    fieldName={field.measure!.name}
                                                                    fieldValue={unit.value}
                                                                    formType={this.props.formType}
                                                                />
                                                                <div className="new-label">{unit.label}</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
