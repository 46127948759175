import * as React from "react";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";

export const SimpleAlert = (message: string | React.ReactNode): Promise<void> => {
    return new Promise(resolve => {
        confirmAlert({
            title: "",
            // This is a hack because the `confirmAlert` TS bindings don't allow ReactNode, but the component works with it
            message: message as any,
            onClickOutside: () => resolve(),
            onKeypressEscape: () => resolve(),
            buttons: [
                {
                    label: "OK",
                    onClick: () => resolve()
                }
            ]
        });
    });
};

export const SimpleConfirmation = (message: string | React.ReactNode, title = "Are you sure?"): Promise<boolean> => {
    return new Promise(resolve => {
        confirmAlert({
            title,
            // This is a hack because the `confirmAlert` TS bindings don't allow ReactNode, but the component works with it
            message: message as any,
            onClickOutside: () => resolve(false),
            onKeypressEscape: () => resolve(false),
            buttons: [
                {
                    label: "No",
                    onClick: () => resolve(false)
                },
                {
                    label: "Yes",
                    onClick: () => resolve(true)
                }
            ]
        });
    });
};
