import * as React from "react";
import { inject, observer } from "mobx-react";
import FormInputField from "./FormInputField";
import FormRadioField from "./FormRadioField";
import { CalcPercentage } from "../../../assets/Common/FileUtil";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { ValidationStore } from "../../../stores/ValidationStore";

export interface Fields {
    label: string;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    label: string;
    formType: string;
    radioName: string;
    inputFieldName?: string;
    info?: string;
    yesTitle?: string;
    required?: boolean;
    isPercentage?: boolean;
}

@inject("applicationStore")
@inject("validationStore")
@observer
export default class YesNoWidget extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.required) {
            const { formType, radioName, inputFieldName } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, this.props.label, (store: ApplicationStore) => {
                const value = store.findGeneralFormValue(formType, radioName);
                if (!(value === "yes" || value === "no")) {
                    return false;
                } else if (!!inputFieldName && value === "yes") {
                    return !!store.findGeneralFormValue(formType, inputFieldName);
                } else {
                    return true;
                }
            });
        }
    }

    handleValidation = () => {
        const { formType, radioName, required, inputFieldName } = this.props;
        const store = this.props.applicationStore!;
        const value = store.findGeneralFormValue(formType, radioName);
        if (!(value === "yes" || value === "no")) {
            return false;
        } else if (!!inputFieldName && value === "yes") {
            return !!store.findGeneralFormValue(formType, inputFieldName);
        } else {
            return true;
        }
    };

    render() {
        const { required } = this.props;
        const isValid = required ? this.handleValidation() : false;
        return (
            <div className="card shadow">
                <div className="card-header">
                    <div className={`d-flex ${isValid ? `text-success` : ``}`}>
                        <div className="mr-3">
                            {this.props.label}
                            {this.props.required ? <span className="text-danger"> * </span> : ""}
                        </div>
                        {this.props.required && (
                            <div className="ml-auto">
                                {isValid ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row col-12 col-lg-8 mx-auto">
                            <div className="col-2 mt-2">
                                <div className="form-check">
                                    <label>
                                        <FormRadioField fieldName={this.props.radioName} fieldValue="yes" formType={this.props.formType} />
                                        <div className="new-label">Yes</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-2 mt-2">
                                <div className="form-check">
                                    <label>
                                        <FormRadioField fieldName={this.props.radioName} fieldValue="no" formType={this.props.formType} />
                                        <div className="new-label">No</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.radioName) === "yes" &&
                            this.props.yesTitle &&
                            this.props.inputFieldName !== undefined && (
                                <>
                                    <div className="form-row mt-4 ml-3">
                                        <div className="col-12 col-lg-8 mx-auto font-italic font-weight-bolder">{this.props.yesTitle}</div>
                                    </div>
                                    <div className="form-row mt-2 ml-3">
                                        <div className="col-12 col-lg-8 mx-auto">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type={this.props.isPercentage ? "number" : `text`}
                                                fieldName={this.props.inputFieldName}
                                                isPercentage={this.props.isPercentage}
                                            />
                                            {this.props.info && (
                                                <small id="emailHelp" className="form-text text-muted">
                                                    {this.props.info}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                    </form>
                </div>
            </div>
        );
    }
}
