import { inject, observer } from "mobx-react";
import * as React from "react";

import { AddressUpdate, ConfirmUpdateOutcome, CopyAddressCurrentToUpdate, SingleUpdateValue } from "../../../model/ConfirmUpdate";
import { CalculateSingleValueValidationState } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { AddressWidget } from "../simple-fields/AddressWidget";
import { errorMessage, successMessage } from "../Toast";
import { ConfirmUpdateWrapper } from "./ConfirmUpdateWrapper";
import { SimpleAlert } from "../SimpleConfirmAlert";

export interface State {
    loading: boolean;
}

interface Props {
    applicationStore?: ApplicationStore;
    label: string;
    confirmUpdateName: string;
}

@inject("applicationStore")
@observer
export default class MailingAddressWidget extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    updateConfirmUpdateOutcome = async (newValue: ConfirmUpdateOutcome) => {
        const applicationStore = this.props.applicationStore!;
        const confirmUpdate = applicationStore.submission!.confirmUpdates[this.props.confirmUpdateName as string]!;
        try {
            if (
                newValue === "CONFIRMED" &&
                Object.keys(confirmUpdate.current.value || {})
                    .filter(key => key !== "province" && key !== "country")
                    .map(fieldName => confirmUpdate.current!.value[fieldName])
                    .every(field => field === undefined || field === null || field === "")
            ) {
                SimpleAlert("Unfortunately, this empty mailing address cannot be confirmed. Please select Update and provide the requested details.");
                return;
            }

            this.setState({ loading: true });
            await applicationStore.updateConfirmUpdateOutcome({
                field: this.props.confirmUpdateName,
                outcome: newValue
            });
            // If we switched to updated, but updated is null, then fill in the default value
            if (newValue === "UPDATED" && !confirmUpdate.updated) {
                await this.props.applicationStore!.updateConfirmUpdateUpdated({
                    field: this.props.confirmUpdateName,
                    updated: { value: CopyAddressCurrentToUpdate(confirmUpdate.current.value) }
                });
            }
            if (newValue === "CONFIRMED") {
                successMessage(`${this.props.label} has been successfully ${newValue === "CONFIRMED" ? "confirmed" : "updated"}. `);
            }
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    onConfirmUpdateChange = async (fieldName: string, value: any) => {
        try {
            // Skip on no change
            const field = this.props.applicationStore!.submission!.confirmUpdates[this.props.confirmUpdateName];
            const updated: SingleUpdateValue<AddressUpdate> = (() => {
                if (!field.updated) {
                    return { value: CopyAddressCurrentToUpdate(field.current.value) };
                } else {
                    return JSON.parse(JSON.stringify(field.updated));
                }
            })();
            if (updated.value[fieldName] === value) {
                return;
            }
            updated.value[fieldName] = value;
            if (fieldName === "countryId") {
                updated.value["provinceId"] = null;
            }

            this.setState({ loading: true });
            await this.props.applicationStore!.updateConfirmUpdateUpdated({
                field: this.props.confirmUpdateName,
                updated
            });
        } catch (error) {
            // TODO: If we get here, the likely case is a network error. We may want a dialog instead?
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission;
        if (!submission) {
            console.warn("Submission is not ready yet");
            return <div />;
        }
        const confirmUpdate = submission.confirmUpdates[this.props.confirmUpdateName];
        if (!confirmUpdate) {
            console.error(`Could not find ${this.props.confirmUpdateName} Confirm-Update in submission object`);
            return <div className="text-danger">Error while loading field. Please contact support.</div>;
        }

        const value =
            confirmUpdate.outcome === "UPDATED" && confirmUpdate.updated && confirmUpdate.updated.value
                ? confirmUpdate.updated.value
                : CopyAddressCurrentToUpdate(confirmUpdate.current.value);

        return (
            <ConfirmUpdateWrapper
                confirmUpdate={confirmUpdate.outcome}
                onConfirmUpdateChange={newValue => this.updateConfirmUpdateOutcome(newValue)}
                label={this.props.label}
                loading={this.state.loading}
                validity={CalculateSingleValueValidationState(confirmUpdate, ['commodities','totalSquareFeet'])}
            >
                <AddressWidget
                    useName={false}
                    value={value}
                    loading={this.state.loading}
                    disabled={confirmUpdate.outcome !== "UPDATED"}
                    synchronizeValue={confirmUpdate.outcome !== "UPDATED"}
                    onFieldChange={(fieldName: string, newValue: string | number | null) => {
                        this.onConfirmUpdateChange(fieldName, newValue);
                    }}
                />
            </ConfirmUpdateWrapper>
        );
    }
}
