import * as React from "react";

import {
    ConfirmUpdateOutcome,
    OneToManyCurrentValue,
    OneToManyDiscardedFlag,
    OneToManyUpdateValue,
    OneToManyConfirmUpdate
} from "../../../model/ConfirmUpdate";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { SimpleConfirmation } from "../SimpleConfirmAlert";
import { successMessage, errorMessage } from "../Toast";

let executionQueueTimer: NodeJS.Timeout | null = null;
const executionQueue: Array<() => any> = [];
const OfferItemToQueue = (item: () => any) => {
    if (executionQueueTimer === null) {
        executionQueueTimer = setTimeout(async () => {
            console.log(`Updating ${executionQueue.length} item(s)`);
            for (let i = 0; i < executionQueue.length; i++) {
                await executionQueue[i]();
            }
            executionQueue.forEach(f => f());
        }, 2000);
    }
    executionQueue.push(item);
};

export interface CommonState {
    loading: boolean;
}

export interface CommonProps {
    applicationStore?: ApplicationStore;
    confirmUpdateName: string;
    label: string;
}

export abstract class OneToManyCommon<C, U, P extends CommonProps, S extends CommonState> extends React.Component<P, S> {
    private updateCheckRan: boolean = false;

    componentDidMount() {
        const confirmUpdate = this.props.applicationStore!.submission!.confirmUpdates[this.props.confirmUpdateName as string];
        if (confirmUpdate && !this.updateCheckRan) {
            if (
                confirmUpdate.outcome === "BLANK" &&
                // Check the specific field if there are any hard coded rules that force a transition to UPDATE
                (this.forceUpdateCheck(confirmUpdate) ||
                    // But for all other cases, we are looking for any cases where the current value is empty
                    Object.keys(confirmUpdate.current).length === 0)
            ) {
                console.log("Triggering a one-to-many field to be UPDATE: " + this.props.confirmUpdateName);
                OfferItemToQueue(() => this.updateConfirmUpdateOutcome("UPDATED"));
            }
            this.updateCheckRan = true;
        }
    }
    addOrRemoteMultiple = async ()=> {

    }


    addOneToMany = async () => {
        const updated: OneToManyUpdateValue<U> = this.buildUpdated();
        updated[this.nextNewId()] = this.emptyUpdate();
        this.updateOneToMany(updated);
    };

    deleteOneToMany = async (key: string, text?: string) => {
        const confirmUpdate = this.props.applicationStore!.submission!.confirmUpdates[this.props.confirmUpdateName as string];
        const updated: OneToManyUpdateValue<U> = this.buildUpdated();
        if (parseInt(key, 10) < 0) {
            const goAhead = await SimpleConfirmation(
                <div className="my-2 mx-2"> Are you sure you'd like to delete this {text ? text : `item`}? This operation is not reversible.</div>
            );
            if (!goAhead) {
                return;
            }
        }

        if (parseInt(key, 10) < 0) {
            // This is one we created so we can just remove it
            delete updated[key];
        } else {
            // When we discard, we reset the fields to be the same as the original
            const original: U & OneToManyDiscardedFlag = this.copyCurrentToUpdate(confirmUpdate.current[key]);
            original.discarded = true;
            updated[key] = original;
        }

        this.updateOneToMany(updated);
    };

    undeleteOneToMany = async (key: string) => {
        const updated: OneToManyUpdateValue<U> = this.buildUpdated();

        if (parseInt(key, 10) < 0) {
            console.error("Cannot undelete a newly created address?");
            return;
        } else {
            updated[key].discarded = false;
        }

        this.updateOneToMany(updated);
    };

    updateConfirmUpdateOutcome = async (newValue: ConfirmUpdateOutcome) => {
        const applicationStore = this.props.applicationStore!;
        const confirmUpdate = applicationStore.submission!.confirmUpdates[this.props.confirmUpdateName as string]!;

        try {
            if (newValue === "CONFIRMED") {
                // const goAhead = await SimpleConfirmation(<div className="my-2 mx-2"> Are you sure you'd like to confirm the {text}? </div>);
                // if (!goAhead) {
                //     return;
                // }
                //get the current number of addresses and set expected to that since we've confirmed.;
                let currentAddressCount = (Object.keys(confirmUpdate.current).length).toString(10)
                await applicationStore.updateConfirmUpdateExpected({
                    field: this.props.confirmUpdateName,
                    expected: currentAddressCount
                });
            }

            this.setState({ loading: true });
            // If we switched to updated, but updated is null, then fill in the default value
            if (newValue === "UPDATED" && !confirmUpdate.updated) {
                await this.props.applicationStore!.updateConfirmUpdateOutcomeAndUpdated(
                    {
                        field: this.props.confirmUpdateName,
                        outcome: newValue
                    },
                    {
                        field: this.props.confirmUpdateName,
                        updated: this.buildUpdated()
                    }
                );
            } else {
                await applicationStore.updateConfirmUpdateOutcome({
                    field: this.props.confirmUpdateName,
                    outcome: newValue
                });
            }
            // On confirm, show a dialog
            if (newValue === "CONFIRMED") {
                successMessage(`${this.props.label} has been successfully ${newValue === "CONFIRMED" ? "confirmed" : "updated"}. `);
            }
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    onConfirmUpdateChangeOneToMany = async (objectKey: string, fieldName: string, value: any | number) => {
        const updated: OneToManyUpdateValue<U> = this.buildUpdated();
        if (updated[objectKey][fieldName] === value) {
            return;
        }
        updated[objectKey][fieldName] = value;

        this.updateOneToMany(updated);
    };

    async updateOneToMany<T>(updated: OneToManyUpdateValue<T>) {
        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.updateConfirmUpdateUpdated({
                field: this.props.confirmUpdateName,
                updated: updated
            });
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    }
    updateConfirmUpdateExpected = async (newValue: string) => {
        const applicationStore = this.props.applicationStore!;
        const confirmUpdate = applicationStore.submission!.confirmUpdates[this.props.confirmUpdateName as string]!;

        try {
            this.setState({ loading: true });
            await applicationStore.updateConfirmUpdateExpected({
                field: this.props.confirmUpdateName,
                expected: newValue
            });
            confirmUpdate.outcome = "UPDATED";
            if (newValue === "UPDATED" && !confirmUpdate.updated) {
                await this.props.applicationStore!.updateConfirmUpdateOutcomeAndUpdated(
                    {
                        field: this.props.confirmUpdateName,
                        outcome: "UPDATED"
                    },
                    {
                        field: this.props.confirmUpdateName,
                        updated: this.buildUpdated()
                    }
                );
            } else {
                await applicationStore.updateConfirmUpdateOutcome({
                    field: this.props.confirmUpdateName,
                    outcome: "UPDATED"
                });
            }
            const updated: OneToManyUpdateValue<U> = this.buildUpdated();
            let currentAddresses =Object.keys(updated).length;
            let expectedAddresses = parseInt(newValue, 10);
            if(expectedAddresses > currentAddresses){
                for (let i = currentAddresses; i < expectedAddresses; i++ ){
                    updated[this.nextNewId()] = this.emptyUpdate();
                    await this.updateOneToMany(updated);
                }
            }
            else if(expectedAddresses < currentAddresses ){
                for(let i = currentAddresses; i > expectedAddresses; i--) {
                    let key = Object.keys(updated)[i-1];
                    if (parseInt(key, 10) < 0) {
                        // This is one we created so we can just remove it
                        delete updated[key];
                    } else {
                        // When we discard, we reset the fields to be the same as the original
                        const original: U & OneToManyDiscardedFlag = this.copyCurrentToUpdate(confirmUpdate.current[key]);
                        original.discarded = true;
                        updated[key] = original;
                    }
                }
                this.updateOneToMany(updated);
            }



        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    protected buildUpdated(): OneToManyUpdateValue<U> {
        const confirmUpdate = this.props.applicationStore!.submission!.confirmUpdates[this.props.confirmUpdateName as string];
        if (confirmUpdate.outcome === "UPDATED" && confirmUpdate.updated) {
            // We make a copy so we can make modifications
            return JSON.parse(JSON.stringify(confirmUpdate.updated));
        } else if (confirmUpdate) {
            const current = confirmUpdate.current as OneToManyCurrentValue<C>;
            const converted = {};
            Object.keys(current).forEach(key => (converted[key] = this.copyCurrentToUpdate(current[key])));
            return converted;
        }
        throw new Error("Could not build updated?");
    }

    protected abstract emptyUpdate(): U;
    protected abstract copyCurrentToUpdate(current: C): U;
    protected forceUpdateCheck(confirmUpdate: OneToManyConfirmUpdate<C, U>): boolean {
        return false;
    }

    private nextNewId(): number {
        const confirmUpdate = this.props.applicationStore!.submission!.confirmUpdates[this.props.confirmUpdateName as string];
        return (
            Math.min(
                ...Object.keys(confirmUpdate.updated || {})
                    .map(key => parseInt(key, 10))
                    .filter(key => !isNaN(key)),
                0
            ) - 1
        );
    }
}
