import { inject, observer } from "mobx-react";
import * as React from "react";

import { ConfirmUpdateOutcome } from "../../../model/ConfirmUpdate";
import { CalculateSingleValueValidationState } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import InputField from "../form-elements/InputField";
import { errorMessage, successMessage } from "../Toast";
import { ConfirmUpdateWrapper } from "./ConfirmUpdateWrapper";

interface Props {
    applicationStore?: ApplicationStore;
    confirmUpdateName: string;
    label: string;
}

interface State {
    loading: boolean;
}

@inject("applicationStore")
@observer
export class SingleConfirmUpdateComponent extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    updateConfirmUpdateOutcome = async (newValue: ConfirmUpdateOutcome) => {
        const applicationStore = this.props.applicationStore!;
        const confirmUpdate = applicationStore.submission!.confirmUpdates[this.props.confirmUpdateName as string]!;

        try {
            if (newValue === "CONFIRMED") {
                // const goAhead = await SimpleConfirmation(<div className="my-2 mx-2"> Are you sure you'd like to confirm the {text}? </div>);
                // if (!goAhead) {
                //     return;
                // }
            }

            this.setState({ loading: true });
            await applicationStore.updateConfirmUpdateOutcome({
                field: this.props.confirmUpdateName,
                outcome: newValue
            });
            // If we switched to updated, but updated is null, then fill in the default value
            if (newValue === "UPDATED" && !confirmUpdate.updated) {
                await this.props.applicationStore!.updateConfirmUpdateUpdated({
                    field: this.props.confirmUpdateName,
                    updated: { value: confirmUpdate.current ? confirmUpdate.current.value : "" }
                });
            }
            if (newValue === "CONFIRMED") {
                successMessage(`${this.props.label} has been successfully ${newValue === "CONFIRMED" ? "confirmed" : "updated"}. `);
            }
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    onConfirmUpdateChange = async (updatedValue: any) => {
        try {
            // Skip on no change
            const field = this.props.applicationStore!.submission!.confirmUpdates[this.props.confirmUpdateName];
            if (field && field.updated && field.updated.value === updatedValue.value) {
                return;
            }

            this.setState({ loading: true });
            await this.props.applicationStore!.updateConfirmUpdateUpdated({
                field: this.props.confirmUpdateName,
                updated: updatedValue
            });
        } catch (error) {
            // TODO: If we get here, the likely case is a network error. We may want a dialog instead?
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission;
        if (!submission) {
            console.warn("Submission is not ready yet");
            return <div />;
        }
        const confirmUpdate = submission.confirmUpdates[this.props.confirmUpdateName];
        if (!confirmUpdate) {
            console.error(`Could not find ${this.props.confirmUpdateName} Confirm-Update in submission object`);
            return <div className="text-danger">Error while loading field. Please contact support.</div>;
        }

        return (
            <ConfirmUpdateWrapper
                confirmUpdate={confirmUpdate.outcome}
                onConfirmUpdateChange={newValue => this.updateConfirmUpdateOutcome(newValue)}
                label={this.props.label}
                loading={this.state.loading}
                validity={CalculateSingleValueValidationState(confirmUpdate)}
            >
                <InputField
                    fieldName={this.props.confirmUpdateName}
                    value={confirmUpdate.outcome === "UPDATED" && confirmUpdate.updated ? confirmUpdate.updated.value : confirmUpdate.current.value}
                    disabled={confirmUpdate.outcome !== "UPDATED" || this.state.loading}
                    synchronizeValue={confirmUpdate.outcome !== "UPDATED"}
                    onBlur={(newKeyFieldValue: string) => this.onConfirmUpdateChange({ value: newKeyFieldValue })}
                />
                {confirmUpdate.outcome === "UPDATED" && (
                    <small className="form-text text-muted">
                        Original value we have on file from last year:&nbsp;
                        <strong>{confirmUpdate.current.value}</strong>
                    </small>
                )}
            </ConfirmUpdateWrapper>
        );
    }
}
