import { inject, observer } from "mobx-react";
import * as React from "react";

import { MarketerSubmission } from "../../../model/Submission";
import { MarketerValidationRules } from "../../../model/validation/MarketerValidation";
import { EvaluateAllRulesForSubsection } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import Accordion from "../../Common/Accordion";
import ContactsWidget from "../../Common/confirm-update/ContactsWidget";
import MailingAddressWidget from "../../Common/confirm-update/MailingAddressWidget";
import PhysicalAddressesWidget from "../../Common/confirm-update/PhysicalAddressesWidget";
import { SingleConfirmUpdateComponent } from "../../Common/confirm-update/SingleConfirmUpdateComponent";
import TraceabilityCodesMarketerGrowerWidget from "../../Common/confirm-update/TraceabilityCodesMarketerGrowerWidget";
import { Disclamer } from "../../Common/Disclamer";
import YesNoSimpleField from "../../Common/simple-fields/YesNoSimpleField";
import YesNoSimpleFieldWithInput from "../../Common/simple-fields/YesNoSimpleFieldWithInput";
import ContentHeader from "../../Template/ContentHeader";

const ONE_TO_MANY_NEW_ID = 0;

interface State {
    currentAccordion: string;
    newIdValue: number;
}

interface Props {
    applicationStore?: ApplicationStore;
}

@inject("applicationStore")
@observer
export default class Form5MarketersInformation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentAccordion: "marketerInformation",
            newIdValue: ONE_TO_MANY_NEW_ID
        };
    }

    setCurrentAccordion = (name: string) => {
        this.setState({ currentAccordion: name });
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission as MarketerSubmission;
        const member = applicationStore.submission!.member;
        const completed = (this.props.applicationStore!.submission! as MarketerSubmission).completed;
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Form 5 - Marketer's Information" />
                <div className="row content">
                    <div className="alert alert-warning shadow-sm text-center" role="alert">
                        Please <strong>enter or confirm/update</strong> the following information. All fields are mandatory unless otherwise
                        specified.
                        <br />
                        <strong>Note:</strong> All the information on file from the previous year can only be archived and not deleted.
                        <br /> Any new information entered in this application can be deleted before its submission.
                    </div>

                    <Accordion
                        title="Marketer's Information"
                        name="marketerInformation"
                        currentAccordion={this.state.currentAccordion}
                        nextAccordion="mailingAddress"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(MarketerValidationRules.form5.subsections.marketersInformation, submission)}
                    >
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <SingleConfirmUpdateComponent confirmUpdateName="legalMemberName" label="Legal Marketer Name" />
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleFieldWithInput
                                    yesNoLabel="Do you own any other greenhouses?"
                                    yesNoFieldName="ownAnyOtherGreenhouses"
                                    yesNoRequired={true}
                                    onYesLabel="Please list all the other greenhouses you own:"
                                    onYesFieldName="ownAnyOtherGreenhousesNames"
                                    onYesRequired={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    label="Do you have biosecurity protocols in place?"
                                    fieldName="biosecurityInPlace"
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    label="Do you handle/house/broker/or market any product in addition to Ontario-grown greenhouse produce? 
                                    If yes, please be sure to include a copy of your DRC License in the Attachments section."
                                    fieldName="marketAnyProductInAddition"
                                    required={true}
                                />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Mailing Address"
                        name="mailingAddress"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="marketerInformation"
                        nextAccordion="physicalAddresses"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(MarketerValidationRules.form5.subsections.mailingAddress, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <MailingAddressWidget label="Mailing Address" confirmUpdateName="mailingAddress" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Physical Address(es)"
                        name="physicalAddresses"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="mailingAddress"
                        nextAccordion="contacts"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(MarketerValidationRules.form5.subsections.physicalAddresses, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <PhysicalAddressesWidget label="Physical Address(es)" confirmUpdateName="physicalAddresses" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Contacts"
                        name="contacts"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="physicalAddresses"
                        nextAccordion="traceabilityCodes"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(MarketerValidationRules.form5.subsections.contacts, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Registered Owner Information" confirmUpdateName="ownerContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Primary Contact Information" confirmUpdateName="primaryContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Food Safety Contact Information" confirmUpdateName="foodSafetyContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Biosecurity Contact Information" confirmUpdateName="biosecurityContact" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Traceability Codes"
                        name="traceabilityCodes"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="contacts"
                        // nextPage="/marketer/form-5a-sales-reporting"
                        nextPage="/marketer/attachments"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(MarketerValidationRules.form5.subsections.traceabilityCodes, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <div className="alert alert-warning">
                                    <div>
                                        <strong>Growers and their Traceability Codes</strong>
                                    </div>
                                    Please provide the traceability code for each grower for whom you market. Also, if you grow/pack your own produce,
                                    please fill out Form 2, 3 and/or Form 4.
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <TraceabilityCodesMarketerGrowerWidget label="Traceabiility Codes" confirmUpdateName="marketerGrowerTraceability" />
                            </div>
                        </div>
                    </Accordion>
                    <div className="row pt-5">
                        <div className="col-12">
                            <Disclamer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
