import { inject, observer } from "mobx-react";
import * as React from "react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import { ValidationStore } from "../../../stores/ValidationStore";
import FormCheckboxField from "./FormChechboxField";
import FormInputField from "./FormInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHeading, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { GenericFormRequest } from "../../../requests/SimpleFieldRequests";
import { errorMessage } from "../Toast";
import { UncontrolledTooltip } from "reactstrap";

export interface Fields {
    label: string;
    name: string;
    value?: string;
}

const HandleValidation = (formType: string, otherInfo: string | undefined, options: Array<Fields>, store: ApplicationStore) => {
    const isValidOptions = options
        .map(o => {
            const optionValue = store.findGeneralFormValue(formType, o.name);
            if (o.value && o.value !== "") {
                return (optionValue && optionValue === "true" && !!store.findGeneralFormValue(formType, o.value)) || false;
            } else {
                return (optionValue && optionValue === "true") || false;
            }
        })
        .some(valid => valid);

    const otherPresent = !!store.findGeneralFormValue(formType, otherInfo + ":name") && !!store.findGeneralFormValue(formType, otherInfo + ":value");
    const missingOther = !store.findGeneralFormValue(formType, otherInfo + ":name") && !!store.findGeneralFormValue(formType, otherInfo + ":value");

    return (isValidOptions || otherPresent) && !missingOther;
};

interface State {}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;

    label: string;
    options: Array<Fields>;
    className: string;
    otherInfo?: string;
    formType: string;
    required?: boolean;
    valueTitle?: string;
}

@inject("validationStore")
@inject("applicationStore")
@observer
export default class CheckboxBreakdown extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.required) {
            const { formType, otherInfo, options } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, this.props.label, (store: ApplicationStore) =>
                HandleValidation(formType, otherInfo, options, store)
            );
        }

        // TODO: If we're not using this, then we should remove it
        const otherInfoName = this.props.otherInfo ? this.props.otherInfo + ":name" : undefined;
        const otherValue = otherInfoName && this.props.applicationStore!.findGeneralFormValue(this.props.formType, otherInfoName);
        if (otherValue !== undefined) {
            this.setState({ otherValue });
        }
    }

    updateGeneralForm = async (fieldName: string, value: string, formType: string) => {
        const request: GenericFormRequest = {
            form: formType,
            field: fieldName,
            value: value
        };

        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.updateGeneralForm(request);
        } catch (error) {
            errorMessage(error);
        }
    };

    render() {
        const store = this.props.applicationStore!;
        const otherInfoName = this.props.otherInfo ? this.props.otherInfo + ":name" : undefined;
        const otherInfoValue = this.props.otherInfo ? this.props.otherInfo + ":value" : undefined;

        const optionValue = this.props.options.filter(o => o.value);
        const isValid = this.props.required
            ? HandleValidation(this.props.formType, this.props.otherInfo, this.props.options, this.props.applicationStore!)
            : false;

        return (
            <div className="card shadow">
                <div className="card-header">
                    {/* <div className={`d-flex ${isValid ? `text-success` : ``}`}>
                        <div></div>
                        <div className="ml-auto"><FontAwesomeIcon icon={faCheck} className="mr-2"/></div>
                    </div> */}
                    <div className={`d-flex ${isValid ? `text-success` : ``}`}>
                        <div>
                            {this.props.label}
                            {this.props.required ? <span className="text-danger"> * </span> : ""}
                        </div>
                        {this.props.required && (
                            <div className="ml-auto">
                                {isValid ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="row mb-2">
                            <div className="col-12 col-lg-8 mx-auto">
                                <div className="row my-0 ml-1">
                                    <div className={this.props.valueTitle ? "col-6" : "col-12"}>
                                        <label htmlFor="" className="font-italic font-weight-bolder">
                                            Select all that apply
                                        </label>
                                    </div>
                                    {this.props.valueTitle &&
                                        this.props.options.find(
                                            field =>
                                                this.props.applicationStore!.findGeneralFormValue(this.props.formType, field.name) === "true" &&
                                                field.value !== ""
                                        ) && <div className="col-6 font-italic font-weight-bolder">{this.props.valueTitle}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-0">
                            <div className="col-12 col-lg-8 mx-auto">
                                {this.props.options.map(field => {
                                    return (
                                        <div key={field.name} className={`row mx-0 px-0 ${field.value === undefined ? `my-0` : ``}`}>
                                            <div className={field.value === "" || field.value === undefined ? `col-12` : "col-12 col-lg-6"}>
                                                <div className="form-check mb-2">
                                                    <label>
                                                        <FormCheckboxField
                                                            fieldName={field.name}
                                                            formType={this.props.formType}
                                                            clearOther={() =>
                                                                field.value ? this.updateGeneralForm(field.value, "", this.props.formType) : {}
                                                            }
                                                        />
                                                        <div className="new-label">{field.label}</div>
                                                    </label>
                                                </div>
                                            </div>
                                            {this.props.applicationStore!.findGeneralFormValue(this.props.formType, field.name) === "true" &&
                                                (field.value && field.value !== "") && (
                                                    <div className="col-12 col-lg-5">
                                                        <FormInputField type="number" formType={this.props.formType} fieldName={field.value} />
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {this.props.otherInfo !== undefined && otherInfoName && otherInfoValue && (
                            <>
                                <div className="row">
                                    <div className="col-12 col-lg-8 mx-auto">
                                        <div className="row mx-0 px-0 my-0">
                                            <div className="col-12 col-lg-2">
                                                <div className="form-check mb-2">
                                                    <label>
                                                        <FormCheckboxField
                                                            fieldName={otherInfoValue}
                                                            formType="goi"
                                                            clearOther={() =>
                                                                otherInfoName ? this.updateGeneralForm(otherInfoName, "", this.props.formType) : {}
                                                            }
                                                        />
                                                        <div className="new-label">Other</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-8 mx-auto">
                                        <div className="row mx-0 px-0 my-0">
                                            <div className="col-12 col-lg-12">
                                                {this.props.applicationStore!.findGeneralFormValue(this.props.formType, otherInfoValue) ===
                                                    "true" && (
                                                    <div className="col-12 col-lg-6 px-0">
                                                        <label className="font-italic font-weight-bolder">Other Description:</label>
                                                        <FormInputField formType={this.props.formType} fieldName={otherInfoName} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}
