import { inject, observer } from "mobx-react";
import * as React from "react";

import { faPercentage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ApplicationStore } from "../../../stores/ApplicationStore";

import { FormNavigation } from "../../Common/FormNavigation";
import AcresUnderLightWidget from "../../Common/general-forms/AcresUnderLightWidget";
import CheckboxBreakdown from "../../Common/general-forms/CheckboxBreadown";
import EmployeesWorkingWidget from "../../Common/general-forms/EmployeesWorkingWidget";
import ProduceDonationWidget from "../../Common/general-forms/ProduceDonationWidget";
import InputWithRadiosWidget from "../../Common/general-forms/InputWithRadiosWidget";
import PercentageBreakdown from "../../Common/general-forms/PercentageBreakdown";
import RadioBreakdown from "../../Common/general-forms/RadioBreakdown";
import NumberBreakdown from "../../Common/general-forms/NumberBreakdown";
import SimpleInputWidget from "../../Common/general-forms/SimpleInputWidget";
import YesNoWidget from "../../Common/general-forms/YesNoWidget";
import ContentHeader from "../../Template/ContentHeader";
import { Disclamer } from "../../Common/Disclamer";
import CarbonDioxideWidget from "../../Common/general-forms/CarbonDioxideWidget";
import EnergyCurtainsWidget from "../../Common/general-forms/EnergyCurtainsWidget";
import ToolsInYourOperationWidget from "../../Common/general-forms/ToolsInYourOperationWidget";
import { GrowerSubmission } from "../../../model/Submission";
import YesNoWithIfYesQuestionsWidget from "../../Common/general-forms/YesNoWithIfYesQuestionsWidget";
import SimpleTextareaWidget from "../../Common/general-forms/SimpleTextareaWidget";
import SimpleInputField from "../../Common/simple-fields/SimpleInputField";
import FormInputField from "../../Common/general-forms/FormInputField";
import WorkersRequiredWidget from "../../Common/general-forms/WorkersRequiredWidget";
import SimpleSelectWidget from "../../Common/general-forms/SimpleSelectWidget";
import HousingInformationWidget from "../../Common/general-forms/HousingInformationWidget";
interface Props {
    applicationStore?: ApplicationStore;

}

interface State {
    housingUnitCount?: Number;
}

@inject("applicationStore")
@observer
export default class Form2AGreenhouseOperationInformation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            housingUnitCount: 0,
        };
    }
    render() {
        const applicationStore = this.props.applicationStore!;
        if (!applicationStore.submission) {
            console.error("Submission is not yet ready in Form2AGreenhouseOperationInformation");
            return <div />;
        }

        const completed = (this.props.applicationStore!.submission! as GrowerSubmission).completed;
        const member = applicationStore.submission.member;
        const lastYearForm = new Date(applicationStore.submission.licenceYear.startDate).getFullYear() - 1;
        let GetHousingUnitCount = () => {

            if (this.state.housingUnitCount === undefined) {
                let ct = applicationStore!.findGeneralFormValue("goi", "housing:count:value");
                if (ct !== undefined) {
                    let intCt = parseInt(ct) || 0;
                    this.setState({ housingUnitCount: intCt });
                    return intCt;
                }
            }

            return 0;

        }
        const housingCount = parseInt(this.props.applicationStore!.findGeneralFormValue("goi", "housing:count:value") || "0") || 0;
        let questionCount = 0;
        const QuestionNumber = (extra: string = "") => `Question #${++questionCount}${extra} - `;
        const QuestionNumberRepeat = (extra: string = "") => `Question #${questionCount}${extra} - `;

        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Form 2A - Greenhouse Operation Information" />
                <div className="row content">
                    <div className="col-12 mt-3 pb-2">
                        <div className="alert alert-warning shadow">
                            The following data is collected for internal OGVG use and will only be shared externally in
                            aggregate, if at all. The
                            following data informs our lobby efforts and ensures your interests are best
                            represented. <br/>
                            <br/>
                            All fields marked with a red asterisk are required (<span className="text-danger">*</span>).
                        </div>
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <PercentageBreakdown
                            label={`${QuestionNumber()} Type of Greenhouse:`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                // { label: "Diffuse Glass", name: "typeOfGreenhouse:diffuseGlass:value" },
                                {label: "Glass", name: "typeOfGreenhouse:glass:value"},
                                {label: "Poly", name: "typeOfGreenhouse:poly:value"},
                                // { label: "Double Poly", name: "typeOfGreenhouse:doublePoly:value" },
                                // { label: "Triple Poly", name: "typeOfGreenhouse:triplePoly:value" }
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={false}
                            //otherInfo="typeOfGreenhouse:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <PercentageBreakdown
                            label={`${QuestionNumber()} Growing Media:`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Coconut", name: "growingMedia:coconut:value"},
                                {label: "Rockwool", name: "growingMedia:rockwool:value"},
                                {label: "NFT", name: "growingMedia:nft:value"},
                                {label: "Peat Moss", name: "growingMedia:peatMoss:value"},
                                {label: "Soil", name: "growingMedia:soil:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={true}
                            otherInfo="growingMedia:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label={`${QuestionNumber()} Heating System:`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            valueTitle="# of Operating Boilers"
                            options={[
                                {
                                    label: "Heat Pump (ground or air source)",
                                    name: "heatingSystem:heatPump:value",
                                    value: ""
                                },
                                {
                                    label: "Hot Water Boiler",
                                    name: "heatingSystem:hotWaterBoiler:value",
                                    value: "heatingSystem:hotWaterBoiler:amount"
                                },
                                {
                                    label: "Steam Boiler - Low Pressure",
                                    name: "heatingSystem:steamBoilerLowPressure:value",
                                    value: "heatingSystem:steamBoilerLowPressure:amount"
                                },
                                {
                                    label: "Steam Boiler - High Pressure",
                                    name: "heatingSystem:steamBoilerHighPressure:value",
                                    value: "heatingSystem:steamBoilerHighPressure:amount"
                                }
                            ]}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Do you purchase natural gas from a 3rd party?`}
                            formType="goi"
                            radioName="purchaseNaturalGasThirdParty:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <div className="card shadow">
                            <div className="card-header">{QuestionNumber()} Heating Fuel Type:</div>
                            <div className="card-body">
                                <RadioBreakdown
                                    label="Primary"
                                    required={true}
                                    className="col-12 col-md-3"
                                    formType="goi"
                                    fieldName="fuelType:primary:value"
                                    fieldAmount="fuelType:primary:amount"
                                    fieldUnit="fuelType:primary:unit"
                                    otherFieldUnit="fuelType:primary:unit:other:name"
                                    otherInfo="fuelType:primary:others[0]"
                                    lastYear={lastYearForm}
                                    options={[
                                        {
                                            label: "Light Oil",
                                            value: "light oil",
                                            applicableUnits: [
                                                {label: "M3", value: "m3"},
                                                {label: "Litres", value: "litres"},
                                                {label: "Gallons", value: "gallons"}
                                            ]
                                        },
                                        {
                                            label: "Bunker",
                                            value: "bunker",
                                            applicableUnits: [
                                                {label: "M3", value: "m3"},
                                                {label: "Litres", value: "litres"},
                                                {label: "Gallons", value: "gallons"}
                                            ]
                                        },
                                        {
                                            label: "Natural Gas",
                                            value: "natural gas",
                                            applicableUnits: [{label: "M3", value: "m3"}, {label: "GJ", value: "gj"}]
                                        },
                                        {
                                            label: "Biomass",
                                            value: "biomass",
                                            applicableUnits: [{label: "kg", value: "kg"}, {
                                                label: "Tons",
                                                value: "tons"
                                            }]
                                        },
                                        {
                                            label: "Biogas",
                                            value: "biogas",
                                            applicableUnits: [{label: "M3", value: "m3"}, {label: "GJ", value: "gj"}]
                                        },
                                        {
                                            label: "Coal",
                                            value: "coal",
                                            applicableUnits: [{label: "kg", value: "kg"}, {
                                                label: "Tons",
                                                value: "tons"
                                            }]
                                        },
                                        {
                                            label: "Electricity",
                                            value: "electricity",
                                            applicableUnits: [{label: "kWh", value: "kwh"}]
                                        }
                                    ]}
                                />

                                <RadioBreakdown
                                    label="Secondary"
                                    className="col-12 col-md-3"
                                    formType="goi"
                                    fieldName="fuelType:secondary:value"
                                    fieldAmount="fuelType:secondary:amount"
                                    fieldUnit="fuelType:secondary:unit"
                                    otherFieldUnit="fuelType:secondary:unit:other:name"
                                    otherInfo="fuelType:secondary:others[0]"
                                    lastYear={lastYearForm}
                                    options={[
                                        {label: "None", value: "none"},
                                        {
                                            label: "Light Oil",
                                            value: "light oil",
                                            applicableUnits: [
                                                {label: "M3", value: "m3"},
                                                {label: "Litres", value: "litres"},
                                                {label: "Gallons", value: "gallons"}
                                            ]
                                        },
                                        {
                                            label: "Bunker",
                                            value: "bunker",
                                            applicableUnits: [
                                                {label: "M3", value: "m3"},
                                                {label: "Litres", value: "litres"},
                                                {label: "Gallons", value: "gallons"}
                                            ]
                                        },
                                        {
                                            label: "Natural Gas",
                                            value: "natural gas",
                                            applicableUnits: [{label: "M3", value: "m3"}, {label: "GJ", value: "gj"}]
                                        },
                                        {
                                            label: "Biomass",
                                            value: "biomass",
                                            applicableUnits: [{label: "kg", value: "kg"}, {
                                                label: "Tons",
                                                value: "tons"
                                            }]
                                        },
                                        {
                                            label: "Biogas",
                                            value: "biogas",
                                            applicableUnits: [{label: "M3", value: "m3"}, {label: "GJ", value: "gj"}]
                                        },
                                        {
                                            label: "Coal",
                                            value: "coal",
                                            applicableUnits: [{label: "kg", value: "kg"}, {
                                                label: "Tons",
                                                value: "tons"
                                            }]
                                        },
                                        {
                                            label: "Electricity",
                                            value: "electricity",
                                            applicableUnits: [{label: "kWh", value: "kwh"}]
                                        }
                                    ]}
                                />

                                <RadioBreakdown
                                    label="Tertiary"
                                    className="col-12 col-md-3"
                                    formType="goi"
                                    fieldName="fuelType:tertiary:value"
                                    fieldAmount="fuelType:tertiary:amount"
                                    fieldUnit="fuelType:tertiary:unit"
                                    otherFieldUnit="fuelType:tertiary:unit:other:name"
                                    otherInfo="fuelType:tertiary:others[0]"
                                    lastYear={lastYearForm}
                                    options={[
                                        {label: "None", value: "none"},
                                        {
                                            label: "Light Oil",
                                            value: "light oil",
                                            applicableUnits: [
                                                {label: "M3", value: "m3"},
                                                {label: "Litres", value: "litres"},
                                                {label: "Gallons", value: "gallons"}
                                            ]
                                        },
                                        {
                                            label: "Bunker",
                                            value: "bunker",
                                            applicableUnits: [
                                                {label: "M3", value: "m3"},
                                                {label: "Litres", value: "litres"},
                                                {label: "Gallons", value: "gallons"}
                                            ]
                                        },
                                        {
                                            label: "Natural Gas",
                                            value: "natural gas",
                                            applicableUnits: [{label: "M3", value: "m3"}, {label: "GJ", value: "gj"}]
                                        },
                                        {
                                            label: "Biomass",
                                            value: "biomass",
                                            applicableUnits: [{label: "kg", value: "kg"}, {
                                                label: "Tons",
                                                value: "tons"
                                            }]
                                        },
                                        {
                                            label: "Biogas",
                                            value: "biogas",
                                            applicableUnits: [{label: "M3", value: "m3"}, {label: "GJ", value: "gj"}]
                                        },
                                        {
                                            label: "Coal",
                                            value: "coal",
                                            applicableUnits: [{label: "kg", value: "kg"}, {
                                                label: "Tons",
                                                value: "tons"
                                            }]
                                        },
                                        {
                                            label: "Electricity",
                                            value: "electricity",
                                            applicableUnits: [{label: "kWh", value: "kwh"}]
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    {(this.props.applicationStore!.findGeneralFormValue("goi", "fuelType:primary:value") === "natural gas" ||
                        this.props.applicationStore!.findGeneralFormValue("goi", "fuelType:secondary:value") === "natural gas" ||
                        this.props.applicationStore!.findGeneralFormValue("goi", "fuelType:tertiary:value") === "natural gas") && (
                        <div className="col-12 mt-2 pb-2">
                            <CheckboxBreakdown
                                label={`${QuestionNumberRepeat(".1")} Rate Classes that Apply to your Operation:`}
                                className="col-12 col-md-3"
                                formType="goi"
                                options={[
                                    {label: "M2", name: "rateClasses:m2:value"},
                                    {label: "M4", name: "rateClasses:m4:value"},
                                    {label: "M5A", name: "rateClasses:m5a:value"},
                                    {label: "6", name: "rateClasses:6:value"},
                                    {label: "145", name: "rateClasses:145:value"},
                                    {label: "170", name: "rateClasses:170:value"}
                                ]}
                                otherInfo="rateClasses:others[0]"
                            />
                        </div>
                    )}
                    <div className="col-12 mt-2 pb-2">
                        <PercentageBreakdown
                            label={`${QuestionNumber()} In-season Organic Waste Disposal:`}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Digester", name: "inSeasonWaste:digester:value"},
                                {label: "On-Farm Compost/Land Applied", name: "inSeasonWaste:onFarm:value"},
                                {label: "Landfill", name: "inSeasonWaste:landfill:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={true}
                            otherInfo="inSeasonWaste:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <PercentageBreakdown
                            label={`${QuestionNumber()} Clean-up Organic Waste Disposal:`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Digester", name: "cleanUpWaste:digester:value"},
                                {label: "On-Farm Compost/Land Applied", name: "cleanUpWaste:onFarm:value"},
                                {label: "Landfill", name: "cleanUpWaste:landfill:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={true}
                            otherInfo="cleanUpWaste:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label={`${QuestionNumber()} How is Food Waste Managed At Your Operation?`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Landfill", name: "foodWaste:landfill:value"},
                                {label: "Compost/Land-Apply", name: "foodWaste:compost:value"},
                                {label: "Direct Access (eg. stand/farmer's market)", name: "foodWaste:direct:value"},
                                {label: "Donation (eg. food banks, etc.)", name: "foodWaste:donation:value"},
                                {
                                    label: "Alternative Retail Channels (eg. No Name Brand)",
                                    name: "foodWaste:retail:value"
                                }
                            ]}
                            otherInfo="foodWaste:others[0]"
                        />
                    </div>
                    {/*{this.props.applicationStore!.findGeneralFormValue("goi", "foodWaste:donation:value") === "true" && (*/}
                    {/*    // <div className="col-12 mt-2 pb-2">*/}
                    {/*    //     <InputWithRadiosWidget*/}
                    {/*    //         label={`${QuestionNumberRepeat(".1")} How Much Produce Was Donated to Not-For-Profit Causes in ${lastYearForm}?`}*/}
                    {/*    //         inputClassName="col-12 col-md-6"*/}
                    {/*    //         radioClassName="col-12 col-md-1"*/}
                    {/*    //         formType="goi"*/}
                    {/*    //         fieldName="amountDonatedAnnually:value"*/}
                    {/*    //         fieldNameType="number"*/}
                    {/*    //         radioName="amountDonatedAnnually:unit"*/}
                    {/*    //         radioOptions={[{ label: "kg" }, { label: "lbs" }]}*/}
                    {/*    //     />*/}
                    {/*    // </div>*/}
                    {/*    <div className="col-12 mt-2 pb-2">*/}
                    {/*        <ProduceDonationWidget*/}
                    {/*            label={`${QuestionNumberRepeat(".1")} How Much Produce Was Donated to Not-For-Profit Causes in ${lastYearForm}?`}*/}
                    {/*            formType="goi"*/}
                    {/*            required={true}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {/* Produce donation widget to always be visible as of the 24/25 licence season. -- dmurray*/}
                    <div className="col-12 mt-2 pb-2">
                        <ProduceDonationWidget
                            label={`${QuestionNumberRepeat(".1")} How Much Produce Was Donated to Not-For-Profit Causes in ${lastYearForm}?`}
                            formType="goi"
                            required={true}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <PercentageBreakdown
                            label={`${QuestionNumber()} Growing Media Disposal:`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "On-Farm Compost/Land Applied", name: "growingMediaDisposal:onFarm:value"},
                                {label: "Return to Supplier", name: "growingMediaDisposal:supplier:value"},
                                {label: "Landfill", name: "growingMediaDisposal:landfill:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={true}
                            otherInfo="growingMediaDisposal:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <PercentageBreakdown
                            label={`${QuestionNumber()} Plastic Waste Disposal:`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Return to Supplier", name: "plasticWasteDisposal:supplier:value"},
                                {label: "Landfill", name: "plasticWasteDisposal:landfill:value"},
                                {label: "Recycled", name: "plasticWasteDisposal:recycle:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={true}
                            otherInfo="plasticWasteDisposal:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <PercentageBreakdown
                            label={`${QuestionNumber()} Sources of Water:`}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Municipal", name: "summerWaterSources:municipal:value"},
                                {label: "Rainwater", name: "summerWaterSources:rainwater:value"},
                                {label: "Well Water", name: "summerWaterSources:wellWater:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={true}
                            otherInfo="summerWaterSources:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <InputWithRadiosWidget
                            label={`${QuestionNumber()} What Was Your Annual Water Consumption in ${lastYearForm}?`}
                            required={true}
                            inputClassName="col-12 col-md-6"
                            radioClassName="col-12 col-md-2"
                            formType="goi"
                            fieldName="averageAnnualWaterConsumption:value"
                            fieldNameType="number"
                            radioName="averageAnnualWaterConsumption:unit"
                            radioOptions={[{label: "Litres", value: "litres"}, {label: "M3", value: "m3"}]}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <InputWithRadiosWidget
                            label={`${QuestionNumber()} What Was Your Annual Organic Waste Volume in ${lastYearForm}?`}
                            required={true}
                            inputClassName="col-12 col-md-6"
                            radioClassName="col-12 col-md-2"
                            formType="goi"
                            fieldName="organicWaste:annualTonnage:value"
                            fieldNameType="number"
                            radioName="organicWaste:annualTonnage:unit"
                            radioOptions={[{label: "kg", value: "kg"}, {label: "Ton", value: "tons"}]}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <InputWithRadiosWidget
                            label={`${QuestionNumber()} Estimated Water Storage:`}
                            inputClassName="col-12 col-md-6"
                            radioClassName="col-12 col-md-2"
                            formType="goi"
                            fieldName="waterStorageSupply:value"
                            radioName="waterStorageSupply:unit"
                            radioOptions={[
                                {label: "Day(s) Supply", value: "days"},
                                {
                                    label: "Volume",
                                    value: "volume",
                                    measure: {
                                        name: "waterStorageSupply:unit:value",
                                        units: [
                                            {label: "M3", value: "m3"},
                                            {label: "Litres", value: "litres"},
                                            {label: "Gallons", value: "gallons"}
                                        ]
                                    }
                                }
                            ]}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <CarbonDioxideWidget
                            label={
                                <>
                                    {QuestionNumber()} Supplemental Carbon Dioxide (CO<sub>2</sub>):
                                </>
                            }
                            formType="goi"
                            lastYear={lastYearForm}
                            required={true}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Water Recirculation:`}
                            required={true}
                            formType="goi"
                            inputFieldName="waterRecycling:percentage"
                            radioName="waterRecycling:value"
                            yesTitle="If yes, How Much of Your Acreage is Connected to The Recirculation System?"
                            isPercentage={true}
                        />
                    </div>
                    {this.props.applicationStore!.findGeneralFormValue("goi", "waterRecycling:value") === "yes" && (
                        <div className="col-12 mt-2 pb-2">
                            <YesNoWidget
                                label={`${QuestionNumberRepeat(
                                    ".1"
                                )} Have you Completed a Dye Test on your Recirculation System to Ensure your System is Sealed?`}
                                required={true}
                                formType="goi"
                                inputFieldName="dyeTestRecirculation:date"
                                radioName="dyeTestRecirculation:value"
                                yesTitle="If yes, when"
                                info="(mm/yyyy)"
                            />
                        </div>
                    )}
                    <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label={`${QuestionNumber()} Disinfecting:`}
                            className="col-12 col-md-3"
                            required={true}
                            formType="goi"
                            options={[
                                {label: "Heat/Pasteurization", name: "disinfecting:heatPasteurization:value"},
                                {label: "Filters (Lava)", name: "disinfecting:lavaFilters:value"},
                                {label: "Filters (Cloth)", name: "disinfecting:clothFilters:value"},
                                {label: "UV", name: "disinfecting:uv:value"},
                                {label: "Heat", name: "disinfecting:heat:value"},
                                {label: "Ozone", name: "disinfecting:ozone:value"},
                                {label: "Membrane", name: "disinfecting:membrane:value"}
                            ]}
                            otherInfo="disinfecting:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label={`${QuestionNumber()} For your Stormwater Do You Have a:`}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Wet Pond", name: "stormwaterSystemPond:wetPond:value"},
                                {label: "Dry Pond", name: "stormwaterSystemPond:dryPond:value"},
                                {label: "Direct Discharge", name: "stormwaterSystemPond:directDischarge:value"}
                            ]}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label={`${QuestionNumber()} How Do You Dispose of Sanitary Waste?`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Sewer", name: "sanitaryWasteDisposal:sewer:value"},
                                {label: "Septic Bed", name: "sanitaryWasteDisposal:septicBed:value"}
                            ]}
                            otherInfo="sanitaryWasteDisposal:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label={`${QuestionNumber()} How Do You Dispose of Excess Leach?`}
                            className="col-12 col-md-3"
                            required={true}
                            formType="goi"
                            options={[
                                {label: "Sanitary", name: "excessLeachDisposal:sanitary:value"},
                                {label: "Sewer", name: "excessLeachDisposal:sewer:value"},
                                {label: "Septic Bed", name: "excessLeachDisposal:septicBed:value"},
                                {
                                    label: "100% Recirculation",
                                    name: "excessLeachDisposal:hundredPercenteRecirculation:value"
                                },
                                {label: "Land Application", name: "excessLeachDisposal:landApplication:value"},
                                {label: "Licensed Waste Hauler", name: "excessLeachDisposal:wasteHauler:value"}
                            ]}
                            otherInfo="excessLeachDisposal:others[0]"
                        />
                    </div>
                    {/* <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label="Waste Water Disposal:"
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                { label: "Sanitary Sewer", name: "wasteWaterDisposal:Sanitary:value" },
                                { label: "Land Application", name: "wasteWaterDisposal:landApplication:value" },
                                { label: "Offsite by Licensed Hauler", name: "wasteWaterDisposal:offsiteByLicensedHauler:value" },
                                { label: "N/A", name: "wasteWaterDisposal:na:value" }
                            ]}
                            otherInfo="wasteWaterDisposal:others[0]"
                        />
                    </div> */}
                    {/*<div className="col-12 mt-2 pb-2">*/}
                    {/*    <AcresUnderLightWidget label={`${QuestionNumber()} Acres Under Light in ${lastYearForm}:`} formType="goi" required={true} />*/}
                    {/*</div>*/}
                    <div className="col-12 mt-2 pb-2">
                        <EnergyCurtainsWidget
                            label={`${QuestionNumber()} Do You Use Energy Curtains?`}
                            formType="goi"
                            radioName="energyCurtains:value"
                            options={[{label: "1", value: "1"}, {label: "2", value: "2"}]}
                            optionsName="energyCurtains:qty:value"
                            yesTitle="If yes, How Many Layers?"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <SimpleInputWidget
                            label={`${QuestionNumber()} Who is your Electricity Provider?`}
                            inputClassName="col-12 col-md-12"
                            formType="goi"
                            fieldName="electricityProvider:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <SimpleInputWidget
                            label={`${QuestionNumber()} What is your Electricity Rate Class?`}
                            inputClassName="col-12 col-md-12"
                            formType="goi"
                            fieldName="electricityRateClass:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <SimpleInputWidget
                            label={`${QuestionNumber()} What Was Your Average Monthly Electricity Demand in ${lastYearForm}?`}
                            inputClassName="col-12 col-md-12"
                            formType="goi"
                            type="number"
                            fieldName="electricityAvgMonthlyDemand:value"
                            info="kW"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <SimpleInputWidget
                            label={`${QuestionNumber()} What Was Your Annual Purchased Electricity Usage in ${lastYearForm}?`}
                            required={true}
                            inputClassName="col-12 col-md-12"
                            formType="goi"
                            type="number"
                            fieldName="electricityAvgAnnualUsage:value"
                            info="KWh"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWithIfYesQuestionsWidget
                            label={
                                <>
                                    {QuestionNumber()} Do You Generate Electricity for Your Operation?&nbsp;
                                    <small>(i.e. behind-the-meter)</small>
                                </>
                            }
                            required={true}
                            formType="goi"
                            ifYesQuestions={[
                                {
                                    label: `What is Your Nameplate Generation Capacity?`,
                                    value: "electricitySelfGen:nameplateGenerationCapacity:value",
                                    measure: "kW"
                                },
                                {
                                    label: `How Much Did you Generate in ${lastYearForm}?`,
                                    value: "electricitySelfGen:annualGeneration:value",
                                    measure: "kWh"
                                }
                            ]}
                            radioName="electricitySelfGen:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWithIfYesQuestionsWidget
                            label={`${QuestionNumber()} Do You Generate Electricity for Export to The Grid?`}
                            required={true}
                            formType="goi"
                            ifYesQuestions={[
                                {
                                    label: `What is Your Nameplate Generation Capacity?`,
                                    value: "electricityExportToGrid:nameplateGenerationCapacity:value",
                                    measure: "kW"
                                },
                                {
                                    label: `How Much Did you Generate in ${lastYearForm}?`,
                                    value: "electricityExportToGrid:annualGeneration:value",
                                    measure: "kWh"
                                }
                            ]}
                            radioName="electricityExportToGrid:value"
                        />
                    </div>
                    {this.props.applicationStore!.findGeneralFormValue("goi", "electricityExportToGrid:value") === "yes" && (
                        <>
                            <div className="col-12 mt-2 pb-2">
                                <CheckboxBreakdown
                                    label={`${QuestionNumberRepeat(".1")} If Applicable, Which Renewable Technology(s) Do You Use?`}
                                    className="col-12 col-md-3"
                                    formType="goi"
                                    options={[
                                        {label: "CHP", name: "renewableTechnologies:chp:value"},
                                        {label: "Solar", name: "renewableTechnologies:solar:value"},
                                        {label: "Wind", name: "renewableTechnologies:wind:value"}
                                    ]}
                                    otherInfo="renewableTechnologies:others[0]"
                                />
                            </div>
                            {/* <div className="col-12 mt-2 pb-2">
                                <SimpleInputWidget
                                    label="If Yes, What is Your Generation Capacity? "
                                    inputClassName="col-12 col-md-12"
                                    formType="goi"
                                    fieldName="electricityAvgMonthlyUsage:value"
                                    info="KWh"
                                />
                            </div> */}
                        </>
                    )}
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Do You Use Pest Exclusion Vent Screens?`}
                            required={true}
                            formType="goi"
                            inputFieldName="pestExclusionScreens:percentage"
                            radioName="pestExclusionScreens:value"
                            yesTitle="If yes, How Much of your Acreage is Covered by Pest Exclusion Vent Screens?"
                            isPercentage={true}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget label={`${QuestionNumber()} Do You Self Propagate?`} formType="goi"
                                     radioName="selfPropagate:value"/>
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Do You have a Biosecurity Plan in Place?`}
                            formType="goi"
                            radioName="biosecurityInPlace:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Would You Be Willing to Share Your Monthly Water, 
                            Natural Gas, Electricity Consumption, And Waste Management Invoices With OGVG? 
                            (Participants will have access to benchmark analysis data)`}
                            required={true}
                            formType="goi"
                            radioName="willingToShareEnergyInvoices:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Would You Be Willing to Participate in OGVG Research Projects?`}
                            required={true}
                            formType="goi"
                            radioName="researchWilling:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <ToolsInYourOperationWidget
                            label={`${QuestionNumber()} Are you Considering Implementing New or Additional Automation Tools in Your Operation?`}
                            formType="goi"
                            radioName="consideringAutomation:value"
                            options={[
                                {label: "1 Year", value: "oneYear"},
                                {label: "2-5 years", value: "twoToFiveYears"},
                                {label: "Longer than 5 years", value: "longerThanFiveYears"}
                            ]}
                            optionsName="consideringAutomation:timeline"
                            yesTitle="If yes, what is your timeline?"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <SimpleTextareaWidget
                            label={`${QuestionNumber()} What Type Of Automation Tools Do You Have In Your Operation?`}
                            required={false}
                            inputClassName="col-12 col-md-12"
                            formType="goi"
                            fieldName="consideringAutomation:typeOfAutomationToolsHave:value"
                        />
                    </div>
                    <div className="col-12 mt-5 pb-3 text-info">
                        <h3> Greenhouse Workforce Information </h3>
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <EmployeesWorkingWidget
                            label={`${QuestionNumber()} How Many Employees Do You Have Working?`}
                            formType="goi"
                            required={true}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Do You Provide Free Wi-Fi to Your Workforce?`}
                            required={true}
                            formType="goi"
                            radioName="freeWifi:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Do You Provide an Orientation to Your International Workers?`}
                            required={true}
                            formType="goi"
                            radioName="orientation:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <CheckboxBreakdown
                            label={`${QuestionNumber()} Which of the Following do You Provide Information On?`}
                            required={true}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Primary Health Care", name: "internationalInfo:primaryHealthCare:value"},
                                {label: "Legal Services", name: "internationalInfo:legalServices:value"},
                                {label: "Translation Services", name: "internationalInfo:translationServices:value"},
                                {label: "Cultural Awareness", name: "internationalInfo:culturalAwareness:value"},
                                {label: "Finances and Taxation", name: "internationalInfo:finances:value"},
                                {label: "Bike Safety", name: "internationalInfo:bikeSafety:value"},
                                {label: "Transportation Providers", name: "internationalInfo:transportation:value"},
                                {label: "Retail Locations", name: "internationalInfo:retail:value"}
                            ]}
                            otherInfo="internationalInfo:others[0]"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <div className="card shadow">
                            <div className="card-header">
                                {QuestionNumber()} Do You Foresee Requiring __________ Work Force in the Next Year?
                                (Please select the option most
                                applicable)
                            </div>
                            <div className="card-body">
                                <RadioBreakdown
                                    className="col-12 col-md-3"
                                    label={`Do You Foresee Requiring __________ Work Force in the Next Year? (Please select the option most applicable)`}
                                    hideLabel={true}
                                    formType="goi"
                                    fieldName="workforceNextYear:value"
                                    options={[{label: "More", value: "more"}, {
                                        label: "Less",
                                        value: "less"
                                    }, {label: "Same", value: "same"}]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <WorkersRequiredWidget
                            label={
                                <>
                                    {QuestionNumber()} How many total workers do you foresee requiring:
                                </>
                            }
                            formType="goi"
                            lastYear={lastYearForm}
                            required={false}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Do you utilize F.A.R.M.S./CanAg to manage your workforce?`}
                            formType="goi"
                            radioName="workforce:management:value"
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <YesNoWidget
                            label={`${QuestionNumber()} Do you utilize any other Agencies? `}
                            required={false}
                            formType="goi"
                            inputFieldName="workforce:management:agencyName:value"
                            radioName="workforce:management:agency:value"
                            yesTitle="If yes, please specify: "
                            isPercentage={false}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <NumberBreakdown
                            label={`${QuestionNumber()} Please indicate the number of workers that fall under each category:`}
                            required={false}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "SAWP", name: "employees:SAWP:value"},
                                {label: "TFW", name: "employees:TFW:value"},
                                {label: "Ag Stream", name: "employees:AgStream:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={false}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <NumberBreakdown
                            label={`${QuestionNumber()} Please provide the origin and number of workers from each origin on your farm:`}
                            required={false}
                            className="col-12 col-md-3"
                            formType="goi"
                            options={[
                                {label: "Mexico", name: "employees:origin:mexico:value"},
                                {label: "Jamaica", name: "employees:origin:jamaica:value"},
                                {label: "Trinidad/Tobago", name: "employees:origin:trinidad:value"},
                                {label: "Thailand", name: "employees:origin:thailand:value"},
                                {label: "Philippines", name: "employees:origin:philippines:value"},
                                {label: "Other", name: "employees:origin:other:value"}
                            ]}
                            icon={<FontAwesomeIcon icon={faPercentage}/>}
                            allowOther={false}
                        />
                    </div>
                    <div className="col-12 mt-5 pb-3 text-info">
                        <h3> Housing Information </h3>
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <SimpleSelectWidget
                            label={`${QuestionNumber()} How many housing units does your farm utilize?`}
                            formType="goi"
                            required={true}
                            inputClassName="col-12"
                            fieldName="housing:count:value"
                            applicationStore={applicationStore}
                            options={[
                                {label: "0", value: "0"},
                                {label: "1", value: "1"},
                                {label: "2", value: "2"},
                                {label: "3", value: "3"},
                                {label: "4", value: "4"},
                                {label: "5", value: "5"},
                                {label: "6", value: "6"},
                                {label: "7", value: "7"},
                                {label: "8", value: "8"},
                                {label: "9", value: "9"},
                                {label: "10", value: "10"},
                            ]}
                        />
                    </div>
                    <div className="col-12 mt-2 pb-2">
                        <SimpleTextareaWidget
                            label={`${QuestionNumber()} What is the farms Regional Public Health Unit?`}
                            info="Please list the name and phone number for your Health Units primary contact."
                            required={false}
                            inputClassName="col-12 col-md-12"
                            formType="goi"
                            fieldName="housing:healthunit:value"
                        />
                    </div>
                    {housingCount >= 1 && (
                        <>
                            {
                                <HousingInformationWidget
                                    label="Unit # 1" formType="goi" required={true} unitNumber={1}
                                />
                            }

                        </>
                    )}
                    {housingCount >= 2 && (
                        <>
                            {
                                <HousingInformationWidget
                                    label="Unit # 2" formType="goi" required={true} unitNumber={2}
                                />

                            }

                        </>
                    )}
                    {housingCount >= 3 && (
                        <>
                            {
                                <HousingInformationWidget
                                    label="Unit # 3" formType="goi" required={true} unitNumber={3}
                                />
                            }

                        </>
                    )}
                    {housingCount >= 4 && (
                        <>
                            {
                                <HousingInformationWidget
                                    label="Unit # 4" formType="goi" required={true} unitNumber={4}
                                />

                            }

                        </>
                    )}
                    {housingCount >= 5 && (
                        <>
                            {

                                <HousingInformationWidget
                                    label="Unit # 5" formType="goi" required={true} unitNumber={5}
                                />

                            }

                        </>
                    )}
                    {housingCount >= 6 && (
                        <>
                            {

                                <HousingInformationWidget
                                    label="Unit # 6" formType="goi" required={true} unitNumber={6}
                                />

                            }

                        </>
                    )}
                    {housingCount >= 7 && (
                        <>
                            {

                                <HousingInformationWidget
                                    label="Unit # 7" formType="goi" required={true} unitNumber={7}
                                />

                            }

                        </>
                    )}
                    {housingCount >= 8 && (
                        <>
                            {

                                <HousingInformationWidget
                                    label="Unit # 8" formType="goi" required={true} unitNumber={8}
                                />

                            }

                        </>
                    )}
                    {housingCount >= 9 && (
                        <>
                            {

                                <HousingInformationWidget
                                    label="Unit # 9" formType="goi" required={true} unitNumber={9}
                                />

                            }

                        </>
                    )}
                    {housingCount >= 10 && (
                        <>
                            {

                                <HousingInformationWidget
                                    label="Unit # 10" formType="goi" required={true} unitNumber={10}
                                />

                            }

                        </>
                    )}
                    <FormNavigation nextPath="/grower/crop-production-details"
                                    backPath="/grower/form-2-producers-information"/>
                    <Disclamer/>
                </div>
            </div>
        );
    }
}
