import * as React from "react";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import FormInputField from "./FormInputField";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import FormRadioField from "./FormRadioField";
import { CalcPercentage } from "../../../assets/Common/FileUtil";
import { ValidationStore } from "../../../stores/ValidationStore";
import YesNoSimpleField from "../../Common/simple-fields/YesNoSimpleField";

export interface Fields {
    label: string;
    name: string;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    formType: string;
    label: string | React.ReactNode;
    required?: boolean;
    lastYear: number;
}

const HandleValidation = (formType: string, store: ApplicationStore) => {
    const totalPercentage = CalcPercentage([
        store.findGeneralFormValue(formType, "carbonDioxide:liquid:value")!,
        store.findGeneralFormValue(formType, "carbonDioxide:flueGas:value")!
    ]);
    const notRequired = store.findGeneralFormValue(formType, "carbonDioxide:notApplicable:value")! === "yes";
    const liquid = store.findGeneralFormValue(formType, "carbonDioxide:liquid:value");
    const liquidOK = liquid && parseInt(liquid, 10) > 0 ? !!store.findGeneralFormValue(formType, "carbonDioxide:liquid:amount") : true;

    // const checkFields =
    //     (!!store.findGeneralFormValue(formType, "carbonDioxide:flueGas:value") &&
    //         !!!store.findGeneralFormValue(formType, "carbonDioxide:liquid:value")) ||
    //     (!!!store.findGeneralFormValue(formType, "carbonDioxide:flueGas:value") &&
    //         !!store.findGeneralFormValue(formType, "carbonDioxide:liquid:value") &&
    //         !!store.findGeneralFormValue(formType, "carbonDioxide:liquid:amount")) ||
    //     (!!store.findGeneralFormValue(formType, "carbonDioxide:flueGas:value") &&
    //         !!store.findGeneralFormValue(formType, "carbonDioxide:liquid:value") &&
    //         !!store.findGeneralFormValue(formType, "carbonDioxide:liquid:amount"));

    return (notRequired || (totalPercentage ===  100 && liquidOK)); // checkFields;
};

@inject("validationStore")
@inject("applicationStore")
@observer
export default class CarbonDioxideWidget extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.required) {
            const { formType, label } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, "Supplemental Carbon Dioxide(C02)", (store: ApplicationStore) =>
                HandleValidation(formType, store)
            );
        }
    }

    render() {
        const store = this.props.applicationStore!;
        const liquidValue = store.findGeneralFormValue(this.props.formType, "carbonDioxide:liquid:value")
            ? parseFloat(store.findGeneralFormValue(this.props.formType, "carbonDioxide:liquid:value")!)
            : 0;
        const valuesRequired = store.findGeneralFormValue(this.props.formType, "carbonDioxide:notApplicable:value")! === "no";
        const totalPercentage = CalcPercentage([
            store.findGeneralFormValue(this.props.formType, "carbonDioxide:liquid:value")!,
            store.findGeneralFormValue(this.props.formType, "carbonDioxide:flueGas:value")!
        ]);

        const isValid = this.props.required && HandleValidation(this.props.formType, store);

        return (
            <div className="card shadow">
                <div className="card-header d-flex">
                    <div>
                        <span className={isValid ? `text-success` : ``}>{this.props.label}</span>
                        {this.props.required ? <span className="text-danger"> * </span> : ""}
                    </div>
                    <div className={` ml-auto ${totalPercentage === 100  ? `text-success` : `text-danger`}`}>
                        {isValid ? '' : `Current Percentage: ` + totalPercentage + ` %`}
                    </div>
                    {this.props.required && (
                        <>
                            <div className="ml-auto">
                                {isValid ? (
                                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row">
                            <div className="col-12 col-lg-8 mx-auto">

                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <label>Not Applicable:</label>
                                    </div>
                                    <div className="col-12 col-lg-1 mt-2">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="carbonDioxide:notApplicable:value" fieldValue="yes" formType={this.props.formType} />
                                                <div className="new-label">Yes</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-1 mt-2">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="carbonDioxide:notApplicable:value" fieldValue="no" formType={this.props.formType} />
                                                <div className="new-label">No</div>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                {valuesRequired && (
                                    <>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <label>Flue Gas:</label>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="d-flex">
                                            <FormInputField formType={this.props.formType} type="number" fieldName="carbonDioxide:flueGas:value" />
                                            <div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>
                                                <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <label>Liquid:</label>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="d-flex">
                                            <FormInputField formType={this.props.formType} type="number" fieldName="carbonDioxide:liquid:value" />
                                            <div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>
                                                <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </>
                                    )}
                                {liquidValue > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <label className="font-italic font-weight-bolder">
                                                    For Liquid C0<sub>2</sub>, Amount Used Annually in {this.props.lastYear}:
                                                </label>
                                                <FormInputField
                                                    formType={this.props.formType}
                                                    fieldName="carbonDioxide:liquid:amount"
                                                    type="number"
                                                />
                                            </div>
                                            <div className="col-12 col-lg-2 pt-4">
                                                <div className="pt-2">Kg</div>
                                            </div>
                                            {/* <div className="col-12 col-lg-4">
                                                <label className="font-italic font-weight-bolder">&nbsp;</label>
                                                <div className="form-row">
                                                    <div className="col-6 mt-2">
                                                        <div className="form-check">
                                                            <label>
                                                                <FormRadioField
                                                                    fieldName="carbonDioxide:liquid:unit"
                                                                    fieldValue="kg"
                                                                    formType={this.props.formType}
                                                                />
                                                                <div className="new-label">Kg</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mt-2">
                                                        <div className="form-check">
                                                            <label>
                                                                <FormRadioField
                                                                    fieldName="carbonDioxide:liquid:unit"
                                                                    fieldValue="m3"
                                                                    formType={this.props.formType}
                                                                />
                                                                <div className="new-label">M3</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
