import { inject, observer } from "mobx-react";
import * as React from "react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import { ValidationStore } from "../../../stores/ValidationStore";
import FormInputField from "./FormInputField";
import FormRadioField from "./FormRadioField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { GenericFormRequest } from "../../../requests/SimpleFieldRequests";
import { errorMessage } from "../Toast";

export interface Fields {
    label: string;
    value: string;
    applicableUnits?: Array<{
        label: string;
        value: string;
    }>;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;

    formType: string;
    fieldName: string;
    fieldAmount?: string;
    fieldUnit?: string;
    otherFieldUnit?: string;
    options: Array<Fields>;
    className: string;
    otherInfo?: string;
    label: string;
    required?: boolean;
    lastYear?: number;
    hideLabel?: boolean;
}

@inject("validationStore")
@inject("applicationStore")
@observer
export default class RadioBreakdown extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            otherValue: ""
        };
    }

    firstLetterToUpperCase = (value: string) => {
        return value
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.substring(1))
            .join(" ");
    };

    optionChanged = async () => {
        if (this.props.otherFieldUnit) {
            await this.updateGeneralForm(this.props.otherFieldUnit, "", this.props.formType);
        }
        if (this.props.otherInfo) {
            await this.updateGeneralForm(this.props.otherInfo + ":value", "", this.props.formType);
        }
    };

    updateGeneralForm = async (fieldName: string, value: string, formType: string) => {
        const request: GenericFormRequest = {
            form: formType,
            field: fieldName,
            value: value
        };

        try {
            await this.props.applicationStore!.updateGeneralForm(request);
        } catch (error) {
            errorMessage(error);
        }
    };

    componentDidMount() {
        if (this.props.required) {
            const { options, otherInfo, formType, fieldAmount, fieldUnit, label } = this.props;

            this.props.validationStore!.offerValidationUpdate(formType, label, (store: ApplicationStore) => {
                const applicationStore = this.props.applicationStore!;
                const optionValue = options.filter(o => o.value);
                const isValidRadio = applicationStore.findGeneralFormValue(formType, this.props.fieldName);
                const filteredUnits =
                    options.filter(o => o.value === isValidRadio).length > 0 && options.filter(o => o.value === isValidRadio)[0].applicableUnits;

                let isValid = isValidRadio ? true : false;

                if (isValidRadio !== "other" && fieldUnit !== undefined && filteredUnits && filteredUnits.length > 1) {
                    isValid = isValid && filteredUnits.some(u => u.value === applicationStore.findGeneralFormValue(formType, fieldUnit!));
                }

                if (!!fieldAmount) {
                    isValid = isValid && !!applicationStore.findGeneralFormValue(formType, fieldAmount);
                }

                if (isValidRadio === "other") {
                    const isValidOthers = !!applicationStore.findGeneralFormValue(formType, otherInfo + ":value");
                    isValid = isValid && isValidOthers;
                }

                return isValid;
            });
        }
    }

    handleValidation = () => {
        const store = this.props.applicationStore!;

        const optionValue = this.props.options.filter(o => o.value);
        const isValidRadio = store.findGeneralFormValue(this.props.formType, this.props.fieldName);
        const filteredUnits =
            this.props.options.filter(o => o.value === isValidRadio).length > 0 &&
            this.props.options.filter(o => o.value === isValidRadio)[0].applicableUnits;

        let isValid = isValidRadio ? true : false;

        if (isValidRadio !== "other" && this.props.fieldUnit !== undefined && filteredUnits && filteredUnits.length > 1) {
            isValid = isValid && filteredUnits.some(u => u.value === store.findGeneralFormValue(this.props.formType, this.props.fieldUnit!));
        }

        if (!!this.props.fieldAmount) {
            isValid = isValid && !!store.findGeneralFormValue(this.props.formType, this.props.fieldAmount);
        }

        if (isValidRadio === "other") {
            const isValidOthers = !!store.findGeneralFormValue(this.props.formType, this.props.otherInfo + ":value");
            isValid = isValid && isValidOthers;
        }

        return isValid;
    };

    render() {
        const otherInfoName = this.props.otherInfo ? this.props.otherInfo + ":name" : undefined;
        const otherValue = this.props.otherInfo ? this.props.otherInfo + ":value" : undefined;
        const value = this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.fieldName);
        const option = this.props.options.find(field => field.value === value);
        const isValid = this.props.required ? this.handleValidation() : false;
        return (
            <form className={`${this.props.label && this.props.hideLabel !== true ? ` mb-3 pb-3` : ``} `}>
                <div className="form-row">
                    <div className="col-12 col-lg-8 mx-auto">
                        {this.props.label && this.props.hideLabel !== true && (
                            <div className={`form-row mb-3 pb-2 border-info border-bottom d-flex ${isValid ? `text-success` : `text-info`}`}>
                                <div>
                                    {this.props.label}: {this.props.required ? <span className="text-danger"> * </span> : ""}
                                </div>
                                {this.props.required && (
                                    <div className="ml-auto">
                                        {isValid ? (
                                            <FontAwesomeIcon icon={faCheck} />
                                        ) : (
                                            <>
                                                <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                                <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        {this.props.options.map(field => {
                            return (
                                <div key={field.label} className="form-row mx-0 px-0">
                                    <div className="col-12">
                                        <div className="form-check mb-2">
                                            <label>
                                                <FormRadioField
                                                    fieldName={this.props.fieldName}
                                                    fieldValue={field.value}
                                                    formType={this.props.formType}
                                                    onChanged={this.optionChanged}
                                                />
                                                <div className="new-label">{field.label}</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {this.props.otherInfo && otherInfoName && (
                    <>
                        <div className="form-row">
                            <div className="col-12 col-lg-8 mx-auto">
                                <div className="form-row mx-0 px-0">
                                    <div className="col-12 col-lg-2">
                                        <div className="form-check mb-2">
                                            <label>
                                                <FormRadioField fieldName={this.props.fieldName} fieldValue="other" formType={this.props.formType} />
                                                <div className="new-label">Other</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 col-lg-8 mx-auto">
                                <div className="form-row mx-0 px-0">
                                    {value === "other" && otherValue && (
                                        <div className="col-12 col-lg-6">
                                            <label className="font-italic font-weight-bolder">Other Information</label>
                                            <FormInputField formType={this.props.formType} fieldName={otherValue} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {this.props.fieldAmount && (value !== undefined && value !== "none") && (
                    <div className="form-row mb-3">
                        <div className="col-12 col-lg-8 mx-auto">
                            <div className="form-row mx-0 px-0">
                                <div className="col-12 col-lg-6">
                                    <label className="font-italic font-weight-bolder">How much consumed in {this.props.lastYear}?</label>
                                    <FormInputField formType={this.props.formType} type="number" fieldName={this.props.fieldAmount} />
                                </div>
                                {this.props.fieldUnit && (value !== undefined && value !== "none") && (
                                    <div className="col-12 col-lg-6">
                                        <label className="pl-3 font-italic font-weight-bolder">Unit:</label>
                                        <div className="form-row pl-3">
                                            {value !== "other" &&
                                                option &&
                                                option.applicableUnits &&
                                                option.applicableUnits.length > 1 &&
                                                option.applicableUnits.map(unit => {
                                                    return (
                                                        <div key={unit.value} className="col-4">
                                                            <div className="form-check">
                                                                <label className="d-flex">
                                                                    <FormRadioField
                                                                        fieldName={this.props.fieldUnit!}
                                                                        fieldValue={unit.value}
                                                                        formType={this.props.formType}
                                                                    />
                                                                    <div className="new-label">{unit.label}</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            {value !== "other" && option && option.applicableUnits && option.applicableUnits.length === 1 && (
                                                <div key={option.applicableUnits[0].value} className="col-4">
                                                    {option.applicableUnits[0].label}
                                                </div>
                                            )}
                                            {value === "other" && (
                                                <FormInputField
                                                    formType={this.props.formType}
                                                    fieldName={this.props.otherFieldUnit || this.props.fieldUnit!}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </form>
        );
    }
}
