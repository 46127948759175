import * as React from "react";
import {AddressUpdate, OneToManyDiscardedFlag} from "../../../model/ConfirmUpdate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import InputField from "../form-elements/InputField";
import {EmptyGroup, SelectGroup} from "../form-elements/SimpleFormComponents";
import {ApplicationStore} from "../../../stores/ApplicationStore";
import {inject, observer} from "mobx-react";
import {Commodity} from "../../../model/ProprietaryCommodity";
import {CommoditySquareFootCommodity} from "../../../model/CropProductionDetails";
import LoadingWidget from "../Loading";

export interface State {
}

interface Props {
    applicationStore?: ApplicationStore;
    useName?: boolean;
    value: AddressUpdate & OneToManyDiscardedFlag;
    loading?: boolean;
    disabled?: boolean;
    synchronizeValue?: boolean;
    onFieldChange: (fieldName: string, newValue: string | number | null) => any;
    showPhysicalFields?: boolean | false;
}

@inject("applicationStore")
@observer
export class AddressWidget extends React.Component<Props, State> {
    onChange = async (fieldName: string, newValue: string | number | null) => {
        if (this.props.loading) {
            return;
        }
        if (fieldName === "countryId") {
            await this.props.onFieldChange("provinceId", null);
            await this.props.onFieldChange("countryId", newValue);
        } else {
            await this.props.onFieldChange(fieldName, newValue);
        }
    };

    render() {
        const configuration = this.props.applicationStore!.configuration;
        if (!configuration) {
            console.warn("In the AddressWidget without configuration?");
            return <div/>;
        }

        const disabled = this.props.disabled || /*this.props.loading ||*/ this.props.value.discarded;
        const strikethrough = !!this.props.value.discarded;
        return (
            <div style={{backgroundColor: "#f4f4f4"}}>
                {/* TODO: Do we need a handler here? */}
                <button className="btn btn-sm btn-info one-to-many-delete-button">
                    <FontAwesomeIcon icon={faTimesCircle}/>
                </button>
                <div className="card-body py-2">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="form-row mb-0">
                            {this.props.useName && (
                                <EmptyGroup label="Optional Name (E.g., Main, second location, etc.):" name="name"
                                            className="col-md-12 col-lg-6">
                                    <InputField
                                        fieldName="name"
                                        value={this.props.value.name || ""}
                                        synchronizeValue={this.props.synchronizeValue}
                                        onBlur={(newValue: string) => this.onChange("name", newValue)}
                                        disabled={disabled}
                                        strikethrough={strikethrough}
                                    />
                                </EmptyGroup>
                            )}
                            <EmptyGroup
                                label="Street Address:"
                                isRequired={false}
                                name="address"
                                className={`col-md-12 ${this.props.useName ? `col-lg-6` : `col-12`}`}
                            >
                                <InputField
                                    fieldName="address"
                                    value={this.props.value.address || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => this.onChange("address", newValue)}
                                    disabled={disabled}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Postal Code:" isRequired={false} name="postalCode"
                                        className="col-md-12 col-lg-6">
                                <InputField
                                    fieldName="postalCode"
                                    value={this.props.value.postalCode || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => this.onChange("postalCode", newValue)}
                                    disabled={disabled}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="City:" name="city" isRequired={false} className="col-md-12 col-lg-6">
                                <InputField
                                    fieldName="city"
                                    value={this.props.value.city || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => this.onChange("city", newValue)}
                                    disabled={disabled}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <SelectGroup
                                label="Country:"
                                isRequired={false}
                                name="countryId"
                                className="col-md-12 col-lg-6"
                                disabled={disabled || this.props.applicationStore!.completed}
                                value={this.props.value.countryId || null}
                                onChange={e => this.onChange("countryId", parseInt(e.target.value, 10))}
                            >
                                {!this.props.value.countryId && <option value=""/>}
                                {configuration!.countries.map(c => (
                                    <option key={c.id} value={c.id}>
                                        {c.friendlyName}
                                    </option>
                                ))}
                            </SelectGroup>

                            <SelectGroup
                                label="Province:"
                                isRequired={false}
                                name="provinceId"
                                className="col-md-12 col-lg-6"
                                disabled={disabled || this.props.applicationStore!.completed}
                                value={this.props.value.provinceId || null}
                                onChange={e => this.onChange("provinceId", e.target.value)}
                            >
                                {!this.props.value.provinceId && <option value=""/>}
                                {configuration!.provinces
                                    .filter(p => p.countryId === this.props.value.countryId)
                                    .map(p => (
                                        <option key={p.id} value={p.id}>
                                            {p.friendlyName}
                                        </option>
                                    ))}
                            </SelectGroup>
                            {this.props.showPhysicalFields &&
                                <EmptyGroup
                                    label="Total Square Feet:"
                                    isRequired={false}
                                    name="totalSquareFeet"
                                    className={`col-md-12 ${this.props.useName ? `col-lg-6` : `col-12`}`}
                                >
                                    <InputField
                                        fieldName="totalSquareFeet"
                                        type="number"
                                        // value={this.props.value.totalSquareFeet ? this.props.value.totalSquareFeet.toString(10) : ""}
                                        value={this.props.value.totalSquareFeet || ""}
                                        synchronizeValue={this.props.synchronizeValue}
                                        onBlur={(newValue: string) => this.onChange("totalSquareFeet", newValue)}
                                        disabled={disabled}
                                        strikethrough={strikethrough}
                                    />
                                </EmptyGroup>
                            }
                            {this.props.showPhysicalFields &&
                                <EmptyGroup label="Commodities"
                                            name="totalSquareFeet"
                                            className={`col-md-12 ${this.props.useName ? `col-lg-12` : `col-12`}`}
                                >
                                    <div className="row mb-0 ml-0">
                                        <div className="col-12">
                                            <label className="font-weight-bolder">Cucumbers:</label>
                                        </div>
                                        <div className="col-12 row mb-0">
                                            <div className="col-12 mx-auto d-flex flex-wrap">
                                                {configuration.commodities.filter(c => c.type as Commodity === Commodity.CUCUMBER).map((c: CommoditySquareFootCommodity) => (
                                                    <div className="form-check mb-2 col-lg-4">
                                                        <label key={c.id}>
                                                            <div className="check-field-with-loading">
                                                                <input
                                                                    className="form-check-input pr-3"
                                                                    type="checkbox"
                                                                    name={"commodities:" + c.id.toString(10)}
                                                                    checked={(this.props.value.commodities ? this.props.value.commodities.indexOf('|Cucumber_' + c.friendlyName + ';') > -1 : false)}
                                                                    onChange={e => this.onChange("commodities", e.target.checked ? "Add_|Cucumber_" + c.friendlyName + ';' : '|Cucumber_' + c.friendlyName + ';')}
                                                                />
                                                                {this.props.loading &&
                                                                    <LoadingWidget className="loading-in-check"/>}
                                                            </div>
                                                            <div className="new-label">{c.friendlyName}</div>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-0 ml-0">
                                        <div className="col-12">
                                            <label className="font-weight-bolder">Peppers:</label>
                                        </div>
                                        <div className="col-12 row mb-0">
                                            <div className="col-12 mx-auto d-flex flex-wrap">

                                                {configuration.commodities.filter(c => c.type as Commodity === Commodity.PEPPER).map((c: CommoditySquareFootCommodity) => (
                                                    <div className="form-check mb-2 col-lg-4">
                                                        <label key={c.id}>
                                                            <div className="check-field-with-loading">
                                                                <input
                                                                    className="form-check-input pr-3"
                                                                    type="checkbox"
                                                                    name={"commodities:" + c.id.toString(10)}
                                                                    checked={(this.props.value.commodities ? this.props.value.commodities.indexOf('|Pepper_' + c.friendlyName + ';') > -1 : false)}
                                                                    onChange={e => this.onChange("commodities", e.target.checked ? "Add_|Pepper_" + c.friendlyName + ';' : '|Pepper_' + c.friendlyName + ';')}
                                                                />
                                                                {this.props.loading &&
                                                                    <LoadingWidget className="loading-in-check"/>}
                                                            </div>
                                                            <div className="new-label">{c.friendlyName}</div>
                                                        </label>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-0 ml-0">
                                        <div className="col-12">
                                            <label className="font-weight-bolder">Tomatoes:</label>
                                        </div>
                                        <div className="col-12 row mb-0">
                                            <div className="col-12 mx-auto d-flex flex-wrap">

                                                {configuration.commodities.filter(c => c.type as Commodity === Commodity.TOMATO).map((c: CommoditySquareFootCommodity) => (
                                                    <div className="form-check mb-2 col-lg-4">
                                                        <label key={c.id}>
                                                            <div className="check-field-with-loading">
                                                                <input
                                                                    className="form-check-input pr-3"
                                                                    type="checkbox"
                                                                    name={"commodities:" + c.id.toString(10)}
                                                                    checked={(this.props.value.commodities ? this.props.value.commodities.indexOf('|Tomato_' + c.friendlyName + ';') > -1 : false)}
                                                                    onChange={e => this.onChange("commodities", e.target.checked ? "Add_|Tomato_" + c.friendlyName + ';' : '|Tomato_' + c.friendlyName + ';')}
                                                                />
                                                                {this.props.loading &&
                                                                    <LoadingWidget className="loading-in-check"/>}
                                                            </div>
                                                            <div className="new-label">{c.friendlyName}</div>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </EmptyGroup>
                            }

                            {/*<SelectGroup*/}
                            {/*    label="Commodities:"*/}
                            {/*    isRequired={false}*/}
                            {/*    name="commodities"*/}
                            {/*    className="col-md-12 col-lg-6"*/}
                            {/*    value={this.props.value.commodities || ""}*/}
                            {/*    disabled={disabled || this.props.applicationStore!.completed}*/}
                            {/*    onChange={e => this.onChange("commodities", e.target.value)}*/}
                            {/*    inputClassName={"multiple"}*/}
                            {/*    multiple={true}*/}
                            {/*>*/}
                            {/*     <optGroup label={"Cucumber"}>*/}
                            {/*        {configuration.commodities.filter(c => c.type as Commodity === Commodity.CUCUMBER).map((c: CommoditySquareFootCommodity) => (*/}
                            {/*            <option key={c.id} value={c.id}>*/}
                            {/*                {c.friendlyName}*/}
                            {/*            </option>*/}
                            {/*        ))}*/}
                            {/*     </optGroup>*/}

                            {/*    <optGroup label={"Pepper"}>*/}
                            {/*        {configuration.commodities.filter(c => c.type as Commodity === Commodity.PEPPER).map((c: CommoditySquareFootCommodity) => (*/}
                            {/*            <option key={c.id} value={c.id}>*/}
                            {/*                {c.friendlyName}*/}
                            {/*            </option>*/}
                            {/*        ))}*/}
                            {/*    </optGroup>*/}

                            {/*    <optGroup label={"Tomato"}>*/}
                            {/*        {configuration.commodities.filter(c => c.type as Commodity === Commodity.TOMATO).map((c: CommoditySquareFootCommodity) => (*/}
                            {/*            <option key={c.id} value={c.id}>*/}
                            {/*                {c.friendlyName}*/}
                            {/*            </option>*/}
                            {/*        ))}*/}
                            {/*    </optGroup>*/}

                            {/*    /!*{configuration!.commodities.map(c => (*!/*/}
                            {/*    /!*    <option key={c.id} value={c.id}>*!/*/}
                            {/*    /!*        {c.friendlyName}*!/*/}
                            {/*    /!*    </option>*!/*/}
                            {/*    /!*))}*!/*/}
                            {/*</SelectGroup>*/}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
