import * as React from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GrowerMarketerUpdate, GrowerPackerUpdate, OneToManyDiscardedFlag } from "../../../model/ConfirmUpdate";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import InputField from "../form-elements/InputField";
import { EmptyGroup } from "../form-elements/SimpleFormComponents";

export interface State {
    seelingSideRoadStandPercentage: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    value: (GrowerMarketerUpdate | GrowerPackerUpdate) & OneToManyDiscardedFlag;
    loading?: boolean;
    disabled?: boolean;
    synchronizeValue?: boolean;
    onFieldChange: (fieldName: string, newValue: string | number | null) => any;
}

export default class TraceabilityCodeGrowerPackerWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            seelingSideRoadStandPercentage: ""
        };
    }

    render() {
        const onChange = (fieldName: string, newValue: string | number | null) => {
            if (this.props.loading) {
                return;
            }
            this.props.onFieldChange(fieldName, newValue);
        };

        const disabledCalculated = this.props.disabled || /*this.props.loading ||*/ this.props.value.discarded;
        const strikethrough = !!this.props.value.discarded;

        return (
            <div style={{ backgroundColor: "#f4f4f4" }}>
                <button className="btn btn-sm btn-info one-to-many-delete-button">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
                <div className="card-body pl-0 py-0 mb-0">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="form-row">
                            <EmptyGroup label="Packer Name:" name="Packer" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="packer"
                                    value={(this.props.value as GrowerPackerUpdate).packer || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("packer", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Percentage:" name="percentage" className="col-md-12 col-lg-4" isRequired={true}>
                                <div className="d-flex">
                                    <InputField
                                        fieldName="percentage"
                                        type="number"
                                        value={(this.props.value as GrowerPackerUpdate).percentage || ""}
                                        synchronizeValue={this.props.synchronizeValue}
                                        onBlur={(newValue: string) => onChange("percentage", newValue)}
                                        disabled={disabledCalculated}
                                        strikethrough={strikethrough}
                                    />
                                    <div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>
                                        <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>
                                            %
                                        </div>
                                    </div>
                                </div>
                            </EmptyGroup>
                            <EmptyGroup label="Traceability Codes:" name="codes" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="codes"
                                    value={(this.props.value as GrowerPackerUpdate).codes || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("codes", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
