export const BuildUri = (path: string, replacementParams: { [key: string]: any } = {}, queryParams: { [key: string]: string } = {}): string => {
    let uri = process.env.REACT_APP_BACKEND_PREFIX + path;
    Object.keys(replacementParams).forEach(key => (uri = uri.replace(new RegExp("{" + key + "}", "g"), replacementParams[key])));
    if (queryParams && Object.keys(queryParams).length > 0) {
        uri += "?1=1";
        Object.keys(queryParams)
            .filter(key => queryParams[key] !== undefined)
            .forEach(key => (uri = uri + "&" + encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])));
    }
    return uri;
};
