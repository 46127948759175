import { inject, observer } from "mobx-react";
import * as React from "react";

import { DownloadBlob, FileToBase64 } from "../../../assets/Common/FileUtil";
import { SubmissionEndpoints } from "../../../endpoints/SubmissionEndpoints";
import { MarketerSubmission } from "../../../model/Submission";
import { MarketerValidationRules } from "../../../model/validation/MarketerValidation";
import { EvaluateAllRulesForSubsection } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import Accordion from "../../Common/Accordion";
import AttachmentWidget from "../../Common/AttachmentWidget";
import { Disclamer } from "../../Common/Disclamer";
import { SimpleConfirmation } from "../../Common/SimpleConfirmAlert";
import { errorMessage, successMessage } from "../../Common/Toast";
import ContentHeader from "../../Template/ContentHeader";

interface Props {
    submissionEndpoints?: SubmissionEndpoints;
    applicationStore?: ApplicationStore;
}

interface State {
    currentAccordion: string;
}

@inject("submissionEndpoints")
@inject("applicationStore")
@observer
export default class MarketerAttachments extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentAccordion: "sampleNetPay"
        };
    }

    setCurrentAccordion = (name: string) => {
        this.setState({ currentAccordion: name });
    };

    addAttachment = async (file: File, attachmentType: string) => {
        const fileBase64 = await FileToBase64(file);
        const request = {
            name: file.name,
            contentType: file.type,
            data: fileBase64,
            attachmentType
        };
        try {
            await this.props.applicationStore!.addAttachment(request);
            successMessage(`${file.name} has been attached.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    deleteAttachment = async (attachmentId: number) => {
        const goAhead = await SimpleConfirmation(`Are you sure you'd like to delete this attachment? This operation cannot be undone`);
        if (!goAhead) {
            return;
        }
        try {
            await this.props.applicationStore!.deleteAttachment(attachmentId);
            successMessage(`The attachment has been deleted.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    getAttachment = async (fileName: string, attachmentId: number) => {
        try {
            const blob = await this.props.submissionEndpoints!.getAttachment(attachmentId);
            DownloadBlob(fileName, blob);
        } catch (error) {
            errorMessage(error);
        }
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission as MarketerSubmission;
        const member = applicationStore.submission!.member;
        const attachments = (applicationStore.submission! as MarketerSubmission).marketerAttachments;
        const completed = (this.props.applicationStore!.submission! as MarketerSubmission).completed;
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Attachments" />
                <div className="row content">
                    <div className="col-12 py-3">
                        <h5 className="text-center">Additional Licencing Information</h5>
                    </div>
                    <div className="col-12 mt-3 px-0">
                        <div className="alert alert-warning shadow-sm text-center" role="alert">
                            Please attach a file for each of the documents listed below.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 ">
                            <Accordion
                                title="Copy of Sample Net Pay Slip"
                                name="sampleNetPay"
                                required={true}
                                currentAccordion={this.state.currentAccordion}
                                backPage="/marketer/page-5b-business-overview"
                                nextAccordion="copyOfValidFoodSafetyCertificate"
                                onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                                completed={EvaluateAllRulesForSubsection(MarketerValidationRules.attachments.subsections.sampleNetPay, submission)}
                            >
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 mx-auto">
                                        <AttachmentWidget
                                            attachments={attachments.sampleNetPay}
                                            addAttachment={file => this.addAttachment(file, "sampleNetPay")}
                                            deleteAttachment={this.deleteAttachment}
                                            getAttachment={this.getAttachment}
                                        />
                                    </div>
                                </div>
                            </Accordion>
                            <Accordion
                                title="Copy of Valid Food Safety Certificate"
                                name="copyOfValidFoodSafetyCertificate"
                                required={true}
                                currentAccordion={this.state.currentAccordion}
                                previousAccordion="sampleNetPay"
                                nextAccordion="drcLicence"
                                onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                                completed={EvaluateAllRulesForSubsection(MarketerValidationRules.attachments.subsections.foodSafetyCert, submission)}
                            >
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 mx-auto">
                                        <AttachmentWidget
                                            attachments={attachments.foodSafetyCert}
                                            addAttachment={file => this.addAttachment(file, "foodSafetyCert")}
                                            deleteAttachment={this.deleteAttachment}
                                            getAttachment={this.getAttachment}
                                        />
                                    </div>
                                </div>
                            </Accordion>
                            <Accordion
                                title="Copy of DRC Licence (Optional)"
                                name="drcLicence"
                                required={false}
                                currentAccordion={this.state.currentAccordion}
                                previousAccordion="copyOfValidFoodSafetyCertificate"
                                nextAccordion="marketingPlan"
                                onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                            >
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 mx-auto">
                                        <AttachmentWidget
                                            attachments={attachments.drcLicence}
                                            addAttachment={file => this.addAttachment(file, "drcLicence")}
                                            deleteAttachment={this.deleteAttachment}
                                            getAttachment={this.getAttachment}
                                        />
                                    </div>
                                </div>
                            </Accordion>
                            <Accordion
                                title="Marketing Plan"
                                name="marketingPlan"
                                required={true}
                                currentAccordion={this.state.currentAccordion}
                                previousAccordion="drcLicence"
                                nextAccordion="biosecurityPlan"
                                onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                                completed={EvaluateAllRulesForSubsection(MarketerValidationRules.attachments.subsections.marketingPlan, submission)}
                            >
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 mx-auto">
                                        Refer to the document "Appendix A" for details on what to include in your marketing plan &nbsp;
                                        <a
                                            href={process.env.PUBLIC_URL + "/documents/Appendix A-20-21.pdf"}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            AppendixA.pdf
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 mx-auto">
                                        <AttachmentWidget
                                            attachments={attachments.marketingPlan}
                                            addAttachment={file => this.addAttachment(file, "marketingPlan")}
                                            deleteAttachment={this.deleteAttachment}
                                            getAttachment={this.getAttachment}
                                        />
                                    </div>
                                </div>
                            </Accordion>
                            <Accordion
                                title="Biosecurity Plan"
                                name="biosecurityPlan"
                                required={true}
                                currentAccordion={this.state.currentAccordion}
                                previousAccordion="marketingPlan"
                                nextPage="/marketer/signature-and-submission"
                                onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                                completed={EvaluateAllRulesForSubsection(MarketerValidationRules.attachments.subsections.biosecurityPlan, submission)}
                            >
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 mx-auto">
                                        <AttachmentWidget
                                            attachments={attachments.biosecurityPlan}
                                            addAttachment={file => this.addAttachment(file, "biosecurityPlan")}
                                            deleteAttachment={this.deleteAttachment}
                                            getAttachment={this.getAttachment}
                                        />
                                    </div>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                    <Disclamer />
                </div>
            </div>
        );
    }
}
