import { inject } from "mobx-react";
import * as React from "react";
import {Dropdown, DropdownItem, UncontrolledTooltip} from "reactstrap";

import { faCheck, faCheckCircle, faCheckSquare, faCircle, faDotCircle, faEdit, faHandPaper, faInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ConfirmUpdateOutcome } from "../../model/ConfirmUpdate";
import { ConfirmUpdateValidity } from "../../model/validation/Validation";
import { ApplicationStore } from "../../stores/ApplicationStore";
import {SelectGroup} from "./SimpleFormComponents";
import LoadingWidget from "./Loading";
import {YieldUnit} from "../../model/Configuration";
import {Commodity} from "../../model/ProprietaryCommodity";

const OneToManyTip = ({ validity }: { validity: ConfirmUpdateValidity }) => {
    if (validity === ConfirmUpdateValidity.OUTCOME_BLANK) {
        return (
            <span className="badge badge-warning">
                <FontAwesomeIcon icon={faHandPaper} />
                &nbsp; Please review the details below and press confirm or update.
            </span>
        );
    } else if (validity === ConfirmUpdateValidity.MISSING_ENTRIES) {
        return (
            <span className="badge badge-warning">
                <FontAwesomeIcon icon={faHandPaper} />
                &nbsp; At least one entry is required. Click <em>Add new</em> to create a new entry.
            </span>
        );
    } else if (validity === ConfirmUpdateValidity.UNFILLED_FIELDS) {
        return (
            <span className="badge badge-warning">
                <FontAwesomeIcon icon={faHandPaper} />
                &nbsp; One or more fields are missing below.
            </span>
        );
    }
    return <div />;
};

export const OneToManyWrapper = inject("applicationStore")(
    ({
        applicationStore,
        children,
        confirmUpdateOutcome,
        onConfirmUpdateChange,
        label,
        loading,
        onAddNewChildren,
        hideConfirmUpdate,
        validity,
        dropDownMax,
        dropDownCurrent,
         showDropdown,
        onChangeDropDown,
    }: {
        applicationStore?: ApplicationStore;
        children: React.ReactNode;
        confirmUpdateOutcome: ConfirmUpdateOutcome;
        onConfirmUpdateChange: (confirmUpdate: ConfirmUpdateOutcome) => void;
        label: string;
        loading?: boolean;
        onAddNewChildren: () => void;
        hideConfirmUpdate?: boolean;
        validity?: ConfirmUpdateValidity;
        dropDownMax?:number;
        dropDownCurrent?:number;
        showDropdown?: boolean;
        onChangeDropDown: (expected: string ) => void;
    }) => {
        const labelToId = label
            .replace(/[|&;$%@"<>()+,]/g, "")
            .split(" ")
            .join("_");
        const wrapperDdlLabel = "Number Of " + label +":";
        let DdlOptions;
        DdlOptions = (): Array<number> => {
            let arr = Array<number>();
            if(dropDownMax == null || dropDownMax == undefined || dropDownMax == 0) return arr;
            for (let i = 0; i < dropDownMax; i++) {
                arr.push(i+1);
            }
            return arr;
        }


        return (
            <>
                <div className="row mb-1">
                    <div className="col-12 px-0 py-0">
                        <div className="d-flex align-items-start">
                            <label style={{ position: "relative", bottom: "-10px", width: "100%" }}>
                                <h5>
                                    {label}
                                    {loading && (
                                        <span className="ml-3">
                                            <LoadingWidget />
                                        </span>
                                    )}
                                </h5>
                                <h6>{validity ? <OneToManyTip validity={validity} /> : null}</h6>
                            </label>
                            <div className="d-flex ml-3 flex-shrink-0">
                                {showDropdown && (
                                    <SelectGroup
                                        label={wrapperDdlLabel}
                                        isRequired={false}
                                        // id="expected"
                                        name="expected"
                                        className="col-md-12 col-lg-12"
                                        value={dropDownCurrent}
                                        disabled={false}
                                        onChange={ e => onChangeDropDown(e.target.value) }
                                    >
                                        {
                                            DdlOptions()
                                            .map((each) => (
                                                <option id={"expected" + each.toString()} key={"expected"+each.toString()} value={each}>{each}</option>
                                            ))};
                                    </SelectGroup>



                                )}
                            </div>
                            <div className="btn-group btn-group-toggle d-flex ml-3 flex-shrink-0 widthToggleButtons" data-toggle="buttons">
                                {!hideConfirmUpdate && (
                                    <>
                                        <button
                                            className={`btn ${confirmUpdateOutcome === "CONFIRMED" ? `btn-primary active` : `btn-outline-primary`}`}
                                            onClick={() => onConfirmUpdateChange("CONFIRMED")}
                                            id={labelToId + "_confirm"}
                                            disabled={applicationStore!.completed}
                                        >
                                            {/* {confirmUpdateOutcome === "CONFIRMED" ? (
                                            <FontAwesomeIcon icon={faDotCircle} className="mr-2" />
                                        ) : (
                                            <FontAwesomeIcon icon={["far", "circle"]} className="mr-2" />
                                        )} */}
                                            Confirm
                                        </button>
                                        <UncontrolledTooltip placement="top" target={labelToId + "_confirm"} delay={0} className="tooltipstrap">
                                            Confirm last year's information {label ? `for ${label}` : ""}
                                        </UncontrolledTooltip>
                                        <button
                                            className={`btn ${confirmUpdateOutcome === "UPDATED" ? `btn-primary active` : `btn-outline-primary`}`}
                                            onClick={() => onConfirmUpdateChange("UPDATED")}
                                            id={labelToId + "_update"}
                                            disabled={applicationStore!.completed}
                                        >
                                            Update
                                        </button>
                                        <UncontrolledTooltip placement="top" target={labelToId + "_update"} delay={0} className="tooltipstrap">
                                            Update {label}
                                        </UncontrolledTooltip>
                                    </>
                                )}
                                {/* */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-one-to-many mb-5">
                    <div className="col-12 px-0">{children}</div>
                    {confirmUpdateOutcome === "UPDATED" && !applicationStore!.completed && (
                        <div className="col-12 px-0 ">
                            <button
                                className={`btn btn-sm btn-primary ${confirmUpdateOutcome !== "UPDATED" ? `disabled` : ``}`}
                                disabled={confirmUpdateOutcome !== "UPDATED" || applicationStore!.completed}
                                onClick={() => onAddNewChildren()}
                                id={labelToId + "_new"}
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                Add new
                            </button>
                            <UncontrolledTooltip placement="top" target={labelToId + "_new"} delay={0} className="tooltipstrap">
                                Add New {label}
                            </UncontrolledTooltip>
                        </div>
                    )}
                </div>
            </>
        );
    }
);
