import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { OneToManyDiscardedFlag, PackerGrowerUpdate } from "../../../model/ConfirmUpdate";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { EmptyGroup } from "../form-elements/SimpleFormComponents";
import InputField from "../form-elements/InputField";

export interface State {
    seelingSideRoadStandPercentage: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    value: PackerGrowerUpdate & OneToManyDiscardedFlag;
    loading?: boolean;
    disabled?: boolean;
    synchronizeValue?: boolean;
    onFieldChange: (fieldName: string, newValue: string | number | null) => any;
}

export default class TraceabilityCodePackerWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            seelingSideRoadStandPercentage: ""
        };
    }

    render() {
        const onChange = (fieldName: string, newValue: string | number | null) => {
            if (this.props.loading) {
                return;
            }
            this.props.onFieldChange(fieldName, newValue);
        };

        const disabledCalculated = this.props.disabled || /*this.props.loading ||*/ this.props.value.discarded;
        const strikethrough = !!this.props.value.discarded;

        return (
            <div style={{ backgroundColor: "#f4f4f4" }}>
                <button className="btn btn-sm btn-info one-to-many-delete-button">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
                <div className="card-body pl-0 py-0 mb-0">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="form-row">
                            <EmptyGroup label="Grower Name:" name="growers" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="growers"
                                    value={(this.props.value as PackerGrowerUpdate).growers || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("growers", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Marketer(s) Used:" name="marketersUsed" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="marketersUsed"
                                    value={(this.props.value as PackerGrowerUpdate).marketersUsed || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("marketersUsed", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Traceability Codes:" name="codes" className="col-md-12 col-lg-4" isRequired={true}>
                                <InputField
                                    fieldName="codes"
                                    value={(this.props.value as PackerGrowerUpdate).codes || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("codes", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
