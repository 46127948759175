import { GrowerSubmission, Submission, PackerSubmission, MarketerSubmission } from "../Submission";
import {
    BuildConfirmUpdateOneToManyValidityCheck,
    BuildSimpleFieldValidityCheck,
    PackerValidation,
    MarketerValidation,
    ValidityCheck
} from "./Validation";
import {
    CalculateOneToManyValidationState,
    CalculateSingleValueValidationState,
    ConfirmUpdateValidity,
    GrowerValidation,
    BuildConfirmUpdateSingleValueValidityCheck
} from "./Validation";

export const BuildMarketerAttachmentValidityCheck = (attachmentName: string, message: string): ValidityCheck => {
    return {
        isValid: (submission: Submission) => (submission as MarketerSubmission).marketerAttachments[attachmentName].length > 0,
        message: (submission: Submission) => ((submission as MarketerSubmission).marketerAttachments[attachmentName].length > 0 ? message : "")
    };
};

const ValidateSalesReport = (submission: Submission) => {
    const form5A = (submission as MarketerSubmission).generalForms["sales-reporting"];
    const valuesEntered = Object.keys(form5A).filter(key => !!form5A[key]);
    const volumeValues = valuesEntered.filter(key => key.indexOf(":volume:value") >= 0);

    return volumeValues.some(volumeKey => {
        const split = volumeKey.split(":");
        const prefix = `${split[0]}:${split[1]}`;
        return (
            valuesEntered.some(valueKey => valueKey.indexOf(`${prefix}:netPay`) === 0) &&
            valuesEntered.some(valueKey => valueKey.indexOf(`${prefix}:avgSellingPrice`) === 0)
        );
    });
};

const ValidateBusinessOverview = (submission: Submission) => {
    const form5B = (submission as MarketerSubmission).generalForms["business-overview"];
    const valuesEntered = Object.keys(form5B).filter(key => !!form5B[key]);

    const domesticValues = valuesEntered.filter(key => key.indexOf(":other") < 0).some(key => key.indexOf(":domestic:value") >= 0);
    const exportValues = valuesEntered.filter(key => key.indexOf(":other") < 0).some(key => key.indexOf(":export:value") >= 0);
    const otherValues = valuesEntered.filter(key => key.indexOf(":other") >= 0);

    const otherValidation = otherValues.some(otherKey => {
        const split = otherKey.split(":");
        const prefix = `${split[0]}:${split[1]}:other`;
        return (
            valuesEntered.some(valueKey => valueKey.indexOf(`${prefix}:name`) === 0) &&
            (valuesEntered.some(valueKey => valueKey.indexOf(`${prefix}:export`) === 0) ||
                valuesEntered.some(valueKey => valueKey.indexOf(`${prefix}:domestic`) === 0))
        );
    });

    return domesticValues || exportValues || otherValidation;
};

export const MarketerValidationRules: MarketerValidation = {
    form5: {
        name: "Form 5 - Marketers's Information",
        subsections: {
            marketersInformation: {
                name: "Marketer's Information",
                checks: [
                    BuildConfirmUpdateSingleValueValidityCheck("legalMemberName", "Missing legal member name"),
                    BuildSimpleFieldValidityCheck("ownAnyOtherGreenhouses", "Missing whether any other greenhouses are owned"),
                    BuildSimpleFieldValidityCheck("biosecurityInPlace", "Missing whether any biosecurity protocols are in pace"),
                    BuildSimpleFieldValidityCheck("marketAnyProductInAddition", "Missing whether any produce outside of Ontario is being marketed")
                ]
            },
            mailingAddress: {
                name: "Mailing Address",
                checks: [BuildConfirmUpdateSingleValueValidityCheck("mailingAddress", "Missing one or more fields for the mailing address",['commodities','totalSquareFeet'])]
            },
            physicalAddresses: {
                name: "Physical Address(es)",
                checks: [BuildConfirmUpdateOneToManyValidityCheck("physicalAddresses", "Missing one or more fields for a physical address",['commodities','totalSquareFeet'])]
            },
            contacts: {
                name: "Contacts",
                checks: [
                    BuildConfirmUpdateOneToManyValidityCheck("ownerContact", "Missing one or more fields for an owner contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("primaryContact", "Missing one or more fields for a primary contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("foodSafetyContact", "Missing one or more fields for a food safety contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("biosecurityContact", "Missing one or more fields for a biosecurity contact")
                ]
            },
            traceabilityCodes: {
                name: "Traceability Codes",
                checks: [BuildConfirmUpdateOneToManyValidityCheck("marketerGrowerTraceability", "Missing one or more fields for a traceability code")]
            }
        }
    },
    attachments: {
        name: "Attachments",
        subsections: {
            sampleNetPay: {
                name: "Copy of Sample Net Pay Slip",
                checks: [BuildMarketerAttachmentValidityCheck("sampleNetPay", "Missing a sample net pay slip attachment")]
            },
            foodSafetyCert: {
                name: "Copy of Valid Food Safety Certificate",
                checks: [BuildMarketerAttachmentValidityCheck("foodSafetyCert", "Missing a valid food safety certificate attachment")]
            },
            marketingPlan: {
                name: "Marketing Plan",
                checks: [BuildMarketerAttachmentValidityCheck("marketingPlan", "Missing a marketing plan attachment")]
            },
            biosecurityPlan: {
                name: "Biosecurity Plan",
                checks: [BuildMarketerAttachmentValidityCheck("biosecurityPlan", "Missing a biosecurity plan attachment")]
            }
        }
    },
    form5A: {
        name: "Form 5A - Sales Reporting",
        subsections: {
            form: {
                name: "Form 5A - Sales Reporting",
                checks: [
                    {
                        isValid: (submission: Submission) => {
                            return ValidateSalesReport(submission);
                        },
                        message: (submission: Submission) => {
                            return ValidateSalesReport(submission) ? "" : "Missing one or more fields for the Form 5A - Sales Reporting";
                        }
                    }
                ]
            }
        }
    },
    form5B: {
        name: "Form 5B - Business Overview",
        subsections: {
            form: {
                name: "Form 5B - Business Overview",
                checks: [
                    {
                        isValid: (submission: Submission) => {
                            return ValidateBusinessOverview(submission);
                        },
                        message: (submission: Submission) => {
                            return ValidateBusinessOverview(submission) ? "" : "Missing one or more fields for the Form 5B - Business Overview";
                        }
                    }
                ]
            }
        }
    }
};
