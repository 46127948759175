import * as React from "react";
import { observer, inject } from "mobx-react";
import { ApplicationStore } from "../../stores/ApplicationStore";

export const Header = inject("applicationStore")(
    observer(({ applicationStore }: { applicationStore?: ApplicationStore }) => {
        const friendlyName = applicationStore!.submission!.licenceYear.friendlyName;

        return (
            <div className="main-header">
                <div className="row">
                    <div className="col-6 text-left pl-0">{friendlyName} Licence Application</div>
                    {/* <div className="col-6 text-right">
                        <button type="button" onClick={() => applicationStore!.logout()} className="btn btn-sm btn-warning">
                            Logout
                        </button>
                    </div> */}
                </div>
            </div>
        );
    })
);
