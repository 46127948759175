import { inject, observer } from "mobx-react";
import * as React from "react";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MarketerSubmission } from "../../../model/Submission";
import { GenericFormRequest } from "../../../requests/SimpleFieldRequests";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { FormNavigation } from "../../Common/FormNavigation";
import FormInputField from "../../Common/general-forms/FormInputField";
import { errorMessage } from "../../Common/Toast";
import ContentHeader from "../../Template/ContentHeader";

const FORM_NAME = "sales-reporting";
interface Props {
    applicationStore?: ApplicationStore;
}

interface State {}

@inject("applicationStore")
@observer
export default class Form5ASalesReporting extends React.Component<Props, State> {
    simpleFieldUpdate = async (fieldName: string, value: string) => {
        const request: GenericFormRequest = {
            form: FORM_NAME,
            field: fieldName,
            value: value
        };

        try {
            await this.props.applicationStore!.updateGeneralForm(request);
            //successMessage(`${text} has been successfully updated.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const member = applicationStore.submission!.member;
        const licence = applicationStore.submission!.licenceYear;
        const lincenceYear = new Date(licence.startDate).getFullYear();
        const completed = (this.props.applicationStore!.submission! as MarketerSubmission).completed;

        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Form 5A - Sales Reporting" />
                <div className="row content">
                    <div className="col-12 py-3">
                        <h5 className="text-center">
                            Sales Reporting for the Time Period between October 1st, {lincenceYear - 1} and September  30th, {lincenceYear} Information.
                            <br />
                            For Sale of Ontario Product Only.
                        </h5>
                    </div>
                    <div className="col-12 mt-3">
                        {!applicationStore.form5AValidated && (
                            <div className="alert alert-warning">
                                <h5>
                                    <FontAwesomeIcon icon={faExclamationTriangle} /> Please fill in at least one complete row below to continue.
                                </h5>
                            </div>
                        )}
                        <div className="alert alert-info">
                            <div>
                                <strong>Average net pay</strong> means the product of the total average reported net price (being the price on packed
                                product after all deductions (transportation, other marketer service charges, including commissions, HST, etc.) have
                                been made) divided by the total reported volume of greenhouse vegetables packed (whether it be by producer, packer or
                                marketer) for those greenhouse vegetables.
                            </div>
                            <div>
                                <strong>Average selling price</strong> means the average depvered price of the greenhouse vegetables to the end buyer
                                on the date of sale
                            </div>
                            <div>
                                <strong>
                                    1<sup>st</sup> Quarter
                                </strong>{" "}
                                means the time period between October 1 – December 31
                            </div>
                            <div>
                                <strong>
                                    2<sup>nd</sup> Quarter
                                </strong>{" "}
                                means the time period between January 1 – March 31
                            </div>
                            <div>
                                <strong>
                                    3<sup>rd</sup> Quarter
                                </strong>{" "}
                                means the time period between April 1 – June 30
                            </div>
                            <div>
                                <strong>
                                    4<sup>th</sup> Quarter
                                </strong>{" "}
                                means the time period between July 1 – September 30
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">Cucumbers</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-cucumbers">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Crop/Size</th>
                                                    <th className="text-center">Volume</th>
                                                    <th colSpan={4} className="text-center">
                                                        Average Net Pay
                                                    </th>
                                                    <th colSpan={4} className="text-center">
                                                        Average Selling Price
                                                    </th>
                                                </tr>
                                                <tr className="default">
                                                    <th colSpan={2} />
                                                    <th className="text-center">
                                                        <small>
                                                            1<sup>st</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>Oct. 1 to Dec. 31</small>
                                                    </th>
                                                    <th className="text-center">
                                                        <small>
                                                            2<sup>nd</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>Jan. 1 to March 31</small>
                                                    </th>
                                                    <th className="text-center">
                                                        <small>
                                                            3<sup>rd</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>April 1 to June 30</small>
                                                    </th>
                                                    <th className="text-center">
                                                        <small>
                                                            4<sup>th</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>July 1 to Sept. 30</small>
                                                    </th>
                                                    <th className="text-center">
                                                        <small>
                                                            1<sup>st</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>Oct. 1 to Dec. 31</small>
                                                    </th>
                                                    <th className="text-center">
                                                        <small>
                                                            2<sup>nd</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>Jan. 1 to March 31</small>
                                                    </th>
                                                    <th className="text-center">
                                                        <small>
                                                            3<sup>rd</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>April 1 to June 30</small>
                                                    </th>
                                                    <th className="text-center">
                                                        <small>
                                                            4<sup>th</sup>
                                                            <br /> Quarter
                                                        </small>
                                                        <br />
                                                        <small>July 1 to Sept. 30</small>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="success">
                                                    <td colSpan={10} className="line">
                                                        <strong>Mini Cucumbers</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">
                                                        12/6 Trays <small>(or equivalent)</small>
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:volume:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:netPay:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:netPay:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:netPay:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:netPay:q4:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:avgSellingPrice:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:avgSellingPrice:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:avgSellingPrice:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:126trays:avgSellingPrice:q4:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">20 lbs</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:volume:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:netPay:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:netPay:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:netPay:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:netPay:q4:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:avgSellingPrice:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:avgSellingPrice:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:avgSellingPrice:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="miniCucumbers:20lbs:avgSellingPrice:q4:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr className="success">
                                                    <td colSpan={10} className="line">
                                                        <strong>English Seedless Cucumbers - CTN 12's</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">Large</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:volume:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:netPay:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:netPay:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:netPay:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:netPay:q4:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:avgSellingPrice:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:avgSellingPrice:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:avgSellingPrice:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:large:avgSellingPrice:q4:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">Medium</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:volume:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:netPay:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:netPay:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:netPay:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:netPay:q4:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:avgSellingPrice:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:avgSellingPrice:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:avgSellingPrice:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:medium:avgSellingPrice:q4:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">Small</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:volume:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:netPay:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:netPay:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:netPay:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:netPay:q4:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:avgSellingPrice:q1:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:avgSellingPrice:q2:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:avgSellingPrice:q3:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="englishSeedless12s:small:avgSellingPrice:q4:value"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">Tomatoes</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-tomatoes">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Crop/Size</th>
                                                    <th className="text-center">Volume</th>
                                                    <th colSpan={4} className="text-center">
                                                        Average Net Pay
                                                    </th>
                                                    <th colSpan={4} className="text-center">
                                                        Average Selling Price
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr className="danger">
                                                    <td colSpan={10} className="line">
                                                        <strong>Beef Tomatoes</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">XL (16-32)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:xl:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:xl:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:xl:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">L (35-39)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:l:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:l:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:l:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">L/M (42-45)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:lm:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:lm:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="beefTomatoes:lm:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr className="danger">
                                                    <td className="line">Clusters (TOV's)</td>
                                                    <td>
                                                        <FormInputField formType="sales-reporting" type="number" fieldName="clusters:volume:value" />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField formType="sales-reporting" type="number" fieldName="clusters:netPay:value" />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="clusters:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">Peppers</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-peppers">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Crop/Size</th>
                                                    <th className="text-center">Volume</th>
                                                    <th colSpan={4} className="text-center">
                                                        Average Net Pay
                                                    </th>
                                                    <th colSpan={4} className="text-center">
                                                        Average Selling Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="warning">
                                                    <td colSpan={10} className="line">
                                                        <strong>Red Peppers</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">XXL (24 and larger)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:xxl:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:xxl:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:xxl:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">XL (25-28)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:xl:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:xl:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:xl:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">L (29-31)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:l:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:l:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:l:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">M (32-36)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:m:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:m:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:m:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">S (37 and smaller)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:s:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:s:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="redPeppers:s:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr className="warning">
                                                    <td colSpan={10} className="line">
                                                        <strong>Orange Peppers</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">XXL (24 and larger)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:xxl:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:xxl:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:xxl:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">XL (25-28)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:xl:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:xl:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:xl:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">L (29-31)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:l:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:l:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:l:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">M (32-36)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:m:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:m:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:m:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">S (37 and smaller)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:s:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:s:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="orangePeppers:s:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr className="warning">
                                                    <td colSpan={10} className="line">
                                                        <strong>Yellow Peppers</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">XXL (24 and larger)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:xxl:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:xxl:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:xxl:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">XL (25-28)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:xl:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:xl:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:xl:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">L (29-31)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:l:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:l:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:l:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">M (32-36)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:m:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:m:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:m:avgSellingPrice:value"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle">S (37 and smaller)</td>
                                                    <td>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:s:volume:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            formType="sales-reporting"
                                                            type="number"
                                                            fieldName="yellowPeppers:s:netPay:value"
                                                        />
                                                    </td>
                                                    <td colSpan={4}>
                                                        <FormInputField
                                                            fieldName="yellowPeppers:s:avgSellingPrice:value"
                                                            formType="sales-reporting"
                                                            type="number"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FormNavigation nextPath="/marketer/page-5b-business-overview" backPath="/marketer/form-5-marketers-information" />
                </div>
            </div>
        );
    }
}
