import { inject, observer } from "mobx-react";
import * as React from "react";

import { faTrash, faTrashRestore, faArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AddressCurrent, AddressUpdate, CopyAddressCurrentToUpdate, EmptyAddressUpdate, OneToManyUpdateValue } from "../../../model/ConfirmUpdate";
import { CalculateOneToManyValidationState } from "../../../model/validation/Validation";
import { OneToManyWrapper } from "../OneToManyWrapper";
import { AddressWidget } from "../simple-fields/AddressWidget";
import { CommonProps, CommonState, OneToManyCommon } from "./OneToManyCommon";

export interface State extends CommonState {}

interface Props extends CommonProps {}

@inject("applicationStore")
@observer
export default class PhysicalAddressesWidget extends OneToManyCommon<AddressCurrent, AddressUpdate, Props, State> {
    state: State = {
        loading: false
    };

    // @override To make sure the `countryId` being changed resets the `provinceId`
    onConfirmUpdateChangeOneToMany = async (objectKey: string, fieldName: string, value: any | number) => {
        const updated: OneToManyUpdateValue<AddressUpdate> = this.buildUpdated();
        if (fieldName !== "commodities" && updated[objectKey][fieldName] === value) {
            return;
        }
        if(fieldName === "commodities"){
            if(value.indexOf('Add_') > -1){
                if(updated[objectKey][fieldName] == null)
                {
                    updated[objectKey][fieldName] = value.toString().substring(4);
                }
                else {
                    updated[objectKey][fieldName] = updated[objectKey][fieldName] + value.toString().substring(4);
                }
            }
            else
            {
                if(updated[objectKey][fieldName] !== null){
                    let temp = updated[objectKey][fieldName] || "" ;
                    //if(temp !== null) {
                        while(temp.indexOf(value) > -1){
                            temp = temp.replace(value.toString(),"");
                        }
                        updated[objectKey][fieldName] = temp;
                    //}
                }
            }

        }
        else{
            updated[objectKey][fieldName] = value;
        }

        if (fieldName === "countryId") {
            updated[objectKey].provinceId = null;
        }

        this.updateOneToMany(updated);
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission;
        const memberType = applicationStore!.submission!.member.memberType;
        if (!submission) {
            console.warn("Submission is not ready yet");
            return <div />;
        }
        const confirmUpdate = submission.confirmUpdates[this.props.confirmUpdateName];
        if (!confirmUpdate) {
            console.error(`Could not find ${this.props.confirmUpdateName} Confirm-Update in submission object`);
            return <div className="text-danger">Error while loading field. Please contact support.</div>;
        }

        const configuration = this.props.applicationStore!.configuration;
        if (!configuration) {
            return <div />;
        }

        const updated = this.buildUpdated();
        const synchronizeValue = confirmUpdate.outcome !== "UPDATED";
        const optionalFields = memberType === "GROWER" ? ["Name"] : ["Name",'commodities','totalSquareFeet'] ;

        return (
            <>
            <OneToManyWrapper
                confirmUpdateOutcome={confirmUpdate.outcome}
                onConfirmUpdateChange={newValue => this.updateConfirmUpdateOutcome(newValue)}
                onAddNewChildren={() => this.addOneToMany()}
                label={this.props.label}
                loading={this.state.loading}
                validity={CalculateOneToManyValidationState(confirmUpdate, optionalFields)}
                hideConfirmUpdate={Object.keys(confirmUpdate.current).length === 0}
                showDropdown={true}
                dropDownCurrent={ confirmUpdate.expected ? confirmUpdate.expected : Object.keys(confirmUpdate.current).length }
                dropDownMax={5}
                onChangeDropDown={ newValue => this.updateConfirmUpdateExpected(newValue) }
            >
                {Object.keys(updated).map(key => {
                    const value = updated[key];
                    return (
                        <span key={key}>
                            <div className="d-flex align-items-start w-100-percent mt-3">
                                <div className="w-100-percent">
                                    <AddressWidget
                                        useName={true}
                                        value={value}
                                        loading={this.state.loading}
                                        disabled={confirmUpdate.outcome !== "UPDATED"}
                                        synchronizeValue={synchronizeValue || value.discarded}
                                        onFieldChange={(fieldName: string, newValue: string | number | null) => {
                                            this.onConfirmUpdateChangeOneToMany(key, fieldName, newValue);
                                        }}
                                        showPhysicalFields={memberType === "GROWER"}
                                    />
                                </div>
                                <div className="flex-shrink-0">
                                    {!value.discarded && (
                                        <button
                                            type="button"
                                            className={`btn btn-sm btn-danger px-2 ml-2 ${confirmUpdate.outcome !== "UPDATED" ? `disabled` : ``}`}
                                            disabled={confirmUpdate.outcome !== "UPDATED"}
                                            onClick={() => this.deleteOneToMany(key, "address")}
                                        >
                                            {parseInt(key, 10) > 0 ? (
                                                <>
                                                    <FontAwesomeIcon icon={faArchive} className="mr-2" /> Archive
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faTrash} className="mr-2" /> Delete
                                                </>
                                            )}
                                        </button>
                                    )}
                                    {value.discarded && (
                                        <button
                                            type="button"
                                            className={`btn btn-sm btn-secondary px-2 ml-2 ${confirmUpdate.outcome !== "UPDATED" ? `disabled` : ``}`}
                                            disabled={confirmUpdate.outcome !== "UPDATED"}
                                            onClick={() => this.undeleteOneToMany(key)}
                                        >
                                            <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
                                            Unarchive
                                        </button>
                                    )}
                                </div>
                            </div>
                            <hr />
                        </span>
                    );
                })}
            </OneToManyWrapper>
            {
                (
                <div className="alert alert-warning shadow-sm text-center" role="alert">
                    Please include <strong><u>all</u></strong> entity locations including all non-OGVG commodity
                    production. This data will be used to determine or identify potential biosecurity risks. This will
                    not affect any billings or future invoicing.
                </div>
            )
            }
            </>
        );
    }

    protected emptyUpdate(): AddressUpdate {
        return EmptyAddressUpdate();
    }

    protected copyCurrentToUpdate(current: AddressCurrent): AddressUpdate {
        return CopyAddressCurrentToUpdate(current);
    }
}
