import * as React from "react";
import { inject, observer } from "mobx-react";
import FormRadioField from "./FormRadioField";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import FormInputField from "./FormInputField";

export interface Fields {
    label: string;
    value: string;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    label: string;
    formType: string;
    radioName: string;
    optionsName: string;
    options: Array<Fields>;
    yesTitle: string;
    required?: boolean;
}

@inject("applicationStore")
@observer
export default class EnergyCurtainsWidget extends React.Component<Props, State> {
    render() {
        return (
            <div className="card shadow">
                <div className="card-header">
                    {this.props.label}
                    {this.props.required ? <span className="text-danger"> * </span> : ""}
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row col-12 col-lg-8 mx-auto">
                            <div className="col-2 mt-2">
                                <div className="form-check">
                                    <label>
                                        <FormRadioField fieldName={this.props.radioName} fieldValue="yes" formType={this.props.formType} />
                                        <div className="new-label">Yes</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-2 mt-2">
                                <div className="form-check">
                                    <label>
                                        <FormRadioField fieldName={this.props.radioName} fieldValue="no" formType={this.props.formType} />
                                        <div className="new-label">No</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.radioName) === "yes" && (
                            <>
                                <div className="form-row mt-4 ml-3">
                                    <div className="col-12 col-lg-8 mx-auto font-italic font-weight-bolder">{this.props.yesTitle}</div>
                                </div>
                                <div className="form-row mt-2">
                                    <div className="col-12 col-lg-8 mx-auto">
                                        {this.props.options.map(field => {
                                            return (
                                                <div key={field.value} className="form-row ml-3">
                                                    <div className="col-12">
                                                        <div className="form-check mb-2">
                                                            <label>
                                                                <FormRadioField
                                                                    fieldName={this.props.optionsName}
                                                                    fieldValue={field.value}
                                                                    formType={this.props.formType}
                                                                />
                                                                <div className="new-label">{field.label}</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="form-row mt-2 ml-3">
                                    <div className="col-12 col-lg-8 mx-auto">
                                        <label className="font-italic font-weight-bolder">Acreage Covered By Curtains:</label>
                                        <FormInputField
                                            type="number"
                                            formType={this.props.formType}
                                            fieldName="energyCurtains:acreageCovered:value"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}
