import { OneToManyConfirmUpdate, SingleConfirmUpdate } from "../ConfirmUpdate";
import { Submission, GrowerSubmission } from "../Submission";

export enum ConfirmUpdateValidity {
    OK,
    OUTCOME_BLANK,
    MISSING_ENTRIES,
    UNFILLED_FIELDS
}

export const CalculateSingleValueValidationState = (
    confirmUpdate: SingleConfirmUpdate<any, any>,
    optionalFields: Array<string> = []
): ConfirmUpdateValidity => {
    if (confirmUpdate.outcome === "BLANK") {
        return ConfirmUpdateValidity.OUTCOME_BLANK;
    } else if (
        confirmUpdate.outcome === "UPDATED" &&
        (!confirmUpdate.updated ||
            !confirmUpdate.updated.value ||
            Object.keys(confirmUpdate.updated.value || {})
                .filter(fieldName => !optionalFields.some(f => f === fieldName))
                .map(fieldName => confirmUpdate.updated!.value[fieldName])
                .some(field => field === undefined || field === null || field === ""))
    ) {
        return ConfirmUpdateValidity.UNFILLED_FIELDS;
    } else {
        return ConfirmUpdateValidity.OK;
    }
};

export const CalculateOneToManyValidationState = (
    confirmUpdate: OneToManyConfirmUpdate<any, any>,
    optionalFields: Array<string> = []
): ConfirmUpdateValidity => {
    if (confirmUpdate.outcome === "BLANK") {
        return ConfirmUpdateValidity.OUTCOME_BLANK;
    } else if (
        confirmUpdate.outcome === "UPDATED" &&
        (!confirmUpdate.updated || Object.keys(confirmUpdate.updated).filter(key => !confirmUpdate.updated![key].discarded).length === 0)
    ) {
        return ConfirmUpdateValidity.MISSING_ENTRIES;
    } else if (
        confirmUpdate.outcome === "UPDATED" &&
        // Extract all instances of the one-to-many
        Object.keys(confirmUpdate.updated || {})
            .filter(key => key !== "ROAD_SIDE" && !confirmUpdate.updated![key].discarded)
            .map(key => confirmUpdate.updated![key])
            .map(entry =>
                // For each field in the instance, filter to only required fields. If they're empty, then return 1, 0 otherwise
                Object.keys(entry)
                    .filter(fieldName => ![...optionalFields, "discarded"].some(f => f === fieldName))
                    .map(fieldName => entry[fieldName])
                    .some(field => field === undefined || field === null || field === "" || field === "NOT_SPECIFIED")
                    ? 1
                    : (0 as number)
            )
            // If the # of empty required fields is greater than 0, then return UNFILLED_FIELDS
            .reduce((a, b) => a + b, 0) > 0
    ) {
        return ConfirmUpdateValidity.UNFILLED_FIELDS;
    } else {
        return ConfirmUpdateValidity.OK;
    }
};

export interface ValidityCheck {
    isValid: (submission: Submission) => boolean;
    message: (submission: Submission) => string;
}

export interface GrowerValidation {
    form2: FormValidation;
    form2A: FormValidation;
    CropProduction: FormValidation;
}

export interface PackerValidation {
    form4: FormValidation;
}

export interface MarketerValidation {
    form5: FormValidation;
    form5A: FormValidation;
    form5B: FormValidation;
    attachments: FormValidation;
}

export type AnyValidation = GrowerValidation | PackerValidation | MarketerValidation;

export interface FormValidation {
    name: string;
    subsections: { [name: string]: SubsectionValidation };
}

export interface SubsectionValidation {
    name: string;
    checks: Array<ValidityCheck>;
}

export const BuildConfirmUpdateSingleValueValidityCheck = (fieldName: string, message: string,optionalFields: Array<string> = [] ): ValidityCheck => {
    return {
        isValid: (submission: Submission) => CalculateSingleValueValidationState(submission.confirmUpdates[fieldName], optionalFields) === ConfirmUpdateValidity.OK,
        message: (submission: Submission) =>
            CalculateSingleValueValidationState(submission.confirmUpdates[fieldName], optionalFields) !== ConfirmUpdateValidity.OK ? message : ""
    };
};

export const BuildConfirmUpdateOneToManyValidityCheck = (fieldName: string, message: string, optionalFields: Array<string> = []): ValidityCheck => {
    return {
        isValid: (submission: Submission) => CalculateOneToManyValidationState(submission.confirmUpdates[fieldName],optionalFields) === ConfirmUpdateValidity.OK,
        message: (submission: Submission) =>
            CalculateOneToManyValidationState(submission.confirmUpdates[fieldName], optionalFields) !== ConfirmUpdateValidity.OK ? message : ""
    };
};

export const BuildSimpleFieldValidityCheck = (fieldName: string, message: string): ValidityCheck => {
    return {
        isValid: (submission: Submission) => submission.simpleFields[fieldName] !== undefined,
        message: (submission: Submission) => (submission.simpleFields[fieldName] === undefined ? message : "")
    };
};

export const EvaluateAllRules = (container: AnyValidation, submission: Submission): boolean => {
    return (
        Object.values(container)
            .map((formValidation: FormValidation) => Object.values(formValidation.subsections).map(s => s.checks))
            .flat()
            .flat()
            .map(check => check.isValid(submission))
            .filter(result => !result).length > 0
    );
};

export const EvaluateAllRulesForForm = (form: FormValidation, submission: Submission): boolean => {
    return (
        Object.values(form.subsections)
            .map(s => s.checks)
            .flat()
            .flat()
            .map(check => check.isValid(submission))
            .filter(result => !result).length === 0
    );
};

export const EvaluateAllRulesForSubsection = (subsection: SubsectionValidation, submission: Submission): boolean => {
    return subsection.checks.map(check => check.isValid(submission)).filter(result => !result).length === 0;
};

export const EvaluateAllRulesToMessages = (container: AnyValidation, submission: Submission): Array<string> => {
    return Object.values(container)
        .map((formValidation: FormValidation) => Object.values(formValidation.subsections).map(s => s.checks))
        .flat()
        .flat()
        .map(check => {
            if (!check.isValid(submission)) {
                return check.message(submission);
            } else {
                return "";
            }
        })
        .filter(str => str !== "");
};

export const EvaluateAllFormRulesToMessages = (formValidation: FormValidation, submission: Submission): Array<string> => {
    return Object.values(formValidation.subsections)
        .map(s => s.checks)
        .flat()
        .flat()
        .map(check => {
            if (!check.isValid(submission)) {
                return check.message(submission);
            } else {
                return "";
            }
        })
        .filter(str => str !== "");
};
