import { inject, observer } from "mobx-react";
import * as React from "react";

import { PackerSubmission } from "../../../model/Submission";
import { PackerValidationRules } from "../../../model/validation/PackerValidation";
import { EvaluateAllRulesForSubsection } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import Accordion from "../../Common/Accordion";
import ContactsWidget from "../../Common/confirm-update/ContactsWidget";
import MailingAddressWidget from "../../Common/confirm-update/MailingAddressWidget";
import PhysicalAddressesWidget from "../../Common/confirm-update/PhysicalAddressesWidget";
import { SingleConfirmUpdateComponent } from "../../Common/confirm-update/SingleConfirmUpdateComponent";
import TraceabilityCodesPackerGrowerWidget from "../../Common/confirm-update/TraceabilityCodesPackerGrowerWidget";
import { Disclamer } from "../../Common/Disclamer";
import YesNoSimpleField from "../../Common/simple-fields/YesNoSimpleField";
import YesNoSimpleFieldWithInput from "../../Common/simple-fields/YesNoSimpleFieldWithInput";
import ContentHeader from "../../Template/ContentHeader";

const ONE_TO_MANY_NEW_ID = 0;

interface State {
    currentAccordion: string;
    newIdValue: number;
}

interface Props {
    applicationStore?: ApplicationStore;
}

@inject("applicationStore")
@observer
export default class Form4PackersInformation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentAccordion: "packerInformation",
            newIdValue: ONE_TO_MANY_NEW_ID
        };
    }

    setCurrentAccordion = (name: string) => {
        this.setState({ currentAccordion: name });
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission as PackerSubmission;
        const member = applicationStore.submission!.member;
        const completed = (applicationStore.submission as PackerSubmission).completed;
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Form 4 - Packer's Information" />
                <div className="row content">
                    <div className="alert alert-warning shadow-sm text-center" role="alert">
                        Please <strong>enter or confirm/update</strong> the following information. All fields are mandatory unless otherwise
                        specified.
                        <br />
                        <strong>Note:</strong> All the information on file from the previous year can only be archived and not deleted.
                        <br /> Any new information entered in this application can be deleted before its submission.
                    </div>

                    <Accordion
                        title="Packer's Information"
                        name="packerInformation"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion=""
                        nextAccordion="mailingAddress"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(PackerValidationRules.form4.subsections.packersInformation, submission)}
                    >
                        <div className="row py -3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <SingleConfirmUpdateComponent confirmUpdateName="legalMemberName" label="Legal Packer Name" />
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleFieldWithInput
                                    yesNoLabel="Do you own any other greenhouses?"
                                    yesNoFieldName="ownAnyOtherGreenhouses"
                                    yesNoRequired={true}
                                    onYesLabel="Please list all the other greenhouses you own:"
                                    onYesFieldName="ownAnyOtherGreenhousesNames"
                                    onYesRequired={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    required={true}
                                    label="Do you have biosecurity protocols in place?"
                                    fieldName="biosecurityInPlace"
                                />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Mailing Address"
                        name="mailingAddress"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="packerInformation"
                        nextAccordion="physicalAddresses"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(PackerValidationRules.form4.subsections.mailingAddress, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <MailingAddressWidget label="Mailing Address" confirmUpdateName="mailingAddress" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Physical Address(es)"
                        name="physicalAddresses"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="mailingAddress"
                        nextAccordion="contacts"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(PackerValidationRules.form4.subsections.physicalAddresses, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <PhysicalAddressesWidget label="Physical Address(es)" confirmUpdateName="physicalAddresses" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Contacts"
                        name="contacts"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="physicalAddresses"
                        nextAccordion="traceabilityCodes"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(PackerValidationRules.form4.subsections.contacts, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Registered Owner Information" confirmUpdateName="ownerContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Primary Contact Information" confirmUpdateName="primaryContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Food Safety Contact Information" confirmUpdateName="foodSafetyContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Biosecurity Contact Information" confirmUpdateName="biosecurityContact" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Traceability Codes"
                        name="traceabilityCodes"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="contacts"
                        nextPage="/packer/signature-and-submission"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(PackerValidationRules.form4.subsections.traceabilityCodes, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <div className="alert alert-warning">
                                    <div>
                                        <strong>Growers, their Traceability Codes, and Marketers</strong>
                                    </div>
                                    Please provide <em>the traceability code</em>
                                    for each grower along with their marketer. Also, if you grow/market your own produce, please fill out Form 2, 3
                                    and/or Form 5.
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <TraceabilityCodesPackerGrowerWidget label="Traceability Codes" confirmUpdateName="packerGrowerTraceability" />
                            </div>
                        </div>
                    </Accordion>
                    <div className="row pt-3">
                        <div className="col-12">
                            <Disclamer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
