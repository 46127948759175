import * as React from "react";
import { observer, inject } from "mobx-react";
import ContentHeader from "../../Template/ContentHeader";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { Disclamer } from "../../Common/Disclamer";
import { FormNavigation } from "../../Common/FormNavigation";
import ProprietaryCommoditiesWidget from "../../Common/ProprietaryCommoditiesWidget";
import CropProductionDetailsWidget from "../../Common/CropProductionDetailsWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faEdit, faTrash, faPlus, faCalendarPlus, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { GrowerSubmission } from "../../../model/Submission";
interface Props {
    applicationStore?: ApplicationStore;
}

interface State { }

@inject("applicationStore")
@observer
export default class CropProductionDetails extends React.Component<Props, State> {
    render() {
        const applicationStore = this.props.applicationStore!;
        const member = applicationStore.submission!.member;
        const currentYear = new Date(applicationStore.submission!.licenceYear.startDate).getFullYear();
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Crop Production Details" />
                <div className="row content">

                    {/*<div className="col-12 py-3">*/}
                    {/*    <h5>*/}
                    {/*        <strong>Step 1: </strong>Review the Proprietary Commodities details on file from previous years' submissions*/}
                    {/*    </h5>*/}
                    {/*</div>*/}
                    {/*<div className="col-12 mb-1">*/}
                    {/*    <div className="alert alert-success" role="alert">*/}
                    {/*        <FontAwesomeIcon icon={faInfo} className="mr-3" />*/}
                    {/*        <strong>*/}
                    {/*            You can skip this step if you do not have any proprietary commodities as defined in the general regulations.*/}
                    {/*        </strong>*/}
                    {/*        <br />*/}
                    {/*        <div className="ml-4 mt-2">*/}
                    {/*            The following are the Proprietary Commodities that we have on file from previous years' submissions. <br />*/}
                    {/*            You are able to add new items to this list, but only those added as part of this online submission can be edited or*/}
                    {/*            deleted.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}



                    {/*<div className="col-12 mt-3 mb-3">*/}
                    {/*    <div className="card text-left">*/}
                    {/*        <div className="card-header">Proprietary Commodities On File</div>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <ProprietaryCommoditiesWidget />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="col-12 pt-4 pb-1 mt-3">
                        <h5>
                            <strong>Step 1: </strong> Build Your Crop Production Profile
                        </h5>
                    </div>

                    <div className="col-12 mb-1">
                        <div className="alert alert-success">
                            <FontAwesomeIcon icon={faInfo} className="mr-3" />
                            This section combines the "Greenhouse Area per Commodity Including Walkways" of the printed Form 2 and Form 3.
                            <br />
                            <div className="ml-4">
                                <i>Add Range</i>&nbsp;
                                <FontAwesomeIcon icon={faCalendarPlus} className="mx-2" /> to build your Crop Production Profile. <br />
                                If you have multiple rotations of the same variety in the same range, click the <i>Add New</i>{" "}
                                <FontAwesomeIcon icon={faRedoAlt} className="mx-2" /> button to create a new rotation for the same range. <br />
                                You can edit and delete the range details entered at any time by clicking the <i>Edit</i>
                                <FontAwesomeIcon icon={faEdit} className="mx-2" /> or <i>Delete</i>{" "}
                                <FontAwesomeIcon icon={faTrash} className="mx-2" /> buttons. <br />
                                Ranges will be sorted by Planting and Pulling Week and grouped by commodity.
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card text-left">
                            <div className="card-header">
                                Crop Production Details: September {currentYear} - December {currentYear + 1}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <CropProductionDetailsWidget />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FormNavigation nextPath="/grower/signature-and-submission" backPath="/grower/form-2a-greenhouse-operation-information" />
                    <Disclamer />
                </div>
            </div>
        );
    }
}
