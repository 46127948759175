import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";

import { faEdit, faTrash, faRedoAlt, faExclamationTriangle, faSpa, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ApplicationStore } from "../../stores/ApplicationStore";

import { GrowerSubmission } from "../../model/Submission";

import {
    Crop,
    ExtractTypeFromCrop,
    Commodity,
    ExtractVarietyFromCrop,
    YearWeekToMoment,
    CropDuration,
    CropDurationFromStart
} from "../../model/CropProductionDetails";
import { UncontrolledTooltip } from "reactstrap";

import $ from "jquery";
// @ts-ignore
import handyScroll from "handy-scroll";
import * as handyScrollJS from './handyScroll.min.js';

// NOTE: This file has an analogue in the `ogvg-mrm-shell/mrm-second-gen`. Any fixes or updates may need to be applied in both.

const SplitTrack = (source: Array<Crop>): Array<Array<Crop>> => {
    const currentTrack = [];
    const nextTrack = [];

    if (source.length > 0) {
        currentTrack.push(source[0]);
    }
    for (let i0 = 0, i1 = 1; i1 < source.length; i1++) {
        const current = source[i0];
        const target = source[i1];
        if (YearWeekToMoment(target.plantYearWeek).isSameOrBefore(YearWeekToMoment(current.pullYearWeek))) {
            nextTrack.push(target);
        } else {
            currentTrack.push(target);
            i0 = i1;
        }
    }

    return [currentTrack, nextTrack];
};

const BreakCropIntoNonOverlappingTracks = (crops: Array<Crop>): Array<Array<Crop>> => {
    const tracks = [];
    const sorted = crops
        .slice()
        .sort(
            (a, b) =>
                ExtractVarietyFromCrop(a).localeCompare(ExtractVarietyFromCrop(b)) ||
                YearWeekToMoment(a.plantYearWeek).valueOf() - YearWeekToMoment(b.plantYearWeek).valueOf()

        );

    let currentTrack = sorted;
    do {
        const result = SplitTrack(currentTrack);
        tracks.push(result[0]);
        currentTrack = result[1];
    } while (currentTrack.length > 0);

    return tracks;
};

export interface WeekYearOption {
    year: number;
    week: number;
    startDay: string;
    endDay: string;
}

export interface FormState { }
export interface ComponentState { }

interface Props {
    applicationStore?: ApplicationStore;
    deleteCropProduction: (id: number) => void;
    editCropProduction: (crop: Crop) => void;
    createNewRotationWithSameRange: (crop: Crop) => void;
}
type State = ComponentState & FormState;

@inject("applicationStore")
@observer
export default class CropProductionDetailsTableWidget extends React.Component<Props, State> {
    private form: HTMLFormElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            ...this.defaultState()
        };
    }

    defaultState = () => {
        return {
            wasValidated: false,
            invalidDate: false,
            showForm: false
        };
    };

    generateWeekOptions = (): Array<WeekYearOption> => {
        const submission = this.props.applicationStore!.submission;
        if (!submission) {
            throw new Error("In handleOptionWeeks without a submission object");
        }

        const initialDate = moment()
            .year(moment(submission!.licenceYear.startDate).year())
            .month(8) // September
            .date(1);

        const lastDate = moment()
            .year(moment(submission!.licenceYear.endDate).year())
            .month(11) // December
            .endOf("month");

        if (
            (this.props.applicationStore!.submission as GrowerSubmission).cropProductionDetails.some(
                c =>
                    (c.harvestYearWeek && YearWeekToMoment(c.harvestYearWeek).isAfter(lastDate)) || YearWeekToMoment(c.pullYearWeek).isAfter(lastDate)
            )
        ) {
            lastDate.add(6, "months");
        }

        const weeks: Array<WeekYearOption> = [];

        for (const day = moment(initialDate); day.isBefore(lastDate); day.add(1, "week")) {
            const firstDay = moment(day);

            weeks.push({
                // year: firstDay.add(6, "days").isoWeekYear(),
                // week: firstDay.add(-5, "days").isoWeek(),
                year: firstDay.isoWeekYear(),
                week: firstDay.isoWeek(),
                startDay: firstDay.startOf("week").format("YYYY-MM-DD"),
                endDay: firstDay.endOf("week").format("YYYY-MM-DD")
                // startDay: firstDay.add(-1, "days").format("YYYY-MM-DD"),
                // endDay: firstDay.add(6, "days").format("YYYY-MM-DD")
            });
        }

        return weeks;
    };

    generateListOfColumns = (): Array<{ type: Commodity; crops: Array<Crop> }> => {
        const submission = this.props.applicationStore!.submission;
        if (!submission) {
            throw new Error("In Submission");
        }
        const cropProductionDetails = (submission as GrowerSubmission).cropProductionDetails;
        const grouped = { CUCUMBER: [], PEPPER: [], TOMATO: [] };
        cropProductionDetails.forEach(crop => grouped[ExtractTypeFromCrop(crop)].push(crop));

        return [
            ...(grouped.CUCUMBER.length > 0
                ? BreakCropIntoNonOverlappingTracks(grouped.CUCUMBER).map(track => {
                    return { type: Commodity.CUCUMBER, crops: track };
                })
                : []),
            ...(grouped.PEPPER.length > 0
                ? BreakCropIntoNonOverlappingTracks(grouped.PEPPER).map(track => {
                    return { type: Commodity.PEPPER, crops: track };
                })
                : []),
            ...(grouped.TOMATO.length > 0
                ? BreakCropIntoNonOverlappingTracks(grouped.TOMATO).map(track => {
                    return { type: Commodity.TOMATO, crops: track };
                })
                : [])
        ];
    };

    isWeekPlant = (year: number, week: number, crops: Array<Crop>): Crop | undefined => {
        return crops.find(crop => crop.plantYearWeek.week === week && crop.plantYearWeek.year === year);
    };

    isWeekHarvest = (year: number, week: number, crops: Array<Crop>): Crop | undefined => {
        return crops.find(crop => crop.harvestYearWeek && crop.harvestYearWeek.week === week && crop.harvestYearWeek.year === year);
    };

    isWeekInTheGround = (year: number, week: number, crops: Array<Crop>): Crop | undefined => {
        const currentWeekYear = moment()
            .isoWeekYear(year)
            .isoWeek(week)
            .startOf("week");
        return crops.find(crop => {
            const plantWeekYear = YearWeekToMoment(crop.plantYearWeek);
            const pullWeekYear = YearWeekToMoment(crop.pullYearWeek);
            return currentWeekYear.isSameOrAfter(plantWeekYear) && currentWeekYear.isSameOrBefore(pullWeekYear);
        });
    };

    isHarvestWeekSameAsPull = (crop: Crop) => {
        const { year: pullYear, week: pullWeek } = crop.pullYearWeek;
        const { year: harvestYear, week: harvestWeek } = crop.harvestYearWeek || { year: 0, week: 0 };
        const value = moment()
            .year(pullYear)
            .week(pullWeek)
            .startOf("week")
            .isSame(
                moment()
                    .year(harvestYear)
                    .week(harvestWeek)
                    .startOf("week")
            );

        return value;
    };

    determineWidgetCSS = (type: Commodity) => {
        switch (type) {
            case Commodity.CUCUMBER:
                return "bg-cucumber";
            case Commodity.PEPPER:
                return "bg-pepper";
            case Commodity.TOMATO:
                return "bg-tomato";
            default:
                return "";
        }
    };


    render() {
        const configuration = this.props.applicationStore!.configuration;
        if (!configuration) {
            return <div />;
        }

        const submission = this.props.applicationStore!.submission;
        if (!submission) {
            return <div />;
        }

        $(() => {
            handyScroll.mount(document.getElementsByClassName("table-responsive-lg"));
            //scrolling commoditiy options
            $(window).scroll(function () {
                var windowPositionRelative = $(document).scrollTop();
                $(".plant-commodity-widget").each(function (index, pcw) {
                    var colorBackground = pcw;
                    //@ts-ignore
                    var colorBackgroundTop = $(pcw).offset().top;
                    var colorBackgroundHeight = $(pcw).parent().outerHeight();
                    var colorBackgroundWidth = $(pcw).parent().outerWidth();
                    //@ts-ignore
                    var colorBackgroundBottom = colorBackgroundTop + colorBackgroundHeight;
                    var textContainer = $(pcw).find(".top-row");
                    var textContainerHeight = $(textContainer).outerHeight();

                    //get top of the table and add to the sum
                    //@ts-ignore
                    var tableTop = $(pcw).parent().parent().parent().offset().top;
                    //@ts-ignore
                    var textBoxPosition = windowPositionRelative - tableTop;
                    //@ts-ignore
                    var bottomBlocker = colorBackgroundBottom - (textContainerHeight * 2);
                    var headerOffset = 125;

                    //@ts-ignore
                    if (windowPositionRelative > colorBackgroundTop) {
                        //@ts-ignore
                        $(textContainer).width(colorBackgroundWidth - 60);

                        //@ts-ignore
                        if (bottomBlocker > windowPositionRelative) {
                            //@ts-ignore
                            $(textContainer).css({ "position": "absolute", "top": "" + (textBoxPosition + headerOffset) + "px" });
                        }

                        //@ts-ignore
                        $(colorBackground).parent().width(colorBackgroundWidth);
                    }
                    else {
                        $(textContainer).css("width", "");
                        $(textContainer).css({ "position": "", "top": "" });
                        $(colorBackground).parent().css("width", "");
                    }
                });
            });
        });

        const tableData = this.generateListOfColumns();
        return (
            //here
            <div className="col-12 px-0 table-responsive-lg" style={{ overflowX: "scroll" }}>
                <table className="crop-table" cellSpacing="0" cellPadding="0" style={{ overflowX: "auto", overflowY: "auto", borderCollapse: "unset" }}>
                    <thead>
                        <tr>
                            <th style={{ width: "90px" /*, position: "fixed", left: 0 */ }} className="text-center">
                                Week #
                            </th>
                            <th style={{ width: "120px" /*, position: "fixed", left: "90px"*/ }} className="text-center">
                                From/To
                            </th>
                            {Object.keys(tableData).length > 0 ? (
                                Object.keys(tableData).map(key => {
                                return (
                                    <th key={key} style={{ maxWidth: "335px" }}>
                                        &nbsp;
                                    </th>
                                );
                            })
                            ) : (
                                <th>&nbsp;</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.generateWeekOptions().map((w: WeekYearOption, index: number) => {
                            return (
                                <tr key={w.week + "_" + w.year}>
                                    <th className="text-center sticky-col first-col">{w.week}</th>
                                    <td className="text-center sticky-col second-col">
                                        {moment(w.startDay).format("MMM DD")} - {moment(w.endDay).format("DD")}
                                    </td>
                                    {Object.keys(tableData).length > 0 ? (
                                        Object.keys(tableData).map(key => {
                                            const c = tableData[key];
                                            const crops: Array<Crop> = c.crops;
                                            const uiKey = w.week + "_" + w.year + "_" + key;

                                            const plantingCrop = this.isWeekPlant(w.year, w.week, crops);
                                            const inTheGroundCrop = this.isWeekInTheGround(w.year, w.week, crops);
                                            const carryOverCrop = index === 0 && !plantingCrop ? inTheGroundCrop : undefined;
                                            const headerCrop = plantingCrop || carryOverCrop;

                                            if (headerCrop !== undefined) {
                                                return (
                                                    <td
                                                        key={uiKey}
                                                        className={`with-crop ${this.determineWidgetCSS(ExtractTypeFromCrop(headerCrop))}`}
                                                        // rowSpan={carryOverCrop ? CropDurationFromStart(w, headerCrop) : CropDuration(headerCrop)}
                                                        rowSpan={CropDurationFromStart(w, headerCrop)}
                                                        style={{ position: "static" }}
                                                    >
                                                        <div
                                                            className={`plant-commodity-widget mb-auto
                                                                ${this.determineWidgetCSS(ExtractTypeFromCrop(headerCrop))}`}
                                                        >
                                                            <div className="top-row">
                                                                <div className="title">
                                                                    {carryOverCrop && (
                                                                        <div className="crop-name">
                                                                            <FontAwesomeIcon icon={faExclamationTriangle} /> Carried over from{" "}
                                                                            {carryOverCrop.plantYearWeek.year} / {carryOverCrop.plantYearWeek.week}{" "}
                                                                            (not editable)
                                                                            {headerCrop.organic && (
                                                                                <span>
                                                                                    {" - "}
                                                                                    <FontAwesomeIcon icon={faSpa} title="organic" />
                                                                                </span>
                                                                            )}
                                                                            {headerCrop.proprietary && (
                                                                                <span>
                                                                                    {" - "}
                                                                                    <FontAwesomeIcon icon={faTag} title="proprietary" />
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    <div className="crop-name">{ExtractVarietyFromCrop(headerCrop)}
                                                                        {headerCrop.organic && (
                                                                            <span>
                                                                                {" - "}
                                                                                <FontAwesomeIcon icon={faSpa} title="organic" />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="size">
                                                                        {headerCrop.enteredArea.toLocaleString()}&nbsp;
                                                                        {headerCrop.preferredUnit.friendlyName}
                                                                    </div>
                                                                    <div className="size">
                                                                        {headerCrop.numberOfPlants} Plants, {headerCrop.enteredExpectedYield}x{" "}
                                                                        {headerCrop.enteredExpectedYieldUnit.friendlyName}
                                                                    </div>
                                                                </div>
                                                                <div className="btn-group btn-group-toggle btns" data-toggle="buttons">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-light btn-sm"
                                                                        id={`editWithoutRange_${uiKey}`}
                                                                        onClick={() => this.props.createNewRotationWithSameRange(headerCrop)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faRedoAlt} />
                                                                    </button>
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        target={`editWithoutRange_${uiKey}`}
                                                                        delay={0}
                                                                        className="tooltipstrap"
                                                                    >
                                                                        Create a New Rotation for the Same Range
                                                                    </UncontrolledTooltip>
                                                                    {headerCrop.editable && !carryOverCrop && (
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                id={`editRange_${uiKey}`}
                                                                                className="btn btn-outline-light btn-sm"
                                                                                onClick={() => this.props.editCropProduction(headerCrop)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                            </button>
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                target={`editRange_${uiKey}`}
                                                                                delay={0}
                                                                                className="tooltipstrap"
                                                                            >
                                                                                Edit Range
                                                                            </UncontrolledTooltip>
                                                                            <button
                                                                                type="button"
                                                                                id={`deleteRange_${uiKey}`}
                                                                                className="btn btn-outline-light btn-sm"
                                                                                onClick={() => this.props.deleteCropProduction(headerCrop.id)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </button>
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                target={`deleteRange_${uiKey}`}
                                                                                delay={0}
                                                                                className="tooltipstrap"
                                                                            >
                                                                                Delete Range
                                                                            </UncontrolledTooltip>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {this.isHarvestWeekSameAsPull(headerCrop) ? (
                                                                // <div className="crop-footer mt-auto text-center">HARVEST & PULLING</div>
                                                                <div className="crop-footer mt-auto text-center">PULLING</div>
                                                            ) : (
                                                                <>
                                                                    {/* <div
                                                                        className="crop-harvest text-center mb-auto"
                                                                        style={{
                                                                            height: CropPlatToHarvestDuration(headerCrop) * 30 + "px"
                                                                        }}
                                                                    >
                                                                        HARVEST
                                                                    </div> */}
                                                                    <div className="crop-footer mt-auto text-center">PULLING</div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                                // } else if (harvestingCrop !== undefined) {
                                                //     return <span> Harvest </span>;
                                            } else if (inTheGroundCrop !== undefined) {
                                                // Skip this column if we're in the ground (already covered by the row span)
                                                return null;
                                            } else {
                                                return (
                                                    <td key={uiKey}>
                                                        <div className="empty">&nbsp;</div>
                                                    </td>
                                                );
                                            }
                                        })
                                    ) : (
                                        <td>
                                            <p className="empty text-info text-center pt-1">
                                                {index === 0 ? "There are currently no crops registered for this application." : ""}
                                            </p>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <script src={'./handyScroll.min.js'}></script>
            </div>
        );
    }
}
