import * as React from "react";
import { ToastContainer } from "react-toastify";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../stores/ApplicationStore";

import { Header } from "./Template/Header";
import { Sidebar } from "./Template/SideBar";

import "react-toastify/dist/ReactToastify.css";
import InformationSection from "./Template/InformationSection";
import { GrowerSubmission } from "../model/Submission";
import WelcomeBack from "./Template/WelcomeBack";
interface Props {
    applicationStore?: ApplicationStore;
}

interface State {
    showInformation: boolean;
    welcomeBack: boolean;
}
@inject("applicationStore")
@observer
export class ApplicationTemplate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showInformation: false,
            welcomeBack: true
        };
    }

    componentDidMount() {
        const applicationStore = this.props.applicationStore!;
        const value = applicationStore.findSimpleFieldValue("dontShowInfoAgain");
        if (!value) {
            this.setState({ showInformation: true });
        }
        if (!this.props.applicationStore!.completed) {
            // To prevent the dialog from showing on submission
            this.setState({ welcomeBack: false });
        }
    }

    render() {
        const applicationStore = this.props.applicationStore!;
        const completed = applicationStore.completed;
        return (
            <>
                <Header />
                <Sidebar />
                {this.state.showInformation && completed === false ? (
                    <InformationSection onClick={() => this.setState({ showInformation: false })} />
                ) : completed && this.state.welcomeBack ? (
                    <WelcomeBack onClick={() => this.setState({ welcomeBack: false })} />
                ) : (
                    this.props.children
                )}
                <ToastContainer
                    position="top-center"
                    autoClose={3500}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick={true}
                    rtl={false}
                    draggable={true}
                    pauseOnHover={true}
                />
            </>
        );
    }
}
