import * as React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { errorMessage } from "../Toast";
import YesNoQuestion from "../form-elements/YesNoQuestion";

interface State {
    loading: boolean;
}

interface PropsComponent {
    label: React.ReactNode;
    fieldName: string;
    required?: boolean;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class YesNoSimpleField extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    onChangeSimpleField = async (updatedValue: string) => {
        try {
            // Skip on no change
            if (this.props.applicationStore!.findSimpleFieldValue(this.props.fieldName) === updatedValue) {
                return;
            }

            this.setState({ loading: true });
            await this.props.applicationStore!.updateSimpleFields({
                [this.props.fieldName]: updatedValue
            });
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const simpleFieldValue = this.props.applicationStore!.findSimpleFieldValue(this.props.fieldName) || "";
        return (
            <YesNoQuestion
                label={this.props.label}
                loading={this.state.loading}
                value={simpleFieldValue}
                onClick={newValue => this.onChangeSimpleField(newValue)}
                required={this.props.required}
            />
        );
    }
}
