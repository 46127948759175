import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const FormNavigation = ({ backPath, nextPath }: { backPath?: string; nextPath?: string }) => {
    return (
        <div className="col-12 py-4  text-right">
            <div className="row">
                <div className="col-12">
                    {backPath && (
                        <Link to={backPath} className="btn btn-sm btn-secondary px-3">
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                        </Link>
                    )}
                    {nextPath && (
                        <Link to={nextPath} className="btn btn-sm btn-primary ml-2 px-3">
                            Next <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};
