import {inject, observer} from "mobx-react";
import moment from "moment";
import * as React from "react";
import {UncontrolledTooltip} from "reactstrap";

import {faEdit, faInfo, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {FutureExpansion} from "../../model/FutureExpansions";
import {FutureExpansionRequest} from "../../requests/FutureExpansionRequest";
import {ApplicationStore} from "../../stores/ApplicationStore";
import LoadingWidget from "./Loading";
import {SimpleConfirmation} from "./SimpleConfirmAlert";
import {DateGroup, InputGroup, SelectGroup} from "./SimpleFormComponents";
import {errorMessage, successMessage} from "./Toast";
import {Commodity} from "../../model/ProprietaryCommodity";
import {CommoditySquareFootCommodity} from "../../model/CropProductionDetails";

export interface FormState {
    id: number | null;
    expansionDate: moment.Moment | null;
    cucumberSquareFeet: string;
    pepperSquareFeet: string;
    tomatoSquareFeet: string;
    location: string;
    countryId: number | null;
    cucumberVarietyId: number |  null;
    pepperVarietyId: number |  null;
    tomatoVarietyId: number |  null;
}
export interface ComponentState {
    wasValidated: boolean;
    invalidDate: boolean;
    missingSquareFeet: boolean;
    showForm: boolean;
    editableApplicationId: number | null;
}

interface Props {
    applicationStore?: ApplicationStore;
    futureExpansions: Array<FutureExpansion>;
}
type State = ComponentState & FormState;

@inject("applicationStore")
@observer
export default class FutureExpansionWidget extends React.Component<Props, State> {
    private form: HTMLFormElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            ...this.defaultState()
        };
    }

    componentDidMount() {
        if (this.props.futureExpansions.length > 0) {
            this.setState({ showForm: true });
        }
    }

    getValidDate = (date: any) => {
        const licenceYear = this.props.applicationStore!.submission!.licenceYear;
        const initialDate = moment()
            .year(moment(licenceYear.startDate).year())
            .month(7)
            .date(31);
        const lastDate = moment()
            .year(moment(licenceYear.endDate).year())
            .month(7)
            .date(31);
        return date.isBetween(initialDate, lastDate);
    };

    defaultState = () => {
        return {
            ...this.empty(),
            wasValidated: false,
            invalidDate: false,
            missingSquareFeet: false,
            showForm: false,
            editableApplicationId: null
        };
    };

    empty = () => {
        return {
            id: null,
            expansionDate: null,
            cucumberSquareFeet: "",
            pepperSquareFeet: "",
            tomatoSquareFeet: "",
            location: "",
            countryId: null,
            cucumberVarietyId: null,
            pepperVarietyId: null,
            tomatoVarietyId: null
        };
    };

    editFutureExpandionForm = (m: FutureExpansion) => {
        const newState = {
            id: m.id,
            expansionDate: m.expansionDate ? moment(m.expansionDate) : null,
            cucumberSquareFeet: m.cucumberSquareFeet.toFixed(2),
            pepperSquareFeet: m.pepperSquareFeet.toFixed(2),
            tomatoSquareFeet: m.tomatoSquareFeet.toFixed(2),
            location: m.location,
            countryId: m.country.id,
            cucumberVarietyId: m.cucumberVariety ? m.cucumberVariety.id : null,
            pepperVarietyId: m.pepperVariety ? m.pepperVariety.id : null,
            tomatoVarietyId: m.tomatoVariety ? m.tomatoVariety.id : null
        };

        this.setState(newState);
    };

    generateRequest = (): FutureExpansionRequest => {
        if (
            this.state.expansionDate === null &&
            this.state.countryId === null &&
            this.state.cucumberSquareFeet === null &&
            this.state.pepperSquareFeet === null &&
            this.state.tomatoSquareFeet === null
        ) {
            throw new Error("In ToRequest Future Expansion with an unvalidated object");
        }
        const request = {
            expansionDate: this.state.expansionDate ? this.state.expansionDate.valueOf() : 0,
            cucumberSquareFeet: this.state.cucumberSquareFeet ? parseFloat(this.state.cucumberSquareFeet.toString().replace(/,/g, "")) : 0,

            pepperSquareFeet: this.state.pepperSquareFeet ? parseFloat(this.state.pepperSquareFeet.toString().replace(/,/g, "")) : 0,
            tomatoSquareFeet: this.state.tomatoSquareFeet ? parseFloat(this.state.tomatoSquareFeet.toString().replace(/,/g, "")) : 0,
            location: this.state.location,
            countryId: this.state.countryId!, // We checked for null above, so not sure why we have to add the bang to this?
            cucumberVarietyId: this.state.cucumberVarietyId!,
            pepperVarietyId: this.state.pepperVarietyId!,
            tomatoVarietyId: this.state.tomatoVarietyId!

        };
        return request;
    };

    createFutureExpansion = async () => {
        try {
            const request = this.generateRequest();

            await this.props.applicationStore!.createFutureExpansion(request);
            this.clearForm();
            successMessage(`A new future expansion has been created.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    editFutureExpansion = async () => {
        try {
            const request = this.generateRequest();
            if (this.state.id === null) {
                throw new Error("In editCreditApplication with a null ID?");
            }
            await this.props.applicationStore!.editFutureExpansion(this.state.id.toString(), request);
            this.clearForm();
            successMessage(`Future Expansion successfully updated.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    deleteFutureExpansion = async (futureExpationId: number) => {
        try {
            const goAhead = await SimpleConfirmation(<div className="my-2 mx-2"> Are you sure you'd like to delete this Future Expansion? </div>);
            if (!goAhead) {
                return;
            }

            await this.props.applicationStore!.deleteFutureExpansion(futureExpationId.toString());
            this.clearForm();
            successMessage(`Future Expansion successfully deleted.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    submit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();
        const missingSquareFeet =
            (this.state.cucumberSquareFeet === "" || parseInt(this.state.cucumberSquareFeet, 10) === 0) &&
            (this.state.pepperSquareFeet === "" || parseInt(this.state.pepperSquareFeet, 10) === 0) &&
            (this.state.tomatoSquareFeet === "" || parseInt(this.state.tomatoSquareFeet, 10) === 0);
        if (this.form && this.form.checkValidity() && !this.state.invalidDate && !missingSquareFeet) {
            this.setState({ wasValidated: false });
            this.state.id === null ? this.createFutureExpansion() : this.editFutureExpansion();
        } else {
            this.setState({ wasValidated: true, missingSquareFeet });
        }
    };

    clearForm = () => {
        this.setState({ ...this.defaultState() });
    };

    render() {
        const configuration = this.props.applicationStore!.configuration;

        if (!configuration) {
            return <div />;
        }

        return (
            <div className="row mb-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="alert-success px-4 py-4 my-3">
                                        <FontAwesomeIcon icon={faInfo} className="mr-3" />
                                        Please enter the details of each expansion and press <strong>Add Future Expansion</strong> when done. Each
                                        registered future expansion will show in the table below.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 ">
                                <div className="col-12 ">
                                    <form
                                        id="future-expansions-form"
                                        ref={form => (this.form = form)}
                                        className={"needs-validation mt-3 bg-one-to-many" + (this.state.wasValidated ? " was-validated" : "")}
                                        onSubmit={e => this.submit(e)}
                                        noValidate={true}
                                    >
                                        <div className="form-row">
                                            <DateGroup
                                                label="Expansion Date:"
                                                invalidFeedBack="Please enter a date."
                                                name="expansionDate"
                                                className="col-sm-12 col-md-4"
                                                //value={this.state.expansionDate ? moment(this.state.expansionDate).format("YYYY-MM-DD") : ""}
                                                forceInvalid={this.state.invalidDate}
                                                value={this.state.expansionDate || ""}
                                                dateFormat="YYYY-MM-DD"
                                                disabled={false}
                                                onChange={(expansionDate: any) => {
                                                    const invalidDate = typeof expansionDate === "string";
                                                    this.setState({ expansionDate, invalidDate });
                                                }}
                                                isRequired={true}
                                                icon={true}
                                                isValidDate={this.getValidDate}
                                            />
                                            <InputGroup
                                                label="Location:"
                                                className="col-md-12 col-lg-4"
                                                type="string"
                                                name="location"
                                                isRequired={true}
                                                disabled={false}
                                                value={this.state.location || ""}
                                                onChange={e => {
                                                    this.setState({ location: e.currentTarget.value });
                                                }}
                                            />
                                            <SelectGroup
                                                label="Country:"
                                                name="country"
                                                isRequired={true}
                                                isInvalid={this.state.wasValidated && this.state.countryId === null}
                                                className="col-md-12 col-lg-4"
                                                disabled={false}
                                                value={this.state.countryId || ""}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    const countryId = e.target.value === "" ? null : parseInt(e.target.value, 10);
                                                    this.setState({ countryId });
                                                }}
                                            >
                                                {this.state.countryId === null && <option value="">Select a country...</option>}
                                                {configuration!.countries.map(c => (
                                                    <option key={c.id} value={c.id}>
                                                        {c.friendlyName}
                                                    </option>
                                                ))}
                                            </SelectGroup>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert-dark text-dark text-center px-1 py-2 mt-4 mb-2">
                                                    Please enter the square footage of the expansion for at least one of the commodities below:
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <InputGroup
                                                label="Cucumber Square Feet:"
                                                className={`col-md-12 col-lg-4`}
                                                inputClassName={`${this.state.missingSquareFeet ? "is-invalid" : ""}`}
                                                type="number"
                                                name="cucumberSquareFeet"
                                                isRequired={false}
                                                disabled={false}
                                                value={this.state.cucumberSquareFeet || ""}
                                                onChange={e => {
                                                    this.setState({ cucumberSquareFeet: e.currentTarget.value });
                                                }}
                                            />
                                            <InputGroup
                                                label="Pepper Square Feet:"
                                                className={`col-md-12 col-lg-4`}
                                                inputClassName={`${this.state.missingSquareFeet ? "is-invalid" : ""}`}
                                                type="number"
                                                name="pepperSquareFeet"
                                                isRequired={false}
                                                disabled={false}
                                                value={this.state.pepperSquareFeet || ""}
                                                onChange={e => {
                                                    this.setState({ pepperSquareFeet: e.currentTarget.value });
                                                }}
                                            />
                                            <InputGroup
                                                label="Tomato Square Feet:"
                                                className={`col-md-12 col-lg-4`}
                                                inputClassName={`${this.state.missingSquareFeet ? "is-invalid" : ""}`}
                                                type="number"
                                                name="tomatoSquareFeet"
                                                isRequired={false}
                                                disabled={false}
                                                value={this.state.tomatoSquareFeet || ""}
                                                onChange={e => this.setState({ tomatoSquareFeet: e.currentTarget.value })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <SelectGroup
                                                label="Cucumber Variety:"
                                                name="cucumberVariety"
                                                className={`col-md-12 col-lg-4`}
                                                inputClassName={`${this.state.missingSquareFeet ? "is-invalid" : ""}`}
                                                isRequired={false}
                                                disabled={false}
                                                value={this.state.cucumberVarietyId || ""}
                                                onChange={e => {
                                                    this.setState({ cucumberVarietyId: e.currentTarget.value });
                                                }}
                                            >
                                                {this.state.cucumberVarietyId === null &&
                                                    <option value="">Select a Commodity Type...</option>}

                                                {configuration.commodities.filter(c => c.type as Commodity === Commodity.CUCUMBER).map((c: CommoditySquareFootCommodity) => (
                                                    <option key={c.id} value={c.id}>
                                                        {c.friendlyName}
                                                    </option>
                                                ))}
                                            </SelectGroup>
                                            <SelectGroup
                                                label="Pepper Variety:"
                                                name="pepperVariety"
                                                className={`col-md-12 col-lg-4`}
                                                inputClassName={`${this.state.missingSquareFeet ? "is-invalid" : ""}`}
                                                isRequired={false}
                                                disabled={false}
                                                value={this.state.pepperVarietyId || ""}
                                                onChange={e => {
                                                    this.setState({ pepperVarietyId: e.currentTarget.value });
                                                }}
                                            >
                                                {this.state.pepperVarietyId === null &&
                                                    <option value="">Select a Commodity Type...</option>}

                                                {configuration.commodities.filter(c => c.type as Commodity === Commodity.PEPPER).map((c: CommoditySquareFootCommodity) => (
                                                    <option key={c.id} value={c.id}>
                                                        {c.friendlyName}
                                                    </option>
                                                ))}
                                            </SelectGroup>
                                            <SelectGroup
                                                label="Tomato Variety:"
                                                name="tomatoVariety"
                                                className={`col-md-12 col-lg-4`}
                                                inputClassName={`${this.state.missingSquareFeet ? "is-invalid" : ""}`}
                                                isRequired={false}
                                                disabled={false}
                                                value={this.state.tomatoVarietyId || ""}
                                                onChange={e => {
                                                    this.setState({ tomatoVarietyId: e.currentTarget.value });
                                                }}
                                            >
                                                {this.state.tomatoVarietyId === null &&
                                                    <option value="">Select a Commodity Type...</option>}

                                                {configuration.commodities.filter(c => c.type as Commodity === Commodity.TOMATO).map((c: CommoditySquareFootCommodity) => (
                                                    <option key={c.id} value={c.id}>
                                                        {c.friendlyName}
                                                    </option>
                                                ))}
                                            </SelectGroup>
                                        </div>
                                        <div className="for-row text-right">
                                            <div className="col-12 pr-0">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        this.clearForm();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button type="submit" className="btn btn-primary btn-sm mr-0 ml-3">
                                                    {this.state.id ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faPlus} /> Add
                                                        </>
                                                    )}{" "}
                                                    Future Expansion
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Expansion Date</th>
                                                <th>Cucumber Square Feet</th>
                                                <th>Pepper Square Feet</th>
                                                <th>Tomato Square Feet</th>
                                                <th>Commodities</th>
                                                <th>Location</th>
                                                <th>Country</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.futureExpansions.length === 0 ? (
                                                <tr>
                                                    <td colSpan={8} align="center">
                                                        {this.props.applicationStore!.loading ? (
                                                            <LoadingWidget />
                                                        ) : (
                                                            <span>No expansions registered.</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ) : (
                                                this.props.futureExpansions
                                                    .slice()
                                                    .sort((a, b) => a.expansionDate - b.expansionDate)
                                                    .map((fs: FutureExpansion) => {
                                                        let commodityArray = new Array();
                                                        if(fs.cucumberVariety !== null)
                                                            commodityArray.push("Cucumber - " + fs.cucumberVariety.friendlyName);
                                                        if(fs.pepperVariety !== null)
                                                            commodityArray.push("Pepper - " + fs.pepperVariety.friendlyName);
                                                        if(fs.tomatoVariety !== null){
                                                            commodityArray.push("Tomato - " + fs.tomatoVariety.friendlyName)
                                                        }
                                                        return (
                                                            <tr key={fs.id}>
                                                                <td>{moment(fs.expansionDate).format("YYYY/MM/DD")}</td>
                                                                <td className="bgCucumberSqft">{fs.cucumberSquareFeet}</td>
                                                                <td className="bgPepperSqft">{fs.pepperSquareFeet}</td>
                                                                <td className="bgTomatoSqft">{fs.tomatoSquareFeet}</td>
                                                                <td>{commodityArray.join(', ')}</td>
                                                                <td>{fs.location}</td>
                                                                <td>{fs.country.friendlyName}</td>
                                                                <td>
                                                                    <button
                                                                        id={`editFutureExpansions_${fs.id}`}
                                                                        className="btn btn-primary btn-sm mr-2 mb-1"
                                                                        onClick={() => this.editFutureExpandionForm(fs)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        target={`editFutureExpansions_${fs.id}`}
                                                                        delay={0}
                                                                        className="tooltipstrap"
                                                                    >
                                                                        Edit Future Expansion
                                                                    </UncontrolledTooltip>
                                                                    <button
                                                                        id={`deleteFutureExpansions_${fs.id}`}
                                                                        className="btn btn-secondary btn-sm mb-1"
                                                                        onClick={() => this.deleteFutureExpansion(fs.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </button>
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        target={`deleteFutureExpansions_${fs.id}`}
                                                                        delay={0}
                                                                        className="tooltipstrap"
                                                                    >
                                                                        Delete Future Expansion
                                                                    </UncontrolledTooltip>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
