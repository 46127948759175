import * as React from "react";
import { observer, inject } from "mobx-react";
import ContentHeader from "../../Template/ContentHeader";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { FormNavigation } from "../../Common/FormNavigation";
import { Disclamer } from "../../Common/Disclamer";
import { MarketerSubmission } from "../../../model/Submission";
import FormInputField from "../../Common/general-forms/FormInputField";
import FormRadioField from "../../Common/general-forms/FormRadioField";

interface Props {
    applicationStore?: ApplicationStore;
}

interface State {}

@inject("applicationStore")
@observer
export default class Form5BBusinessOverview extends React.Component<Props, State> {
    handleTotal = (fieldName: string) => {
        const applicationStore = this.props.applicationStore!;
        const businessOverview = (applicationStore.submission! as MarketerSubmission).generalForms["business-overview"];
        return businessOverview
            ? Math.floor(
                  [parseFloat(businessOverview[fieldName + ":domestic:value"]), parseFloat(businessOverview[fieldName + ":export:value"])]
                      .filter(v => !isNaN(v))
                      .reduce((a, b) => {
                          return a + b;
                      },      0) * 1000
              ) / 1000
            : 0;
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const member = applicationStore.submission!.member;
        const completed = (this.props.applicationStore!.submission! as MarketerSubmission).completed;
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Form 5B - Business Overview" />
                <div className="row content">
                    <div className="col-12 py-3">
                        <h5 className="text-center">Bussiness Overview</h5>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="alert alert-warning">
                            <div>Please provide as much detail below as possible.</div>
                            <div>Please specify if you are reporting in Kilos or Cases.</div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">Cucumbers</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-1">Reported in:</div>
                                    <div className="col-3 col-md-1">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="cucumbers:unit:value" fieldValue="kg" formType="business-overview" />
                                                <div className="new-label">Kilos</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-1">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="cucumbers:unit:value" fieldValue="cases" formType="business-overview" />
                                                <div className="new-label">Cases</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-cucumbers">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Category</th>
                                                    <th className="text-center">Name</th>
                                                    <th className="text-center">Domestic (Canada)</th>
                                                    <th className="text-center">Export (U.S. & Overseas)</th>
                                                    <th className="text-center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="line-subtitle-overview">Retail</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:retail:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:retail:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("cucumbers:volume:retail")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Food Service</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:foodService:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:foodService:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("cucumbers:volume:foodService")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Terminal/Wholesale</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:terminalWholesale:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:terminalWholesale:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("cucumbers:volume:terminalWholesale")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">OGVG Marketers / Resellers</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:marketersResellers:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:marketersResellers:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("cucumbers:volume:marketersResellers")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Other</td>
                                                    <td>
                                                        <FormInputField formType="business-overview" fieldName="cucumbers:volume:other:name:value" />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:other:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="cucumbers:volume:other:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("cucumbers:volume:other")}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">Tomatoes</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-1">Reported in:</div>
                                    <div className="col-3 col-md-1">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="tomatoes:unit:value" fieldValue="kg" formType="business-overview" />
                                                <div className="new-label">Kilos</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-1">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="tomatoes:unit:value" fieldValue="cases" formType="business-overview" />
                                                <div className="new-label">Cases</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-tomatoes">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Category</th>
                                                    <th className="text-center">Name</th>
                                                    <th className="text-center">Domestic (Canada)</th>
                                                    <th className="text-center">Export (U.S. & Overseas)</th>
                                                    <th className="text-center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="line-subtitle-overview">Retail</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:retail:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:retail:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("tomatoes:volume:retail")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Food Service</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:foodService:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:foodService:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("tomatoes:volume:foodService")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Terminal/Wholesale</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:terminalWholesale:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:terminalWholesale:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("tomatoes:volume:terminalWholesale")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">OGVG Marketers / Resellers</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:marketersResellers:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:marketersResellers:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("tomatoes:volume:marketersResellers")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Other</td>
                                                    <td>
                                                        <FormInputField formType="business-overview" fieldName="tomatoes:volume:other:name:value" />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:other:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="tomatoes:volume:other:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("tomatoes:volume:other")}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">Peppers</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-1">Reported in:</div>
                                    <div className="col-3 col-md-1">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="peppers:unit:value" fieldValue="kg" formType="business-overview" />
                                                <div className="new-label">Kilos</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-1">
                                        <div className="form-check">
                                            <label>
                                                <FormRadioField fieldName="peppers:unit:value" fieldValue="cases" formType="business-overview" />
                                                <div className="new-label">Cases</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-peppers">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Category</th>
                                                    <th className="text-center">Name</th>
                                                    <th className="text-center">Domestic (Canada)</th>
                                                    <th className="text-center">Export (U.S. & Overseas)</th>
                                                    <th className="text-center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="line-subtitle-overview">Retail</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:retail:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:retail:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("peppers:volume:retail")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Food Service</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:foodService:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:foodService:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("peppers:volume:foodService")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Terminal/Wholesale</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:terminalWholesale:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:terminalWholesale:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("peppers:volume:terminalWholesale")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">OGVG Marketers / Resellers</td>
                                                    <td />
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:marketersResellers:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:marketersResellers:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("peppers:volume:marketersResellers")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="line-subtitle-overview">Other</td>
                                                    <td>
                                                        <FormInputField formType="business-overview" fieldName="peppers:volume:other:name:value" />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:other:domestic:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormInputField
                                                            formType="business-overview"
                                                            type="number"
                                                            fieldName="peppers:volume:other:export:value"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="total">{this.handleTotal("peppers:volume:other")}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormNavigation nextPath="/marketer/attachments" backPath="/marketer/form-5a-sales-reporting" />
                    <Disclamer />
                </div>
            </div>
        );
    }
}
