import * as React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { ValidationStore } from "../../../stores/ValidationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { EmptyGroup, SelectGroup } from "../form-elements/SimpleFormComponents";
import { GenericFormRequest } from "../../../requests/SimpleFieldRequests";
import { errorMessage } from "../Toast";
export interface Fields {
    label: string;
    value: string;
}

interface State {
    otherValue: string;
    loading?: boolean;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    label: string;
    inputClassName: string;
    formType: string;
    fieldName: string;
    info?: string;
    required?: boolean;
    synchronizeValue?: boolean;
    disabled?: boolean;
    options: Array<Fields>;
}

@inject("validationStore")
@inject("applicationStore")
@observer
export default class SimpleSelectWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            otherValue: ""
        };
    }
    updateGeneralForm = async (fieldName: string, value: string, formType: string) => {
        const request: GenericFormRequest = {
            form: formType,
            field: fieldName,
            value: value
        };

        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.updateGeneralForm(request);
            //successMessage(`${text} has been successfully updated.`);
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        if (this.props.required) {
            const { formType, label, fieldName } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, label, (store: ApplicationStore) => {
                const isValid = !!store.findGeneralFormValue(formType, fieldName);
                return isValid;
            });
        }
    }

    handleValidation = () => {
        const { formType, fieldName } = this.props;
        const store = this.props.applicationStore!;
        const isValid = !!store.findGeneralFormValue(formType, fieldName);
        return isValid;
    };

    render() {
        const isValid = this.props.required ? this.handleValidation() : false;
        const formValue = this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.fieldName);
        //const formValue = this.props.applicationStore!.findSimpleFieldValue(this.props.fieldName);
        return (
            <div className="card shadow">
                <div className="card-header">
                    <div className={`d-flex ${isValid ? `text-success` : ``}`}>
                        <div>
                            {this.props.label}
                            {this.props.required ? <span className="text-danger"> * </span> : ""}
                        </div>

                        {this.props.required && (
                            <div className="ml-auto">
                                {isValid ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row col-12 col-lg-8 mx-auto">
                            <div className={`${this.props.inputClassName} mb-3 mr-5`}>
                                <SelectGroup
                                    label=""
                                    isRequired={false}
                                    name={this.props.fieldName}
                                    className="col-md-12 col-lg-6"
                                    disabled={this.props.disabled || this.props.applicationStore!.completed}
                                    value={formValue || null}
                                    onChange={e => this.updateGeneralForm(this.props.fieldName, e.target.value, this.props.formType)}
                                >

                                    {this.props.options.map(field =>
                                        <option key={field.value} value={field.value}>
                                            {field.label}
                                        </option>
                                    )}
                                </SelectGroup>
                                {this.props.info && (
                                    <small id="emailHelp" className="form-text text-muted">
                                        {this.props.info}
                                    </small>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
