import * as React from "react";

const LoadingWidget = ({ className }: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`loading-widget ${className ? className : ``}`}
            width="20"
            height="20"
            viewBox="0 0 405.868 404.868"
        >
            <g id="Group_1" data-name="Group 1" transform="translate(-254.189 -337.322)">
                <path
                    className="path"
                    data-name="Path 5"
                    d="M499.556,532.174c28.861-41.115,24.6-108.822-17.345-155.569s-141.454-58.193-198.557,8.723-13.621,140.434-13.621,140.434S309.35,459.4,390.569,465.277,499.556,532.174,499.556,532.174Z"
                    fill="#e61c34"
                />
                <path
                    className="path"
                    d="M499.556,532.174c28.861-41.115,24.6-108.822-17.345-155.569s-141.454-58.193-198.557,8.723-13.621,140.434-13.621,140.434S309.35,459.4,390.569,465.277,499.556,532.174,499.556,532.174Z"
                    transform="translate(998.379 83.133) rotate(90)"
                    fill="#008540"
                />
                <path
                    className="path"
                    d="M499.556,532.174c28.861-41.115,24.6-108.822-17.345-155.569s-141.454-58.193-198.557,8.723-13.621,140.434-13.621,140.434S309.35,459.4,390.569,465.277,499.556,532.174,499.556,532.174Z"
                    transform="translate(914.246 1079.512) rotate(-180)"
                    fill="#fdde00"
                />
                <path
                    className="path"
                    d="M499.556,532.174c28.861-41.115,24.6-108.822-17.345-155.569s-141.454-58.193-198.557,8.723-13.621,140.434-13.621,140.434S309.35,459.4,390.569,465.277,499.556,532.174,499.556,532.174Z"
                    transform="translate(-83.133 996.379) rotate(-90)"
                    fill="#f36a1e"
                />
            </g>
        </svg>
    );
};

export default LoadingWidget;
