import { inject, observer } from "mobx-react";
import * as React from "react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import LoadingWidget from "../Loading";
import { errorMessage } from "../Toast";
import { GenericFormRequest } from "../../../requests/SimpleFieldRequests";
import Form2ProducersInformation from "../../Forms/Grower/Form2ProducersInformation";

interface State {
    loading: boolean;
}

interface PropsComponent {
    fieldName: string;
    formType: string;
    clearOther?: () => void;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class FormCheckboxField extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    updateGenericForm = async (fieldName: string, checked: boolean, formType: string) => {
        const request: GenericFormRequest = {
            form: formType,
            field: fieldName,
            value: checked ? "true" : ""
        };

        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.updateGeneralForm(request);
        } catch (error) {
            errorMessage(error);
        } finally {
            if (this.props.clearOther) {
                this.props.clearOther();
            }
            this.setState({ loading: false });
        }
    };

    render() {
        const formValue = this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.fieldName);
        const checked = formValue === "true" || formValue == "true" ? true : false;
        return (
            <div className="check-field-with-loading">
                <input
                    className="form-check-input pr-3"
                    type="checkbox"
                    name={this.props.fieldName}
                    checked={checked}
                    disabled={this.state.loading || this.props.applicationStore!.completed}
                    onChange={() => this.updateGenericForm(this.props.fieldName, !checked, this.props.formType)}
                />
                {this.state.loading && <LoadingWidget className="loading-in-check" />}
            </div>
        );
    }
}
