import { inject } from "mobx-react";
import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";

import { faCheckSquare, faEdit, faHandPaper, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ConfirmUpdateOutcome } from "../../../model/ConfirmUpdate";
import { ConfirmUpdateValidity } from "../../../model/validation/Validation";
import LoadingWidget from "../Loading";
import { ApplicationStore } from "../../../stores/ApplicationStore";

const ValidationTip = ({ validity }: { validity: ConfirmUpdateValidity }) => {
    if (validity === ConfirmUpdateValidity.OUTCOME_BLANK) {
        return (
            <span className="badge badge-warning my-2">
                <FontAwesomeIcon icon={faHandPaper} />
                &nbsp; Please review the details below and press confirm or update.
            </span>
        );
    } else if (validity === ConfirmUpdateValidity.UNFILLED_FIELDS) {
        return (
            <span className="badge badge-warning my-2">
                <FontAwesomeIcon icon={faHandPaper} />
                &nbsp; One or more fields are missing below.
            </span>
        );
    }
    return <div />;
};

export const ConfirmUpdateWrapper = inject("applicationStore")(
    ({
        applicationStore,
        children,
        confirmUpdate,
        onConfirmUpdateChange,
        label,
        loading,
        hideConfirmUpdate,
        validity
    }: {
        applicationStore?: ApplicationStore;
        children: React.ReactNode;
        confirmUpdate: ConfirmUpdateOutcome;
        onConfirmUpdateChange: (confirmUpdate: ConfirmUpdateOutcome) => void;
        label: string;
        loading?: boolean;
        hideConfirmUpdate?: boolean;
        validity?: ConfirmUpdateValidity;
    }) => {
        const labelToId = label
            .replace(/[|&;:$%@"<>()+,]/g, "")
            .split(" ")
            .join("_");
        return (
            <>
                <div className="d-flex align-items-start">
                    <div className="d-block mr-2" style={{ position: "relative", bottom: "-10px" }}>
                        <label>{label}</label>
                        {loading && (
                            <span className="ml-3">
                                <LoadingWidget />
                            </span>
                        )}
                        <h6>{validity ? <ValidationTip validity={validity} /> : null}</h6>
                    </div>
                    <div className="btn-group btn-group-toggle d-flex ml-auto mb-1 widthToggleButtons flex-shrink-0" data-toggle="buttons">
                        {!hideConfirmUpdate && (
                            <>
                                <button
                                    className={`btn ${confirmUpdate === "CONFIRMED" ? `btn-primary active` : `btn-outline-primary`}`}
                                    onClick={() => onConfirmUpdateChange("CONFIRMED")}
                                    id={labelToId + "_confirm"}
                                    disabled={applicationStore!.completed}
                                >
                                    {/* <FontAwesomeIcon icon={faCheckSquare} className="mr-2" /> */}
                                    Confirm
                                </button>
                                <UncontrolledTooltip placement="top" target={labelToId + "_confirm"} delay={0} className="tooltipstrap">
                                    Confirm last year's value for '{label}'
                                </UncontrolledTooltip>
                                <button
                                    className={`btn ${confirmUpdate === "UPDATED" ? `btn-primary active` : `btn-outline-primary`}`}
                                    onClick={() => onConfirmUpdateChange("UPDATED")}
                                    id={labelToId + "_update"}
                                    disabled={applicationStore!.completed}
                                >
                                    {/* <FontAwesomeIcon icon={faEdit} className="mr-2" /> */}
                                    Update
                                </button>
                                <UncontrolledTooltip placement="top" target={labelToId + "_update"} delay={0} className="tooltipstrap">
                                    Update '{label}'
                                </UncontrolledTooltip>
                            </>
                        )}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">{children}</div>
                </div>
            </>
        );
    }
);
