import * as React from "react";

import { ContactUpdate, OneToManyDiscardedFlag } from "../../../model/ConfirmUpdate";
import { EmptyGroup } from "../form-elements/SimpleFormComponents";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import InputField from "../form-elements/InputField";
import { inject, observer } from "mobx-react";

interface Props {
    applicationStore?: ApplicationStore;
    value: ContactUpdate & OneToManyDiscardedFlag;
    loading?: boolean;
    disabled?: boolean;
    synchronizeValue?: boolean;
    onFieldChange: (fieldName: string, newValue: string | number | null) => any;
}

@inject("applicationStore")
@observer
export class ContactWidget extends React.Component<Props, {}> {
    render() {
        const configuration = this.props.applicationStore!.configuration;
        if (!configuration) {
            console.warn("In the ContactWidget without configuration?");
            return <div />;
        }

        const onChange = (fieldName: string, newValue: string | number | null) => {
            if (this.props.loading) {
                return;
            }
            this.props.onFieldChange(fieldName, newValue);
        };

        const disabledCalculated = this.props.disabled || /*this.props.loading ||*/ this.props.value.discarded;
        const strikethrough = !!this.props.value.discarded;
        return (
            <div style={{ backgroundColor: "#f4f4f4" }}>
                {/* TODO: Do we need a handler here? */}
                <button className="btn btn-sm btn-info one-to-many-delete-button">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
                <div className="card-body py-0">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="form-row mb-0">
                            <EmptyGroup label="First Name:" name="firstName" className="col-md-12 col-lg-6" isRequired={false}>
                                <InputField
                                    fieldName="firstName"
                                    value={this.props.value.firstName || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("firstName", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Last Name:" name="lastName" className="col-md-12 col-lg-6" isRequired={false}>
                                <InputField
                                    fieldName="lastName"
                                    value={this.props.value.lastName || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("lastName", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Office Phone:" name="officeNumber" className="col-md-12 col-lg-6" isRequired={false}>
                                <InputField
                                    fieldName="officeNumber"
                                    value={this.props.value.officeNumber || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("officeNumber", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                    type="tel"
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Cell:" name="cellNumber" className="col-md-12 col-lg-6" isRequired={false}>
                                <InputField
                                    fieldName="cellNumber"
                                    value={this.props.value.cellNumber || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("cellNumber", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                    type="tel"
                                />
                            </EmptyGroup>
                            <EmptyGroup label="Email:" name="email" className="col-md-12 col-lg-6" isRequired={false}>
                                <InputField
                                    fieldName="email"
                                    value={this.props.value.email || ""}
                                    synchronizeValue={this.props.synchronizeValue}
                                    onBlur={(newValue: string) => onChange("email", newValue)}
                                    disabled={disabledCalculated}
                                    strikethrough={strikethrough}
                                    type="email"
                                />
                            </EmptyGroup>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
