import * as React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import LoadingWidget from "../Loading";

interface State {}

interface PropsComponent {
    label: React.ReactNode;
    value: string | undefined;
    loading?: boolean;
    required?: boolean;
    onClick: (keyFieldNewValue: string) => void;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class YesNoQuestion extends React.Component<Props, State> {
    render() {
        return (
            <div className="d-flex align-items-start">
                <div className="w-100-percentage mr-2">
                    <label>
                        {this.props.label}
                        {this.props.required && !this.props.value ? (
                            <div>
                                <span className="badge badge-warning my-2">
                                    <FontAwesomeIcon icon={faHandPaper} />
                                    &nbsp; Please enter a value for this field.
                                </span>
                            </div>
                        ) : null}
                    </label>
                    {this.props.loading && (
                        <span className="ml-3">
                            <LoadingWidget />
                        </span>
                    )}
                </div>
                <div className="btn-group btn-group-toggle d-flex mb-1 ml-auto widthToggleButtons flex-shrink-0" data-toggle="buttons">
                    <button
                        type="button"
                        className={`btn ${this.props.value === "yes" ? `btn-primary active` : `btn-outline-primary`}`}
                        onClick={() => this.props.onClick("yes")}
                        disabled={this.props.applicationStore!.completed}
                    >
                        {/* <FontAwesomeIcon icon={faCheck} className="mr-2" /> */}
                        Yes
                    </button>
                    <button
                        type="button"
                        className={`btn ${this.props.value === "no" ? `btn-primary active` : `btn-outline-primary`}`}
                        onClick={() => this.props.onClick("no")}
                        disabled={this.props.applicationStore!.completed}
                    >
                        {/* <FontAwesomeIcon icon={faTimes} className="mr-2" /> */}
                        No
                    </button>
                </div>
            </div>
        );
    }
}
