import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";

import { GrowerSubmission } from "../../../model/Submission";
import { GrowerValidationRules } from "../../../model/validation/GrowerValidation";
import { EvaluateAllRulesForSubsection, GrowerValidation } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import Accordion from "../../Common/Accordion";
import ContactsWidget from "../../Common/confirm-update/ContactsWidget";
import MailingAddressWidget from "../../Common/confirm-update/MailingAddressWidget";
import PhysicalAddressesWidget from "../../Common/confirm-update/PhysicalAddressesWidget";
import { SingleConfirmUpdateComponent } from "../../Common/confirm-update/SingleConfirmUpdateComponent";
import TraceabilityCodesGrowerMarketerWidget from "../../Common/confirm-update/TraceabilityCodesGrowerMarketerWidget";
import TraceabilityCodesGrowerPackerWidget from "../../Common/confirm-update/TraceabilityCodesGrowerPackerWidget";
import { Disclamer } from "../../Common/Disclamer";
import FutureExpansionWidget from "../../Common/FutureExpansionsWidget";
import YesNoSimpleField from "../../Common/simple-fields/YesNoSimpleField";
import YesNoSimpleFieldWithInput from "../../Common/simple-fields/YesNoSimpleFieldWithInput";
import ContentHeader from "../../Template/ContentHeader";

const ONE_TO_MANY_NEW_ID = 0;

interface State {
    currentAccordion: string;
    newIdValue: number;
}

interface Props {
    applicationStore?: ApplicationStore;
}

@inject("applicationStore")
@observer
export default class Form2ProducersInformation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentAccordion: "producerInformation",
            newIdValue: ONE_TO_MANY_NEW_ID
        };
    }

    setCurrentAccordion = (name: string) => {
        this.setState({ currentAccordion: name });
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const submission = applicationStore.submission as GrowerSubmission;
        const member = submission.member;
        const licenceYear = submission.licenceYear;
        const futureExpansions = submission.futureExpansions;
        const completed = (this.props.applicationStore!.submission! as GrowerSubmission).completed;
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Form 2 - Producer's Information" />
                <div className="row content">
                    <div className="alert alert-warning shadow-sm text-center" role="alert">
                        Please <strong>enter or confirm/update</strong> the following information. All fields are mandatory unless otherwise
                        specified.
                        <br />
                        <strong>Note:</strong> All the information on file from the previous year can only be archived and not deleted.
                        <br /> Any new information entered in this application can be deleted before its submission.
                    </div>

                    <Accordion
                        title="Producer's Information"
                        name="producerInformation"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion=""
                        nextAccordion="mailingAddress"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(GrowerValidationRules.form2.subsections.producersInformation, submission)}
                    >
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <SingleConfirmUpdateComponent confirmUpdateName="legalMemberName" label="Legal Greenhouse Name" />
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    label={
                                        <>
                                            Are you a Part-Time Producer?
                                            <br />
                                            <small>For definition please see General Regulations.</small>
                                        </>
                                    }
                                    fieldName="partTimeProducer"
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleFieldWithInput
                                    yesNoLabel="Do you own any other greenhouses?"
                                    yesNoFieldName="ownAnyOtherGreenhouses"
                                    yesNoRequired={true}
                                    onYesLabel="Please list all the other greenhouses you own:"
                                    onYesFieldName="ownAnyOtherGreenhousesNames"
                                    onYesRequired={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    label="Do the collective farms noted above constitute a single FBR/AgriCorp entity in its entirety?"
                                    fieldName="singleFBRAgriCorp"
                                    required={true}
                                />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Mailing Address"
                        name="mailingAddress"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="producerInformation"
                        nextAccordion="physicalAddresses"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(GrowerValidationRules.form2.subsections.mailingAddress, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <MailingAddressWidget label="Mailing Address" confirmUpdateName="mailingAddress" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Physical Address(es)"
                        name="physicalAddresses"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="mailingAddress"
                        nextAccordion="contacts"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(GrowerValidationRules.form2.subsections.physicalAddresses, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <PhysicalAddressesWidget label="Physical Address(es)" confirmUpdateName="physicalAddresses" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Contacts"
                        name="contacts"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="physicalAddresses"
                        nextAccordion="traceabilityCodes"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(GrowerValidationRules.form2.subsections.contacts, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Registered Owner Information" confirmUpdateName="ownerContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Grower Contact Information" confirmUpdateName="growerContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Primary Contact Information" confirmUpdateName="primaryContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Crop Consultant Information" confirmUpdateName="cropConsultantContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Food Safety Contact Information" confirmUpdateName="foodSafetyContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Biosecurity Contact Information" confirmUpdateName="biosecurityContact" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <ContactsWidget label="Human Resources Contact Information" confirmUpdateName="humanResourcesContact" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Traceability Codes"
                        name="traceabilityCodes"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="contacts"
                        nextAccordion="futureExpansions"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(GrowerValidationRules.form2.subsections.traceabilityCodes, submission)}
                    >
                        <div className="row">
                            <div className="col-md-12 col-lg-8 px-0 mx-auto">
                                <div className="alert alert-warning">
                                    <div>
                                        <b>Marketers, Packers, and Your Traceability Codes</b>
                                    </div>
                                    Please provide your traceability codes for each marketer and packer. When using more than one marketer/packer,
                                    please state the % shipped to each. Also, if you market/pack your own produce, please fill out Form 5 and/or Form
                                    4. <br />
                                    <strong>Note:</strong> Producers not represented by a licenced marketer will not be granted a licence.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <TraceabilityCodesGrowerMarketerWidget label="Marketer(s)" confirmUpdateName="growerMarketerTraceability" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <TraceabilityCodesGrowerPackerWidget label="Packer(s)" confirmUpdateName="growerPackerTraceability" />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title="Future Expansions"
                        name="futureExpansions"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="traceabilityCodes"
                        //nextAccordion="billableSquareFeet"
                        nextAccordion=""
                        nextPage="/grower/form-2a-greenhouse-operation-information"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                        completed={EvaluateAllRulesForSubsection(GrowerValidationRules.form2.subsections.futureExpansions, submission)}
                    >
                        <div className="row py-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    label={
                                        <>
                                            Are you planning any expansions (Canada and/or US){" "}
                                            <strong>
                                                from September 1,&nbsp;
                                                {moment(licenceYear.startDate).year()} to August 31, {moment(licenceYear.endDate).year()}
                                            </strong>
                                            ?
                                        </>
                                    }
                                    fieldName="expandInNextYear"
                                    required={true}
                                />
                            </div>
                        </div>
                        {submission.simpleFields["expandInNextYear"] === "yes" && (
                            <div className="row">
                                <div className="col-md-12 col-lg-8 mx-auto">
                                    <FutureExpansionWidget futureExpansions={futureExpansions} />
                                </div>
                            </div>
                        )}
                        <div className="row pb-3">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    label={
                                        <>
                                            Are you planning an expansion in <strong>Canada</strong> within the next <strong>5 years</strong>?
                                        </>
                                    }
                                    fieldName="expandInCanada"
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-8 mx-auto">
                                <YesNoSimpleField
                                    label={
                                        <>
                                            Are you planning an expansion in the <strong>US</strong> within the next <strong>5 years</strong>?
                                        </>
                                    }
                                    fieldName="expandInUSA"
                                    required={true}
                                />
                            </div>
                        </div>
                    </Accordion>

                    {/* <Accordion
                        title="Billable Square Feet"
                        name="billableSquareFeet"
                        currentAccordion={this.state.currentAccordion}
                        previousAccordion="futureExpansions"
                        nextAccordion=""
                        nextPage="/grower/form-2a-greenhouse-operation-information"
                        onClick={(currentAccordion: string) => this.setCurrentAccordion(currentAccordion)}
                    >
                        <ConfirmUpdateWrapper
                            confirmUpdate={confirmUpdates.totalSquareFootage.outcome}
                            onConfirmUpdateChange={newValue => this.onConfirmUpdateChange("totalSquareFootage", newValue, "Billable Square Feet")}
                            label="Total Square Footage"
                        >
                            <SimpleFieldInput
                                    label="Total Square Footage"
                                    keyFieldName="totalSquareFootage"
                                    keyFieldData={confirmUpdates.totalSquareFootage}
                                    onConfirmUpdateChange={(newKeyFieldValue: any) =>
                                        this.onConfirmUpdateChange("totalSquareFootage", newKeyFieldValue, "Total Square Footage")
                                    }
                                />
                        </ConfirmUpdateWrapper>
                    </Accordion> */}

                    <Disclamer />
                </div>
            </div>
        );
    }
}
