import * as React from "react";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import FormInputField from "./FormInputField";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { CalcPercentage } from "../../../assets/Common/FileUtil";
import { ValidationStore } from "../../../stores/ValidationStore";

export interface Fields {
    label: string;
    name: string;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    formType: string;
    label: string;
    required?: boolean;
}

const generateArray = (type: string, formType: string, store: ApplicationStore) => {
    return [
        store!.findGeneralFormValue(formType, "employees:" + type + ":local:value")!,
        store!.findGeneralFormValue(formType, "employees:" + type + ":foreign:value")!,
        store!.findGeneralFormValue(formType, "employees:" + type + ":international:value")!
    ];
};

//This has been replaced because of the removal of percentages
// const isValid = (fieldName: string, formType: string, store: ApplicationStore) => {
//     /**
//      * Cheking for If value !== "0" -> Check Percentage === 100
//      * Or value === "0" -> Check percentage === 0
//      */
//     return (
//         (!!store.findGeneralFormValue(formType, "employees:" + fieldName + ":value") &&
//             store.findGeneralFormValue(formType, "employees:" + fieldName + ":value") !== "0" &&
//             CalcPercentage(generateArray(fieldName, formType, store)) === 100) ||
//         (store.findGeneralFormValue(formType, "employees:" + fieldName + ":value") === "0" &&
//             CalcPercentage(generateArray(fieldName, formType, store)) === 0)
//     );
// };

const isValid = (fieldName: string, formType: string, store: ApplicationStore) => {
    return (
        (!!store.findGeneralFormValue(formType, "employees:"+fieldName+":local:value")) &&
        (!!store.findGeneralFormValue(formType, "employees:"+fieldName+":foreign:value")) &&
        (!!store.findGeneralFormValue(formType, "employees:"+fieldName+":international:value"))
    );
};
const includeOnValidation = (fieldName: string, formType: string, store: ApplicationStore) => {
    if (
        !!store.findGeneralFormValue(formType, "employees:" + fieldName + ":value") ||
        !!store.findGeneralFormValue(formType, "employees:" + fieldName + ":local:value") ||
        !!store.findGeneralFormValue(formType, "employees:" + fieldName + ":foreign:value") ||
        !!store.findGeneralFormValue(formType, "employees:" + fieldName + ":international:value")
    ) {
        return true;
    } else {
        return false;
    }
};

const handleValidation = (formType: string, store: ApplicationStore) => {
    return (
        (includeOnValidation("greenhouse", formType, store) ? isValid("greenhouse", formType, store) : true) &&
        (includeOnValidation("packingShed", formType, store) ? isValid("packingShed", formType, store) : true) &&
        (includeOnValidation("marketingEntity", formType, store) ? isValid("marketingEntity", formType, store) : true) &&
        (includeOnValidation("greenhouse", formType, store)
            && includeOnValidation("packingShed", formType, store)
            && includeOnValidation("marketingEntity", formType, store))
    );
};

@inject("applicationStore")
@inject("validationStore")
@observer
export default class EmployeesWorkingWidget extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.required) {
            const { formType } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, this.props.label, (store: ApplicationStore) => {
                return handleValidation(formType, store);
            });
        }
    }

    render() {
        const { formType } = this.props;
        const store = this.props.applicationStore!;

        //const calcPercentageGreenhouse = CalcPercentage(generateArray("greenhouse", formType, store));
        //const greenhouseOK = calcPercentageGreenhouse === (this.lookupValue("employees:greenhouse:value") === "0" ? 0 : 100);
        const greenHouseLocal =  store!.findGeneralFormValue(formType, "employees:greenhouse:local:value");
        const greenHouseForeign =  store!.findGeneralFormValue(formType, "employees:greenhouse:foreign:value");
        const greenHouseInternational =  store!.findGeneralFormValue(formType, "employees:greenhouse:international:value");
        const greenhouseOK =
            ((greenHouseLocal !== undefined && greenHouseLocal !== "undefined") && !isNaN(parseFloat(greenHouseLocal))) &&
            ((greenHouseForeign !== undefined && greenHouseForeign !== "undefined") && !isNaN(parseFloat(greenHouseForeign))) &&
            ((greenHouseInternational !== undefined && greenHouseInternational !== "undefined") && !isNaN(parseFloat(greenHouseInternational)));


        //const calcPercentagePackingShed = CalcPercentage(generateArray("packingShed", formType, store));
        //const packingShedOK = calcPercentagePackingShed === (this.lookupValue("employees:packingShed:value") === "0" ? 0 : 100);

        const packingShedLocal =  store!.findGeneralFormValue(formType, "employees:packingShed:local:value");
        const packingShedForeign =  store!.findGeneralFormValue(formType, "employees:packingShed:foreign:value");
        const packingShedInternational =  store!.findGeneralFormValue(formType, "employees:packingShed:international:value");
        const packingShedOK =
            ((packingShedLocal !== undefined && packingShedLocal !== "undefined") && !isNaN(parseFloat(packingShedLocal))) &&
            ((packingShedForeign !== undefined &&  packingShedForeign !== "undefined") && !isNaN(parseFloat(packingShedForeign))) &&
            ((packingShedInternational !== undefined &&packingShedInternational !== "undefined") && !isNaN(parseFloat(packingShedInternational)));

        // const calcPercentageMarketingEntity = CalcPercentage(generateArray("marketingEntity", formType, store));
        // const marketingOK = calcPercentageMarketingEntity === (this.lookupValue("employees:marketingEntity:value") === "0" ? 0 : 100);
        const marketingLocal =  store!.findGeneralFormValue(formType, "employees:marketingEntity:local:value");
        const marketingForeign =  store!.findGeneralFormValue(formType, "employees:marketingEntity:foreign:value");
        const marketingInternational =  store!.findGeneralFormValue(formType, "employees:marketingEntity:international:value");
        const marketingOK =
            (marketingLocal !== undefined &&  marketingLocal !== "undefined" && !isNaN(parseFloat(marketingLocal))) &&
            (marketingForeign !== undefined && marketingForeign !== "undefined" && !isNaN(parseFloat(marketingForeign))) &&
            (marketingInternational !== undefined && marketingInternational !== "undefined" && !isNaN(parseFloat(marketingInternational)));

        const validated = this.props.required && handleValidation(formType, store);
        return (
            <div className="card shadow">
                <div className="card-header">
                    <div className={`d-flex ${validated ? `text-success` : ``}`}>
                        <div>
                            {this.props.label}
                            {this.props.required ? <span className="text-danger"> * </span> : ""}
                        </div>
                        {/* <div className="ml-auto">{validated && <FontAwesomeIcon icon={faCheck} className="mr-2" />}</div> */}
                        {this.props.required && (
                            <div className="ml-auto">
                                {validated ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row">
                            <div className="col-12 col-lg-10 mx-auto">
                                <div className="row mb-2">
                                    <div className="col-12 col-lg-2" />
                                    <div className="col-12 col-lg-2" />
                                    <div className="col-12 col-lg-2">Local:</div>
                                    <div className="col-12 col-lg-2">Temporary/Seasonal: </div>
                                    <div className="col-12 col-lg-2">International Workers : </div>
                                    <div className="col-12 col-lg-2">{/*%:*/} </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-2">
                                        <label>In the Greenhouse:</label>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        {/*<FormInputField formType={this.props.formType} fieldName="employees:greenhouse:value" type="number" />*/}
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:greenhouse:local:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:greenhouse:foreign:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:greenhouse:international:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2 pt-2">
                                        <div className={`${greenhouseOK ? `text-success` : `text-danger`}`}>
                                            {greenhouseOK ? (
                                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                            ) : (
                                                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            )}
                                            {/*{calcPercentageGreenhouse} %*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-2">
                                        <label>In the Packing Shed:</label>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        {/*<FormInputField formType={this.props.formType} fieldName="employees:packingShed:value" type="number" />*/}
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:packingShed:local:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:packingShed:foreign:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:packingShed:international:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2 pt-2">
                                        <div className={`${packingShedOK ? `text-success` : `text-danger`}`}>
                                            {packingShedOK ? (
                                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                            ) : (
                                                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            )}
                                            {/*{calcPercentagePackingShed} %*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-2">
                                        <label>In the Marketing Entity:</label>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        {/*<FormInputField formType={this.props.formType} fieldName="employees:marketingEntity:value" type="number" />*/}
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:marketingEntity:local:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:marketingEntity:foreign:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2">
                                        <div className="d-flex">
                                            <FormInputField
                                                formType={this.props.formType}
                                                type="number"
                                                fieldName="employees:marketingEntity:international:value"
                                            />
                                            {/*<div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                            {/*    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                            {/*        %*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-2 pt-2">
                                        <div className={`${marketingOK ? `text-success` : `text-danger`}`}>
                                            {marketingOK ? (
                                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                            ) : (
                                                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            )}
                                            {/*{calcPercentageMarketingEntity} %*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    private lookupValue(field: string): any {
        return this.props.applicationStore!.submission!.generalForms[this.props.formType][field] || "";
    }
}
