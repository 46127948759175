import { inject, observer } from "mobx-react";
import * as React from "react";

import { faCheck, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CalcPercentage } from "../../../assets/Common/FileUtil";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { ValidationStore } from "../../../stores/ValidationStore";
import FormInputField from "./FormInputField";

export interface Fields {
    label: string;
    name: string;
}

interface State {
    percentageMessage: string;
    fieldValues: { [key: string]: string };
    other: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;

    formType: string;
    label: string;
    options: Array<Fields>;
    allowOther: boolean;
    otherInfo?: string;
    icon: React.ReactNode;
    otherPlaceholder?: string;
    placeholder?: string;
    className: string;
    required?: boolean;
}

@inject("applicationStore")
@inject("validationStore")
@observer
export default class NumberBreakdown extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            percentageMessage: "",
            fieldValues: {},
            other: ""
        };
    }

    componentDidMount() {
        if (this.props.required) {
            // Make copies to avoid memory leaks
            const options = this.props.options.slice();
            const { formType, otherInfo, allowOther } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, this.props.label, (store: ApplicationStore) => {
                const calcPercentage = CalcPercentage(
                    this.props.options
                        .map(o => this.props.applicationStore!.findGeneralFormValue(formType, o.name)!)
                        .concat(this.props.applicationStore!.findGeneralFormValue(formType, otherInfo + ":value")!)
                );
                const checkOtherValues =
                    this.props.allowOther && otherInfo
                        ? (!!store.findGeneralFormValue(formType, otherInfo + ":name") &&
                            !!store.findGeneralFormValue(formType, otherInfo + ":value")) ||
                        (!store.findGeneralFormValue(formType, otherInfo + ":name") && !store.findGeneralFormValue(formType, otherInfo + ":value"))
                        : true;

                return calcPercentage === 100 && checkOtherValues;
            });
        }
    }

    render() {
        const store = this.props.applicationStore!;
        const otherName = this.props.otherInfo ? this.props.otherInfo + ":name" : null;
        const otherValue = this.props.otherInfo ? this.props.otherInfo + ":value" : null;

        //Validation

        const checkOtherValues =
            (!!store.findGeneralFormValue(this.props.formType, this.props.otherInfo + ":name") &&
                !!store.findGeneralFormValue(this.props.formType, this.props.otherInfo + ":value")) ||
            (!store.findGeneralFormValue(this.props.formType, this.props.otherInfo + ":name") &&
                !store.findGeneralFormValue(this.props.formType, this.props.otherInfo + ":value"));

        const isValid = checkOtherValues && this.props.required;
        return (
            <div className="card shadow">
                <div className="card-header d-flex">
                    <div>
                        <span className={isValid ? `text-success` : ``}>{this.props.label}</span>
                        {this.props.required ? <span className="text-danger"> * </span> : ""}
                    </div>
                    {this.props.required && (
                        <>
                            <div className="ml-auto">
                                {isValid ? (
                                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="card-body">
                    <form>
                        <div className="col-12 col-lg-8 mx-auto">
                            {!checkOtherValues && (
                                <div className="form-row">
                                    <div className="col-10">
                                        <div className="alert alert-danger text-center">
                                            <FontAwesomeIcon icon={faHandPaper} className="mr-2" />A name for the Other option must be specified when
                                            it has been assigned a percentage, or vice versa.
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.props.options.map(field => {
                                return (
                                    <div key={field.name} className="form-group row">
                                        <label htmlFor={field.name} className="col-12 col-lg-3 col-form-label">
                                            {field.label}:
                                        </label>
                                        <div className="col-12 col-lg-3" />
                                        <div className="col-12 col-md-4">
                                            <FormInputField formType={this.props.formType} type="number" fieldName={field.name} />
                                        </div>
                                    </div>
                                );
                            })}
                            {this.props.allowOther && otherName && otherValue && (
                                <>
                                    <div className="form-group row">
                                        <label htmlFor="other" className="col-12 col-lg-3 col-form-label">
                                            Other:
                                        </label>
                                        <div className="col-12 col-lg-3">
                                            <FormInputField formType={this.props.formType} fieldName={otherName} />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <FormInputField formType={this.props.formType} type="number" fieldName={otherValue} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
