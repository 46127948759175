import { AttachmentFile } from "./Attachments";

export enum Commodity {
    CUCUMBER = "CUCUMBER",
    PEPPER = "PEPPER",
    TOMATO = "TOMATO"
}

export const CommodityFriendlyName = (index: Commodity): string => {
    switch (index) {
        case Commodity.CUCUMBER:
            return "Cucumber";
        case Commodity.PEPPER:
            return "Pepper";
        case Commodity.TOMATO:
            return "Tomato";
        default:
            return "?";
    }
};

export interface ProprietaryCommodity {
    id: number;
    type: Commodity;
    variety: string;
    notes: string;
    applicationSourced: boolean;
    // Currently unused
    files: Array<AttachmentFile>;
    //added 2023 for new proprietary data collection all are nullable due to prior proprietary commodities potentially being null.
    seedCompanyProvider: string | null;
    seedCompanyProviderMainContact: string | null;
    seedCompanyAddress: string | null;
    seedCompanyPhoneNumber: string | null;
    seedCompanyEmailAddress: string | null;
    proprietaryCode: string | null;
    protectedVariety: boolean | null;
    exclusiveCommodity: boolean | null;
    exclusivityNotes: string | null;
    contractEstablished: boolean | null;
    contactTimeFrameMonths: number | null;
    annualAcresToSeed: number | null;
    transferrableAgreement: boolean | null;

}

export interface YearWeek {
    year: number;
    week: number;
}
