import * as React from "react";
import { observer, inject } from "mobx-react";
import { ApplicationStore } from "../../stores/ApplicationStore";
import { Information } from "./Information";
import { Link } from "react-router-dom";
import SimpleCheckField from "../Common/simple-fields/SimpleCheckField";

interface Props {
    applicationStore?: ApplicationStore;
    onClick: () => void;
}

interface State {}

@inject("applicationStore")
@observer
export default class WelcomeBack extends React.Component<Props, State> {
    render() {
        const applicationStore = this.props.applicationStore!;
        const memberType = applicationStore.submission!.member.memberType;
        return (
            <div className="modal-information-section">
                <div className="information-section">
                    <div className="information-section-content shadow">
                        <div className="row my-5">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="text-center text-info pb-2">Welcome back!</h4>
                                        <p className="text-center">
                                            This application has already been submitted and cannot be edited any more. You can still access it in
                                            read-only mode. <br />
                                            If you need to apply changes to this application, please call the office at
                                            <strong> 519-326-2604 </strong> for assistance.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 mx-auto text-center">
                                        <button type="button" className="btn btn-warning px-5" onClick={() => this.props.onClick()}>
                                            View Application
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
