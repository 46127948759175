import * as React from "react";
import FormInputField from "./FormInputField";
import { inject, observer } from "mobx-react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import { ValidationStore } from "../../../stores/ValidationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { EmptyGroup, SelectGroup } from "../form-elements/SimpleFormComponents";
import internal from "assert";
import YesNoWidget from "./YesNoWidget";
import CheckboxBreakdown from "./CheckboxBreadown";
import SimpleInputWidget from "./SimpleInputWidget";
import NumberBreakdown from "./NumberBreakdown";

export interface Fields {
    label: string;
    name: string;
}

interface State {
    otherValue: string;
    housingCount: number;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    formType: string;
    label: string;
    required?: boolean;
    unitNumber: number;
}

const handleValidation = (formType: string, store: ApplicationStore) => {
    return (
        !!store.findGeneralFormValue(formType, "housing:count:value") ||
        !!store.findGeneralFormValue(formType, "housing:healthunit:value")
    );
};

@inject("validationStore")
@inject("applicationStore")
@observer
export default class HousingInformationWidget extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.required) {
            const { formType, label } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, "Housing Information", (store: ApplicationStore) =>
                handleValidation(formType, store)
            );
        }
    }

    render() {
        const { formType, label } = this.props;
        const store = this.props.applicationStore!;
        const isValid = this.props.required ? handleValidation(formType, store) : false;
        return (
            <div>
                <div className="col-12 mt-5 pb-3 text-info">
                    <h4> {this.props.label} </h4>
                </div>
                {/* </div>
            <div className="card shadow"> */}
                {/* <div className="card-header">
                    <div className={`d-flex ${isValid ? `text-success` : ``}`}>
                        <div>
                            {this.props.label}
                            {this.props.required ? <span className="text-danger"> * </span> : ""}
                        </div>
                        {/* <div className="ml-auto">{isValid && <FontAwesomeIcon icon={faCheck} className="mr-2" />}</div> */}
                {/* {this.props.required && (
                            <div className="ml-auto">
                                {isValid ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    <>
                                        <small className="text-danger">Missing value. Please enter a value for this question.</small>
                                        <FontAwesomeIcon icon={faHandPaper} className="text-danger ml-2" />
                                    </>
                                )}
                            </div>
                        )} */}
                {/* </div>
                </div> */}
                {/* <div className="card-body">
                    <form> */}

                <YesNoWidget
                    label={`Is this unit on the farm?`}
                    required={true}
                    formType="goi"
                    inputFieldName={"housing:unit[" + (this.props.unitNumber - 1) + "]:onFarm:value"}
                    radioName={"housing:unit[" + (this.props.unitNumber - 1) + "]:onFarm:value"}
                />


                <CheckboxBreakdown
                    label={`What type of structure is this unit?`}
                    required={true}
                    className="col-12 col-md-3"
                    formType="goi"
                    options={[
                        {
                            label: "Single Detached",
                            name: "housing:unit[" + (this.props.unitNumber - 1) + "]:singleDetached:value",
                        },
                        {
                            label: "Boarding House",
                            name: "housing:unit[" + (this.props.unitNumber - 1) + "]:boardingHouse:value",
                        },
                        {
                            label: "Worker Residence",
                            name: "housing:unit[" + (this.props.unitNumber - 1) + "]:workerResidence:value",
                        }

                    ]}
                />
                <SimpleInputWidget
                    label={`Do you Rent or own this housing unit?`}
                    required={true}
                    inputClassName="col-12 col-md-12"
                    formType="goi"
                    fieldName={"housing:unit[" + (this.props.unitNumber - 1) + "]:rent:value"}
                />
                <CheckboxBreakdown
                    label={`Please indicate which of the following are included in this housing unit?`}
                    required={true}
                    className="col-12 col-md-3"
                    formType="goi"
                    options={[
                        {
                            label: "Fire Alarm System",
                            name: "housing:unit[" + (this.props.unitNumber - 1) + "]:fireAlarm:value",
                        },
                        {
                            label: "Smoke Detectors",
                            name: "housing:unit[" + (this.props.unitNumber - 1) + "]:smokeDetector:value",
                        },
                        {
                            label: "Fire Extinguisher",
                            name: "housing:unit[" + (this.props.unitNumber - 1) + "]:fireExtinguisher:value",
                        }

                    ]}
                />
                <YesNoWidget
                    label={`Does this unit include posted translated signage?`}
                    required={true}
                    formType="goi"
                    inputFieldName={"housing:unit[" + (this.props.unitNumber - 1) + "]:translatedSignage:value"}
                    radioName={"housing:unit[" + (this.props.unitNumber - 1) + "]:translatedSignage:value"}
                />
                <SimpleInputWidget
                    label={`What is the zone classification of this unit?`}
                    required={true}
                    inputClassName="col-12 col-md-12"
                    formType="goi"
                    fieldName={"housing:unit[" + (this.props.unitNumber - 1) + "]:zoneClass:value"}
                />
                <NumberBreakdown
                    label={`Please indicate how many of each item are included in this unit:`}
                    required={false}
                    className="col-12 col-md-3"
                    formType="goi"
                    options={[
                        { label: "Individual Beds", name: "housing:unit[" + (this.props.unitNumber - 1) + "]:beds:value" },
                        { label: "Bunk Beds", name: "housing:unit[" + (this.props.unitNumber - 1) + "]:bunkbeds:value" },
                        { label: "Toilets", name: "housing:unit[" + (this.props.unitNumber - 1) + "]:toilets:value" },
                        { label: "Fridges", name: "housing:unit[" + (this.props.unitNumber - 1) + "]:fridges:value" },
                        { label: "Sinks", name: "housing:unit[" + (this.props.unitNumber - 1) + "]:sinks:value" },
                        { label: "Showers", name: "housing:unit[" + (this.props.unitNumber - 1) + "]:showers:value" }
                    ]}
                    icon=""
                    allowOther={false}
                />
                {/* </form>
                </div>
            </div> */}
            </div>
        );
    }
}
