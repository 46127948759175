import * as React from "react";
import { observer, inject } from "mobx-react";
import { ApplicationStore } from "../../stores/ApplicationStore";
import { Information } from "./Information";
import { Link } from "react-router-dom";
import SimpleCheckField from "../Common/simple-fields/SimpleCheckField";

interface Props {
    applicationStore?: ApplicationStore;
    onClick: () => void;
}

interface State {}

@inject("applicationStore")
@observer
export default class InformationSection extends React.Component<Props, State> {
    render() {
        const applicationStore = this.props.applicationStore!;
        const memberType = applicationStore.submission!.member.memberType;
        return (
            <div className="modal-information-section">
                <div className="information-section">
                    <div className="information-section-content shadow">
                        <div className="row my-5">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <Information memberType={memberType} welcome={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 mx-auto text-center">
                                        <div className="form-check mb-2">
                                            <label className="d-flex justify-content-center">
                                                <SimpleCheckField fieldName="dontShowInfoAgain" />
                                                <div className="new-label">Do not show me this page again.</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 mx-auto text-center">
                                        <button type="button" className="btn btn-warning px-5" onClick={() => this.props.onClick()}>
                                            Start Application
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
