import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap";
import { FormDialog } from "../Common/Dialog";
import { Information } from "./Information";
import { inject } from "mobx-react";
import { observable } from "mobx";
import { ApplicationStore } from "../../stores/ApplicationStore";

const MemberTypeFriendlyName = (memberType: string): string => {
    switch (memberType) {
        case "GROWER":
            return "Grower";
        case "PACKER":
            return "Packer";
        case "MARKETER":
            return "Marketer";
        default:
            return "?";
    }
};

interface State {
    showDialog: boolean;
}

interface Props {
    applicationStore?: ApplicationStore;
    name: string;
    pageTitle: string;
}

@observable
@inject("applicationStore")
export default class ContentHeader extends React.Component<Props, State> {
    private form: HTMLFormElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false
        };
    }

    calculateStatus() {
        if (this.props.applicationStore!.submission!.deactivated) {
            return "Deactivated";
        } else if (this.props.applicationStore!.completed) {
            return "Completed";
        } else {
            return "In Progress";
        }
    }

    render() {
        if (!this.props.applicationStore!.submission) {
            console.error("In content header without a submission in the store?");
            return null;
        }
        const submission = this.props.applicationStore!.submission;
        const member = submission.member;
        return (
            <div className="row header pt-4">
                <div className="col-12 col-md-8">
                    <div className="row my-0 formType">{MemberTypeFriendlyName(member.memberType)}</div>
                    <h3 className="row my-0">{this.props.name}</h3>
                    <h5 className="row my-0">{this.props.pageTitle}</h5>
                </div>
                <div className="col-12 col-md-4 pr-0">
                    <div className="row pr-0">
                        <div className="col-12 text-right">
                            Status: <span className="text-info">{this.calculateStatus()}</span>
                        </div>
                    </div>
                    <div className="row pr-0">
                        <div className="col-12 mt-2 text-right">
                            <button
                                id="system_info_target"
                                type="button"
                                className="btn btn-info px-3"
                                onClick={() => this.setState({ showDialog: true })}
                            >
                                <FontAwesomeIcon icon={faInfo} />
                            </button>
                            <UncontrolledTooltip placement="top" target="system_info_target" delay={0} className="tooltipstrap">
                                Instructions
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </div>
                <FormDialog title="" modalSize="modal-lg" show={this.state.showDialog} onClose={() => this.setState({ showDialog: false })}>
                    <Information memberType={member.memberType} />
                </FormDialog>
            </div>
        );
    }
}
