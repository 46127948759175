import { inject, observer } from "mobx-react";
import * as React from "react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import LoadingWidget from "../Loading";
import { errorMessage } from "../Toast";
import TextareaField from "../form-elements/TextareaField";

interface State {
    loading: boolean;
}

interface PropsComponent {
    label: string;
    fieldName: string;
    rows?: number;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class SimpleTextareaField extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    onChangeSimpleField = async (updatedValue: string) => {
        try {
            // Skip on no change
            if (this.props.applicationStore!.findSimpleFieldValue(this.props.fieldName) === updatedValue) {
                return;
            }

            this.setState({ loading: true });
            await this.props.applicationStore!.updateSimpleFields({
                [this.props.fieldName]: updatedValue
            });
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const simpleFieldValue = this.props.applicationStore!.findSimpleFieldValue(this.props.fieldName) || "";
        return (
            <>
                <div className="w-100-percentage mr-2">
                    <label>{this.props.label}</label>
                    {this.state.loading && (
                        <span className="ml-3">
                            <LoadingWidget />
                        </span>
                    )}
                </div>
                <TextareaField
                    rows={this.props.rows}
                    fieldName={this.props.fieldName}
                    value={simpleFieldValue}
                    onBlur={this.onChangeSimpleField}
                    disabled={this.state.loading || this.props.applicationStore!.completed}
                />
            </>
        );
    }
}
