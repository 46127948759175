import * as React from "react";
import { observer, inject } from "mobx-react";
import { ApplicationStore } from "../../../stores/ApplicationStore";

interface State {
    value: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    fieldName: string;
    value: string;
    disabled?: boolean;
    placeholder?: string;
    synchronizeValue?: boolean;
    className?: string;
    strikethrough?: boolean;
    onBlur: (newValue: string) => void;
    notCheckUpdated?: boolean;
    type?: string;
    isPercentage?: boolean;
}

@inject("applicationStore")
@observer
export default class InputField extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.value !== "") {
            this.setState({ value: this.props.value });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // if (this.props.synchronizeValue) {
        if ((this.props.synchronizeValue || prevProps.synchronizeValue) && this.props.value !== this.state.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        const updated = this.props.notCheckUpdated === true ? false : this.state.value !== this.props.value;

        return (
            <div className={this.props.isPercentage === true ? `d-flex` : `d-block`} style={{ width: "100%" }}>
                <div className="input-group">
                    <input
                        type={this.props.type ? this.props.type : "text"}
                        className={`form-control ${updated ? `text-danger` : ``} ${this.props.className ? this.props.className : ``} ${
                            this.props.strikethrough ? "strikethrough" : ""
                        }`}
                        name={this.props.fieldName}
                        placeholder={this.props.placeholder || ""}
                        value={this.state.value}
                        disabled={this.props.disabled || this.props.applicationStore!.completed}
                        maxLength={this.props.isPercentage === true ? 4 : 250}
                        onChange={e => this.setState({ value: e.target.value })}
                        onBlur={() => {
                            if (
                                (this.props.value !== "" && this.props.value !== this.state.value) ||
                                (this.props.value === "" && this.state.value !== "")
                            ) {
                                this.props.onBlur(this.state.value);
                            }
                        }}
                        autoComplete={
                            this.props
                                .fieldName /* Sugestion of : https://www.20spokes.com/blog/what-to-do-when-chrome-ignores-autocomplete-off-on-your-form */
                        }
                        min={this.props.type === "number" ? 0 : ""}
                        step={this.props.type === "number" ? 0.1 : ""}
                    />
                </div>
                <div className={this.props.isPercentage === true ? `input-group-prepend` : `d-none`} style={{ position: "relative", left: "-1px" }}>
                    <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>
                        %
                    </div>
                </div>
            </div>
        );
    }
}
