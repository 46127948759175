import * as React from "react";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import FormInputField from "./FormInputField";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import FormRadioField from "./FormRadioField";
import { CalcPercentage } from "../../../assets/Common/FileUtil";
import { ValidationStore } from "../../../stores/ValidationStore";

export interface Fields {
    label: string;
    name: string;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;
    formType: string;
    label: string | React.ReactNode;
    required?: boolean;
    lastYear: number;
}

const HandleValidation = (formType: string, store: ApplicationStore) => {

    const p = store.findGeneralFormValue(formType, "workforceNextYearNumber:percent:value");
    const n = store.findGeneralFormValue(formType, "workforceNextYearNumber:number:value");

    const valid = (p && parseInt(p) > 0) || (n && parseInt(n) > 0)
    return valid; // checkFields;
};

@inject("validationStore")
@inject("applicationStore")
@observer
export default class WorkersRequiredWidget extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.required) {
            const { formType, label } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, "Workers Required", (store: ApplicationStore) =>
                HandleValidation(formType, store)
            );
        }
    }

    render() {
        const store = this.props.applicationStore!;


        const isValid = this.props.required && HandleValidation(this.props.formType, store);

        return (
            <div className="card shadow">
                <div className="card-header d-flex">
                    <div>
                        <span className={isValid ? `text-success` : ``}>{this.props.label}</span>
                        {this.props.required ? <span className="text-danger"> * </span> : ""}
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row">
                            <div className="col-12 col-lg-8 mx-auto">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <label>#:</label>
                                        <div className="d-flex">
                                            <FormInputField formType={this.props.formType} type="number" fieldName="workforceNextYear:number:value" />
                                        </div>
                                    </div>
                                    {/*<div className="col-12 col-lg 4">*/}
                                    {/*    <label> OR </label>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 col-lg-4">*/}
                                    {/*    <label>%:</label>*/}
                                    {/*    <div className="d-flex">*/}
                                    {/*        <FormInputField formType={this.props.formType} type="number" fieldName="workforceNextYear:percent:value" />*/}
                                    {/*        <div className="input-group-prepend" style={{ position: "relative", left: "-1px" }}>*/}
                                    {/*            <div className="input-group-text" style={{ borderRadius: "0 .25rem .25rem 0" }}>*/}
                                    {/*                %*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
