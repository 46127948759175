/**
 * Intercepted fetch logic
 */

export interface FetchOptions extends RequestInit {
    /**
     * If true, this will instruct the fetch to return the raw response, not an interpreted message
     */
    returnRawResponseOnError?: boolean;
    /**
     * The body object is overriden to accept anything since we serialize it to JSON by default
     */
    body?: any;
    /**
     * The headers parameter is relaxed a bit for our purposes
     */
    headers?: { [header: string]: string };
    /**
     * If true, the response will be a blob
     */
    returnBlob?: boolean;
    /**
     * If true, the unauthorized interceptor will not be triggered.
     */
    ignoreUnauthorized?: boolean;
}
export type Fetch = (url: string, options?: FetchOptions, refreshToken?: boolean) => Promise<any>;

export const InterceptedFetch = (
    authorizationProvider: () => string,
    onRefreshedToken: (token: string) => void,
    onUnauthorized: () => void
): Fetch => {
    return (url: string, options: FetchOptions = {}, refreshToken = true): Promise<any> => {
        const opts = Object.assign(
            {
                cache: "no-cache",
                headers: {
                    "content-type": "application/json"
                },
                mode: "cors",
                referrer: "no-referrer"
            },
            options
        );

        // Always inject the proper authorization header
        opts.headers.authorization = "Bearer " + authorizationProvider();

        // Inject the X-Refresh-Token as well
        opts.headers["X-Refresh-Token"] = `${refreshToken}`;

        // If the body is a non-string, then assume it is to be stringified to JSON
        if (opts.body !== undefined && typeof opts.body !== "string") {
            opts.body = JSON.stringify(opts.body);
        }

        return new Promise((resolve, reject) => {
            fetch(url, opts)
                .then(response => {
                    // Pass back unauthorized access - Removed to Catch Postal Code Error.
                    if (response.status === 401 && !options.ignoreUnauthorized) {
                        onUnauthorized();
                        reject("Token has expired.");
                    }

                    // If we have a new token, then pass this along to the handler
                    const refreshedToken = response.headers.get("X-Refreshed-Token");
                    if (refreshedToken) {
                        onRefreshedToken(refreshedToken);
                    }

                    // Get the contentType and determine the next steps
                    const contentType = (response.headers.get("content-type") || "").split(";")[0];
                    if (options.returnBlob) {
                        if (response.ok) {
                            response.blob().then(r => resolve(r));
                        } else {
                            response.text().then(r => reject(r));
                        }
                    } else if (contentType === "application/json") {
                        if (response.ok) {
                            response.json().then(r => resolve(r));
                        } else {
                            response.json().then(r => reject(r));
                        }
                    } else if (contentType === "text/plain" || contentType === "text/html") {
                        if (response.ok) {
                            response.text().then(r => resolve(r));
                        } else {
                            response.text().then(r => reject(r));
                        }
                    } else {
                        if (response.ok) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(err => {
                    if (options.returnRawResponseOnError) {
                        reject(err);
                    } else if (err.text) {
                        err.text().then((msg: string) => reject(msg));
                    } else {
                        reject(err.responseText || err.statusText || err.message || err || "An unknown error occurred");
                    }
                });
        });
    };
};
