import { Member } from "./Submission";
import { Country, Province } from "./Configuration";
import { object } from "prop-types";

// -------------------------------------------
// Fundamental Types
// -------------------------------------------

export type SingleCurrentValue<T> = { value: T };
export type SingleUpdateValue<T> = SingleCurrentValue<T>;

export type OneToManyDiscardedFlag = { discarded?: boolean };
export type OneToManyCurrentValue<T> = { [id: string]: T };
export type OneToManyUpdateValue<T> = {
    [id: string]: T & OneToManyDiscardedFlag;
};

// -------------------------------------------
// The ConfirmUpdate itself
// -------------------------------------------

export type ConfirmUpdateOutcome = "UPDATED" | "CONFIRMED" | "BLANK";
export type ConfirmUpdate = {
    outcome: ConfirmUpdateOutcome;
    expected: string | null;
};
export interface SingleConfirmUpdate<C, U> extends ConfirmUpdate {
    current: SingleCurrentValue<C>;
    updated: SingleUpdateValue<U> | null;
}
export interface OneToManyConfirmUpdate<C, U> extends ConfirmUpdate {
    current: OneToManyCurrentValue<C>;
    updated: OneToManyUpdateValue<U> | null;
}

// -------------------------------------------
// The "current" and "update" data type for the actual fields
// -------------------------------------------

// ---- Addresses ----

export interface AddressCurrent {
    name: string;
    address: string;
    city: string;
    province: Province;
    country: Country;
    postalCode: string;
    totalSquareFeet:string | null;
    commodities: string | null;
}

export interface AddressUpdate {
    name: string;
    address: string;
    city: string;
    provinceId: number | null;
    countryId: number | null;
    postalCode: string;
    totalSquareFeet:string | null;
    commodities: string | null;
}

export const CopyAddressCurrentToUpdate = (current: AddressCurrent): AddressUpdate => {
    return {
        name: current.name,
        address: current.address,
        city: current.city,
        provinceId: current.province ? current.province.id : null,
        countryId: current.country ? current.country.id : null,
        postalCode: current.postalCode,
        totalSquareFeet: current.totalSquareFeet ? current.totalSquareFeet : null,
        commodities: current.commodities ? current.commodities : null
    };
};

export const EmptyAddressUpdate = (): AddressUpdate => {
    return {
        name: "",
        address: "",
        city: "",
        provinceId: null,
        countryId: null,
        postalCode: "",
        totalSquareFeet: "",
        commodities: ""
    };
};

// -------------------

// ---- Contacts -----

export interface ContactCurrent {
    firstName: string;
    lastName: string;
    officeNumber: Array<string>; // 1:* in MRM
    cellNumber: Array<string>; // 1:* in MRM
    email: Array<string>; // 1:* in MRM
}

export interface ContactUpdate {
    firstName: string;
    lastName: string;
    // When converting the current to this, the arrays can be converted to comma separated values
    officeNumber: string;
    cellNumber: string;
    email: string;
}

export const CopyContactCurrentToUpdate = (current: ContactCurrent): ContactUpdate => {
    return {
        firstName: current.firstName,
        lastName: current.lastName,
        officeNumber: current.officeNumber
            .slice()
            .sort()
            .join(", "),
        cellNumber: current.cellNumber
            .slice()
            .sort()
            .join(", "),
        email: current.email
            .slice()
            .sort()
            .join(", ")
    };
};

export const EmptyContactUpdate = (): ContactUpdate => {
    return {
        firstName: "",
        lastName: "",
        officeNumber: "",
        cellNumber: "",
        email: ""
    };
};

// ---------------------------------------

// ---- Grower-Marketer Traceability -----

export enum NatureOfRelationship {
    CONTRACT = "CONTRACT",
    NOT_CONTRACT = "NOT_CONTRACT",
    ROAD_SIDE = "ROAD_SIDE",
    NOT_SPECIFIED = "NOT_SPECIFIED"
}

// Assume id=0, codes=[], marketer=null is road-side stand
export interface GrowerMarketerCurrent {
    id: number;
    codes: Array<string>;
    marketer: Member | null;
    percentage: number;
    relationship: NatureOfRelationship;
    // sideRoadStandPercentage: number;
}

// Assume codes="", marketer="Road Side Stand", relationship=ROAD_SIDE for road-side stand
export interface GrowerMarketerUpdate {
    codes: string;
    marketer: string;
    percentage: string;
    relationship: NatureOfRelationship;
}

export const CopyGrowerMarketerCurrentToUpdate = (current: GrowerMarketerCurrent): GrowerMarketerUpdate => {
    return {
        codes: current.codes
            .slice()
            .sort()
            .join(", "),
        marketer: current.marketer ? current.marketer.businessName : "N/A",
        percentage: current.percentage.toString(),
        relationship: current.relationship
    };
};

export const GrowerMarketerEmpty = (): GrowerMarketerUpdate => {
    return {
        codes: "",
        marketer: "",
        percentage: "",
        relationship: NatureOfRelationship.NOT_SPECIFIED
    };
};

// ---------------------------------------

// ---- Grower-Packer Traceability -------

export interface GrowerPackerCurrent {
    id: number;
    codes: Array<string>;
    packer: Member;
    percentage: number;
}

export interface GrowerPackerUpdate {
    codes: string;
    packer: string;
    percentage: string;
}

export const CopyGrowerPackerCurrentToUpdate = (current: GrowerPackerCurrent): GrowerPackerUpdate => {
    return {
        codes: current.codes
            .slice()
            .sort()
            .join(", "),
        packer: current.packer.businessName,
        percentage: current.percentage.toString()
    };
};

export const GrowerPackerEmpty = (): GrowerPackerUpdate => {
    return {
        codes: "",
        packer: "",
        percentage: ""
    };
};

// ---------------------------------------

// ---- Packer-Grower Traceability -------

export interface PackerGrowerCurrent {
    id: number;
    growers: Array<Member>;
    codes: Array<string>;
    marketersUsed: Array<Member>;
}

export interface PackerGrowerUpdate {
    growers: string;
    codes: string;
    marketersUsed: string;
}

export const CopyPackerGrowerCurrentToUpdate = (current: PackerGrowerCurrent): PackerGrowerUpdate => {
    return {
        codes: current.codes
            .slice()
            .sort()
            .join(", "),
        growers: current.growers
            .map(grower => grower.businessName)
            .sort()
            .join(", "),
        marketersUsed: current.marketersUsed
            .map(marketer => marketer.businessName)
            .sort()
            .join(", ")
    };
};

export const PackerGrowerEmpty = (): PackerGrowerUpdate => {
    return {
        codes: "",
        growers: "",
        marketersUsed: ""
    };
};

// ---------------------------------------

// ---- Marketer-Grower Traceability -----

export interface MarketerGrowerCurrent {
    id: number;
    growers: Array<Member>;
    codes: Array<string>;
    brandName: string;
    relationship: NatureOfRelationship;
}

export interface MarketerGrowerUpdate {
    growers: string;
    codes: string;
    brandName: string;
    relationship: NatureOfRelationship;
}

export const CopyMarketerGrowerCurrentToUpdate = (current: MarketerGrowerCurrent): MarketerGrowerUpdate => {
    return {
        codes: current.codes
            .slice()
            .sort()
            .join(", "),
        growers: current.growers
            .map(grower => grower.businessName)
            .slice()
            .sort()
            .join(", "),
        brandName: current.brandName,
        relationship: current.relationship
    };
};

export const MarketerGrowerEmpty = (): MarketerGrowerUpdate => {
    return {
        codes: "",
        growers: "",
        brandName: "",
        relationship: NatureOfRelationship.NOT_SPECIFIED
    };
};

// ---------------------------------------

// -------------------------------------------
// Collections of all Grower/Packer/Marketer ConfirmUpdate fields
// -------------------------------------------

export interface CommonConfirmUpdateFields {
    legalMemberName: SingleConfirmUpdate<string, string>;
    mailingAddress: SingleConfirmUpdate<AddressCurrent, AddressUpdate>;
    physicalAddresses: OneToManyConfirmUpdate<AddressCurrent, AddressUpdate>;
    ownerContact: OneToManyConfirmUpdate<ContactCurrent, ContactUpdate>;
    primaryContact: OneToManyConfirmUpdate<ContactCurrent, ContactUpdate>;
    foodSafetyContact: OneToManyConfirmUpdate<ContactCurrent, ContactUpdate>;
    biosecurityContact: OneToManyConfirmUpdate<ContactCurrent, ContactUpdate>;
}
export interface GrowerConfirmUpdateFields extends CommonConfirmUpdateFields {
    growerContact: OneToManyConfirmUpdate<ContactCurrent, ContactUpdate>;
    cropConsultantContact: OneToManyConfirmUpdate<ContactCurrent, ContactUpdate>;
    humanResourcesContact: OneToManyConfirmUpdate<ContactCurrent, ContactUpdate>;
    growerPackerTraceability: OneToManyConfirmUpdate<GrowerPackerCurrent, GrowerPackerUpdate>;
    growerMarketerTraceability: OneToManyConfirmUpdate<GrowerMarketerCurrent, GrowerMarketerUpdate>;
    billableSquareFeet: SingleConfirmUpdate<string, string>;
}
export interface PackerConfirmUpdateFields extends CommonConfirmUpdateFields {
    packerGrowerTraceability: OneToManyConfirmUpdate<PackerGrowerCurrent, PackerGrowerUpdate>;
}
export interface MarketerConfirmUpdateFields extends CommonConfirmUpdateFields {
    marketerGrowerTraceability: OneToManyConfirmUpdate<MarketerGrowerCurrent, MarketerGrowerUpdate>;
}

// -------------------------------------------
// An example to show how it fits together
// -------------------------------------------
/*
const test: OneToManyConfirmUpdate<MarketerGrowerCurrent, MarketerGrowerUpdate> = {
    outcome: "UPDATED",
    // Current types represent what's on the server so we can go into more detail
    current: {
        "123": {
            id: 123,
            growers: [
                {
                    id: 1,
                    memberType: "GROWER",
                    state: "ACTIVE",
                    friendlyName: "Bob Smith Farms - Grower",
                    memberName: "Grower",
                    businessName: "Bob Smith Farms"
                }
            ],
            codes: ["ABC123"],
            brandName: "Green Fresh",
            relationship: NatureOfRelationship.CONTRACT
        }
    },
    // What's collected in the updated can't be represented in the same amount of detail since
    // we can't reasonably allow members to search for other members in the database.
    // Instead, the user will enter free text and we'll report this to the OGVG for manual entry.
    updated: {
        "-1": {
            growers: "Green Value Fresh Acres Farms",
            codes: "abcdef",
            brandName: "don't know",
            relationship: NatureOfRelationship.CONTRACT
        },
        "123": {
            growers: "Green Value Fresh Acres Farms",
            codes: "abcdef",
            brandName: "don't know",
            relationship: NatureOfRelationship.CONTRACT,
            discarded: true
        }
    }
};
*/
