import * as React from "react";
// import * as Datetime from "react-datetime";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

export const EmptyGroup = ({
    label,
    name,
    className,
    isRequired,
    children
}: {
    label: string;
    name: string;
    className?: string;
    isRequired?: boolean;
    children: React.ReactNode;
}) => {
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>
                {label} {isRequired && <span className="text-danger">*</span>}
            </label>
            {children}
        </div>
    );
};

export const InputGroup = ({
    label,
    className,
    isRequired,
    inputClassName,
    type,
    name,
    disabled,
    value,
    onChange,
    onBlur,
    placeholder,
    icon,
    children,
    pattern,
    isInvalid
}: {
    label: string;
    className?: string;
    inputClassName?: string;
    icon?: React.ReactNode;
    type: string;
    name: string;
    disabled: boolean;
    isRequired?: boolean;
    isInvalid?: boolean;
    value: number | string | undefined;
    placeholder?: string;
    onChange: (e: React.ChangeEvent<any>) => void;
    onBlur?: (e: React.ChangeEvent<any>) => void;
    children?: React.ReactNode;
    pattern?: string;
}) => {
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>
                {label} {isRequired && <span className="text-danger">*</span>}
            </label>
            {icon ? (
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">{icon}</div>
                    </div>
                    <input
                        type={type}
                        name={name}
                        className={`form-control ${inputClassName} ${isInvalid ? `is-invalid` : ``} ${isRequired ? `` : `not-required`}`}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        readOnly={disabled}
                        required={isRequired}
                        pattern={pattern}
                    />
                    {children}
                </div>
            ) : (
                    <>
                        <input
                            type={type}
                            name={name}
                            className={`form-control ${inputClassName} ` + (isInvalid ? "is-invalid" : "") + (isRequired ? "" : "not-required")}
                            placeholder={placeholder}
                            disabled={disabled}
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            readOnly={disabled}
                            required={isRequired}
                            pattern={pattern}
                        />
                        {children}
                    </>
                )}
        </div>
    );
};

export const TextareaGroup = ({
    label,
    className,
    isRequired,
    rows,
    inputClassName,
    name,
    disabled,
    value,
    onChange,
    placeholder
}: {
    label: string;
    className?: string;
    inputClassName?: string;
    placeholder?: string;
    name: string;
    rows: number;
    disabled?: boolean;
    isRequired?: boolean;
    value: string | undefined;
    onChange: (e: React.ChangeEvent<any>) => void;
}) => {
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>
                {label}: {isRequired && <span className="text-danger">*</span>}
            </label>
            <textarea
                name={name}
                rows={rows}
                className={`form-control ${inputClassName} ` + (isRequired ? "" : "not-required")}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onChange}
                value={value}
                required={isRequired}
                style={{ resize: "none" }}
            />
        </div>
    );
};

export const DateGroup = ({
    label,
    className,
    invalidFeedBack,
    isRequired,
    inputClassName,
    name,
    disabled,
    value,
    onChange,
    isValidDate,
    icon,
    dateFormat
}: {
    label: string;
    className?: string;
    inputClassName?: string;
    name: string;
    invalidFeedBack?: string;
    disabled: boolean;
    isRequired?: boolean;
    icon?: boolean;
    value: string | Date | undefined;
    dateFormat?: string;
    isValidDate?: (date: any) => any;
    onChange: (date: any) => void;
}) => {
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>
                {label} {isRequired && <span className="text-danger">*</span>}
            </label>
            <div className="input-group mb-2">
                {/* <Datetime
                    dateFormat={dateFormat ? dateFormat : "MM/DD/YYYY"}
                    inputProps={{
                        placeholder: "-",
                        disabled: disabled,
                        readOnly: disabled,
                        required: isRequired ? isRequired : false,
                        className: `form-control border-right-0 rounded-left ${inputClassName} `
                    }}
                    className={`form-control px-0 py-0 border-0`}
                    onChange={onChange}
                    input={true}
                    timeFormat={false}
                    isValidDate={isValidDate}
                    value={value || ""}
                    closeOnSelect={true}
                /> */}
                <div className="input-group-prepend rounded-4" style={{ position: "relative", left: "-2px" }}>
                    <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendar} />
                    </div>
                </div>
            </div>
            {invalidFeedBack && <div className="invalid-feedback">{invalidFeedBack}</div>}
        </div>
    );
};

export const SelectGroup = ({
    label,
    info,
    className,
    isRequired,
    inputClassName,
    name,
    disabled,
    value,
    onChange,
    showOptions,
    isInvalid,
    children,
    multiple
}: {
    label: string;
    className?: string;
    inputClassName?: string | "";
    name: string;
    info?: string;
    disabled?: boolean;
    isInvalid?: boolean;
    isRequired?: boolean;
    value: number | string | null;
    onChange: (e: React.ChangeEvent<any>) => void;
    showOptions?: React.ReactNode;
    children?: React.ReactNode;
    multiple?: boolean | null
}) => {
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>
                {label} {isRequired && <span className="text-danger">*</span>}
            </label>
            <select
                className={`form-control ${inputClassName ? inputClassName : ``} ${isInvalid ? `is-invalid` : ``} ${
                    isRequired ? `` : `not-required`
                    }`}
                name={name}
                disabled={disabled}
                onChange={onChange}
                value={value || ""}
                aria-describedby={info}
                multiple={multiple || false}
            >
                {showOptions}
                {children}
            </select>
            {info && (
                <small id="info" className="form-text text-muted">
                    {" "}
                    {info}{" "}
                </small>
            )}
        </div>
    );
};
