import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faInfo,
    faArchive,
    faTrash,
    faRedo,
    faCalendarPlus,
    faEdit,
    faPlus,
    faBoxOpen
} from "@fortawesome/free-solid-svg-icons";

export const Information = ({ memberType, welcome }: { memberType: "GROWER" | "PACKER" | "MARKETER"; welcome?: boolean }) => (
    <div className="py-3 px-4 pr-5 system-info">
        <h4 className="text-center text-info pb-2">
            {welcome && `Welcome to `}
            Ontario Greenhouse Vegetable Growers (OGVG)
            <br />
            Online Licence Submission System
        </h4>
        <p>
            <strong>
                This system allows you to complete and submit your yearly licence application information. If you submit your application online, you
                are not required to complete the paper application.
            </strong>
        </p>
        <h5 className="text-info mt-2">General Instructions:</h5>
        <ul className="info-list">
            <li>The use of a mid-to-large-size screen is highly recommended for the best user-experience</li>
            <li>The left panel lists the forms and the sections that you are required to complete</li>
            <li>
                You can navigate each page from the left menu or by clicking the <i>“Next”</i>
                <FontAwesomeIcon icon={faArrowRight} className="mx-2" /> and <i>“Back”</i> <FontAwesomeIcon icon={faArrowLeft} className="mx-2" />
                buttons located at the bottom of each page
            </li>
            <li>All the questions are mandatory, unless otherwise specified</li>
            <li>
                You don’t have to complete your application all at once. You can complete your application at different times and edit your entries
                before submitting it.
            </li>
            <li>Once your application is submitted, you will not be able to edit it any more</li>
            <li>After we receive and review your application, we will contact you if we need any further information</li>
            <li>
                You can access these instructions at anytime by clicking the <FontAwesomeIcon icon={faInfo} className="mx-2" /> button located in the
                top right corner of each page.
            </li>
            <li>
                If you prefer to complete your licence application on paper, please contact the office at
                <strong> 519-326-2604 </strong> or
                <a href="mailto:admin@ogvg.com"> admin@ogvg.com </a> to request the paper copy of the application package
            </li>
        </ul>
        <h5 className="text-info mt-2">Specific Instructions:</h5>
        <p>
            <strong>Confirm/Update:</strong> These fields will ask you to confirm or update the value that is on file from the past year. Click
            “Confirm” if the value from last year is still accurate for this licence year. Click <i>“Update”</i> to edit the value.
        </p>
        <ul>
            <li>
                Archive: Information on file from the previous year submission can only be archived. If you need to remove an
                address/contact/traceability code, you are able to do so by clicking the <i>“Archive”</i>
                <FontAwesomeIcon icon={faArchive} className="mx-2" /> button and they will not be displayed again in future years. If necessary, you
                will be able to undo the changes by clicking the <i>“Un-archive”</i> <FontAwesomeIcon icon={faBoxOpen} className="mx-2" /> button
            </li>
            <li>
                Delete: Any new information entered in this application can be deleted by clicking the <i>“Delete”</i>
                <FontAwesomeIcon icon={faTrash} className="mx-2" /> button before submission and will not be displayed in the future.
            </li>
        </ul>

        {memberType === "GROWER" && (
            <>
                <p>
                    <strong>Crop Productions Details:</strong> This section combines the
                    <i> “Greenhouse Area per Commodity Including Walkways” </i> of Form 2 and Form 3. The steps below guide you through the completion
                    of this page.
                </p>
                <ul>
                    <li>
                        <strong>Step 1:</strong> Review the Proprietary Commodities details on file from the previous licence years; then, if
                        necessary, <i>"add a new one"</i> <FontAwesomeIcon icon={faPlus} className="mx-2" />
                    </li>
                    <li>
                        <strong>Step 2:</strong> Click the <i>“Add Range”</i> <FontAwesomeIcon icon={faCalendarPlus} className="mx-2" /> button: A
                        dialog will open and it will ask you to add all the details of your range. It will include variety grown details, propagator’
                        details, plant week, pull week and the expected yield.
                        <ul style={{ listStyleType: "disc !important" }}>
                            <li>
                                If you have multiple rotations of the same variety in the same range, click the
                                <i> “Create a New Rotation for the Same Range”</i> <FontAwesomeIcon icon={faRedo} className="mx-2" /> button: All the
                                fields will be pre-filled with the previous values so you just have to add the new <i>“plant week” </i> and
                                <i>“pull week”</i>
                                details.
                            </li>
                            <li>If you produce multiple varieties, add different ranges with their own details.</li>
                            <li>After a range is created, it is added to the timeline to build your farm profile for the upcoming licence year.</li>
                            <li>
                                You can edit and delete the range details entered at any time by clicking the <i> “Edit” </i>
                                <FontAwesomeIcon icon={faEdit} className="mx-2" /> or <i>“Delete”</i>
                                <FontAwesomeIcon icon={faTrash} className="mx-2" /> buttons.
                            </li>
                        </ul>
                    </li>
                </ul>
            </>
        )}
    </div>
);
