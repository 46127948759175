import * as React from "react";
import { MainNavigation } from "./MainNavigation";
import { Logo } from "./Logo";
import { ButtonLogout } from "./LogoutButton";

export const Sidebar = () => {
    return (
        <nav className="main-sidebar">
            <Logo />
            <MainNavigation />
            <ButtonLogout />
        </nav>
    );
};
