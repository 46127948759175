import { action, computed, observable } from "mobx";
import { ApplicationStore } from "./ApplicationStore";

export class ValidationStore {
    @observable private validationData: {
        [formType: string]: {
            [fieldIdentifier: string]: (store: ApplicationStore) => any;
        };
    } = {};

    offerValidationUpdate(formType: string, fieldIdentifier: string, outcomeFn: (store: ApplicationStore) => any) {
        if (!this.validationData[formType]) {
            this.validationData[formType] = {};
        }
        this.validationData[formType][fieldIdentifier] = outcomeFn;
    }

    checkFormValidity(formType: string, store: ApplicationStore): boolean | undefined {
        // This is the undefined case where we don't know the value yet
        if (!this.validationData[formType]) {
            return undefined;
        }
        return Object.values(this.validationData[formType]).filter(valid => !valid(store)).length === 0;
    }
}
