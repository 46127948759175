import * as React from "react";
import { NavLink } from "react-router-dom";

import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LinkMainMenu = ({ path, title, check }: { path: string; title: string; check?: boolean }) => {
    return (
        <NavLink to={path} className="nav-link-intern d-flex" activeClassName="active">
            {check && <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2 mt-1 text-success" />}
            {title}
            <FontAwesomeIcon icon={faArrowRight} className="link-icon ml-auto my-auto" />
        </NavLink>
    );
};
