import "react-datetime/css/react-datetime.css";

import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import { Redirect, RouteChildrenProps } from "react-router";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { ApplicationTemplate } from "./components/ApplicationTemplate";
import { Login } from "./components/Authentication/Login";
import ScrollToTop from "./components/Common/ScrollToTop";
import CropProductionDetails from "./components/Forms/Grower/CropProductionDetails";
import Form2AGreenhouseOperationInformation from "./components/Forms/Grower/Form2AGreenhouseOperationInformation";
import Form2ProducersInformation from "./components/Forms/Grower/Form2ProducersInformation";
import GrowerSignatureAndSubmission from "./components/Forms/Grower/GrowerSignatureAndSubmission";
import Form5ASalesReporting from "./components/Forms/Marketer/Form5ASalesReporting";
import Form5BBusinessOverview from "./components/Forms/Marketer/Form5BBusinessOverview";
import Form5MarketersInformation from "./components/Forms/Marketer/Form5MarketersInformation";
import MarketerAttachments from "./components/Forms/Marketer/MarketerAttachments";
import MarketerSignatureAndSubmission from "./components/Forms/Marketer/MarketerSignatureAndSubmission";
import Form4PackersInformation from "./components/Forms/Packer/Form4PackersInformation";
import PackerSignatureAndSubmission from "./components/Forms/Packer/PackerSignatureAndSubmission";
import { SubmissionEndpoints } from "./endpoints/SubmissionEndpoints";
import { InterceptedFetch } from "./endpoints/util/InterceptedFetch";
import { ApplicationStore } from "./stores/ApplicationStore";
import { ValidationStore } from "./stores/ValidationStore";

const BuildStoresAndEndpoints = () => {
    const validationStore = new ValidationStore();
    const submissionEndpoints = new SubmissionEndpoints();
    const applicationStore = new ApplicationStore(submissionEndpoints, validationStore);

    submissionEndpoints.setInterceptedFetch(
        InterceptedFetch(
            () => applicationStore.currentToken,
            (token: string) => applicationStore.updateToken(token),
            () => applicationStore.timedOut()
        )
    );

    return { applicationStore, submissionEndpoints, validationStore };
};

const stores = BuildStoresAndEndpoints();

/** Grower */
const RedirectToFormMember = (memberType: "GROWER" | "PACKER" | "MARKETER") => {
    let path = "";

    if (memberType === "GROWER") {
        path = "/grower/form-2-producers-information";
    } else if (memberType === "PACKER") {
        path = "/packer/form-4-packers-information";
    } else {
        path = "/marketer/form-5-marketers-information";
    }

    return <Redirect to={path} />;
};

const SaveKey = (props: RouteChildrenProps<{ key: string | undefined }>) => {
    if (!props || !props.match || !props.match.params.key) {
        console.error("In SaveKey without a key?");
    } else {
        stores.applicationStore.logout(false);
        stores.applicationStore.setLinkKey(props.match.params.key);
    }

    return <Redirect to="/" />;
};

const Form2ProducersInformationWrapper = () => {
    return <Form2ProducersInformation />;
};
const Form2AGreenhouseOperationInformationWrapper = () => {
    return <Form2AGreenhouseOperationInformation />;
};
const CropProductionDetailsWrapper = () => {
    return <CropProductionDetails />;
};
const GrowerSignatureAndSubmissionWrapper = () => {
    return <GrowerSignatureAndSubmission />;
};

/** Packer */
const Form4PackersInformationWrapper = () => {
    return <Form4PackersInformation />;
};
const PackerSignatureAndSubmissionWrapper = () => {
    return <PackerSignatureAndSubmission />;
};

/** Marketer */
const Form5MarketersInformationWrapper = () => {
    return <Form5MarketersInformation />;
};
const Form5ASalesReportingWrapper = () => {
    return <Form5ASalesReporting />;
};
const Form5BBusinessOverviewWrapper = () => {
    return <Form5BBusinessOverview />;
};
const MarketerAttachmentsWrapper = () => {
    return <MarketerAttachments />;
};

const MarketerSignatureandSubmissionWrapper = () => {
    return <MarketerSignatureAndSubmission />;
};

interface Props {
    applicationStore?: ApplicationStore;
}

@inject("applicationStore")
@observer
class Application extends React.Component<Props, {}> {
    render() {
        const widget = this.props.applicationStore!.loggedIn ? this.loggedIn() : this.login();
        return (
            <Router>
                <Route exact={true} path="/login/:key" component={SaveKey} />
                <Route render={() => widget} />
            </Router>
        );
    }

    loggedIn() {
        const memberType = this.props.applicationStore!.submission!.member.memberType;
        return (
            <>
                {/* <TransitionGroup>
                    <CSSTransition key={props.location.pathname} in={props.match != null} timeout={300} classNames="page" unmountOnExit={true}> */}
                <ScrollToTop>
                    <Switch>
                        <ApplicationTemplate>
                            <Route exact={true} path="/grower/form-2-producers-information" component={Form2ProducersInformationWrapper} />
                            <Route
                                exact={true}
                                path="/grower/form-2a-greenhouse-operation-information"
                                component={Form2AGreenhouseOperationInformationWrapper}
                            />
                            <Route exact={true} path="/grower/crop-production-details" component={CropProductionDetailsWrapper} />
                            <Route exact={true} path="/grower/signature-and-submission" component={GrowerSignatureAndSubmissionWrapper} />
                            <Route exact={true} path="/packer/form-4-packers-information" component={Form4PackersInformationWrapper} />
                            <Route exact={true} path="/packer/signature-and-submission" component={PackerSignatureAndSubmissionWrapper} />
                            <Route exact={true} path="/marketer/form-5-marketers-information" component={Form5MarketersInformationWrapper} />
                            <Route exact={true} path="/marketer/form-5a-sales-reporting" component={Form5ASalesReportingWrapper} />
                            <Route exact={true} path="/marketer/page-5b-business-overview" component={Form5BBusinessOverviewWrapper} />
                            <Route exact={true} path="/marketer/attachments" component={MarketerAttachmentsWrapper} />
                            <Route exact={true} path="/marketer/signature-and-submission" component={MarketerSignatureandSubmissionWrapper} />
                            <Route exact={true} path="/" component={() => RedirectToFormMember(memberType)} />
                            {/* <Route exact={true} path="/information" component={InformationSectionWrapper} /> */}
                        </ApplicationTemplate>
                    </Switch>
                </ScrollToTop>
                {/* </CSSTransition>
                </TransitionGroup> */}
            </>
        );
    }

    login() {
        return <Login />;
    }
}

stores.applicationStore.onReady();

export default () => {
    return (
        <Provider {...stores}>
            <Application />
        </Provider>
    );
};
