import { inject, observer } from "mobx-react";
import * as React from "react";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import LoadingWidget from "../Loading";
import { errorMessage } from "../Toast";
import { GenericFormRequest } from "../../../requests/SimpleFieldRequests";

interface State {
    loading: boolean;
}

interface PropsComponent {
    fieldName: string;
    fieldValue: string;
    formType: string;
    onChanged?: () => any;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class FormRadioField extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    updateGenericForm = async (fieldName: string, value: string, formType: string) => {
        const request: GenericFormRequest = {
            form: formType,
            field: fieldName,
            value: value
        };

        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.updateGeneralForm(request);
            if (this.props.onChanged) {
                this.props.onChanged();
            }
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const formValue = this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.fieldName);
        return (
            <div className="radio-field-with-loading" style={{ position: "relative" }}>
                <input
                    className="form-check-input mr-5"
                    type="radio"
                    name={this.props.fieldName}
                    checked={formValue === this.props.fieldValue}
                    disabled={this.state.loading || this.props.applicationStore!.completed}
                    onChange={() => this.updateGenericForm(this.props.fieldName, this.props.fieldValue, this.props.formType)}
                />
                {this.state.loading && <LoadingWidget className="loading-in-radio" />}
            </div>
        );
    }
}
