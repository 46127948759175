import * as React from "react";
export const Disclamer = () => (
    <div className="row">
        <div className="col-12">
            <div className="alert alert-success">
                <small>
                    The OGVG may publish, on a restricted access basis, the information submitted on this form, with said publication being related to
                    OGVG's exercise of its delegated powers and including but not limited to support reporting initiatives, information associated
                    with the producing or marketing of greenhouse vegetables and/or any other requirements as outlined in the OGVG Regulations. The
                    partners, employees and/or agents are required to protect the personal information they receive from OGVG and to comply with the
                    general principles described in our Privacy Policy. OGVG reserves the right to disclose personal information to a third party if a
                    law, regulation, search warrant, subpoena or court order legally requires or authorizes us to do so.
                </small>
            </div>
        </div>
    </div>
);
