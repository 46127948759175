import { inject, observer } from "mobx-react";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ApplicationStore } from "../../stores/ApplicationStore";
import LoadingWidget from "../Common/Loading";
import { Logo } from "../Template/Logo";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const RECAPTCHA_DISABLED = process.env.REACT_APP_RECAPTCHA_DISABLE === "true";

//const TEST_KEY = "abc123"; // Grower N8H 3W1
//const TEST_KEY = "def456"; // Packer
//const TEST_KEY = "ghi789"; // Marketer

export interface Props {
    applicationStore?: ApplicationStore;
}
export interface State {
    postalCode: string;
    errorMessage: React.ReactNode;
    isLoading: boolean;
    wasValidated: boolean;
    reCaptchaResponse: string | null;
}

const DefaultState = (): State => {
    return {
        postalCode: "",
        errorMessage: "",
        isLoading: false,
        wasValidated: false,
        reCaptchaResponse: ""
    };
};

const ErrorMapper = (error: string): string => {
    switch (error) {
        case "Postal code does not belong to this member.":
            return `
            We could not find the entered Postal Code on file. 
            If the problem persists, please call the office at 519-326-2604 to
            have this value updated.`;
        case "Link has been locked due to too many failed login attempts.":
            return `
            There have been too many failed attempts to login for this application.
            Please try again in an hour. If the postal code you are using is not working,
            then please call the office at 519-326-2604 to have this value updated.
            `;
        default:
            return error;
    }
};

@inject("applicationStore")
@observer
export class Login extends React.Component<Props, State> {
    private form: HTMLFormElement | null;
    private reCaptcha: ReCAPTCHA | null;

    constructor(props: Props) {
        super(props);
        this.state = DefaultState();
    }

    submit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (this.state.isLoading === false && this.state.postalCode.trim() !== "" && this.state.reCaptchaResponse) {
            this.setState({ isLoading: true, wasValidated: false, errorMessage: "" });
            this.login();
        } else if (!this.state.reCaptchaResponse) {
            this.setState({
                isLoading: false,
                errorMessage: (
                    <>
                        Please check the <em>"I'm not a robot"</em> box and try again.
                    </>
                )
            });
        } else {
            this.setState({ isLoading: false, wasValidated: true, errorMessage: "Postal Code is required." });
        }
    };

    login = async () => {
        try {
            await this.props.applicationStore!.login(this.state.postalCode, RECAPTCHA_DISABLED ? undefined : this.state.reCaptchaResponse || "");
            this.resetState();
        } catch (error) {
            this.setState({ errorMessage: ErrorMapper(error), isLoading: false, wasValidated: true });
        }
    };

    onRecaptchaResponse = (response: string | null) => {
        this.setState({ reCaptchaResponse: response });
    };

    resetState = () => {
        this.setState(DefaultState());
    };

    render() {
        const flashMessage = this.props.applicationStore!.flashMessage;
        return (
            <div className="wrapperLogin">
                <div className="login shadow">
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-12 text-light text-center">Online Form Submission</div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Logo />
                            </div>
                        </div>
                        {!this.props.applicationStore!.ready && (
                            <div className="row">
                                <div className="login-form-right col-12 text-center text-white">
                                    One moment, please... &nbsp; <LoadingWidget />
                                </div>
                            </div>
                        )}
                        {this.props.applicationStore!.ready && (
                            <div className="row">
                                <div className="login-form-right col-12">
                                    {this.props.applicationStore!.currentLinkKey === null && (
                                        <>
                                            {!flashMessage && (
                                                <div className="alert bg-danger text-white text-center" role="alert">
                                                    For your security, your previous session has timed out.
                                                </div>
                                            )}
                                            {flashMessage && (
                                                <div className="alert bg-warning text-white text-center" role="alert">
                                                    {flashMessage}
                                                </div>
                                            )}
                                            <p className="text-white">
                                                To continue, please return to the email you received and click the link again.
                                            </p>
                                        </>
                                    )}
                                    {this.props.applicationStore!.currentLinkKey !== null && (
                                        <form
                                            onSubmit={e => this.submit(e)}
                                            ref={ref => (this.form = ref)}
                                            className={"needs-validation" + (this.state.wasValidated ? " was-validated" : "")}
                                            noValidate={true}
                                        >
                                            {this.state.errorMessage !== "" && (
                                                <div className="alert bg-danger text-white text-center" role="alert">
                                                    {this.state.errorMessage}
                                                </div>
                                            )}
                                            {flashMessage && (
                                                <div className="alert bg-warning text-white text-center" role="alert">
                                                    {flashMessage}
                                                </div>
                                            )}
                                            <p className="text-white text-justify mb-5">
                                                To confirm your identity, please enter any Postal Code associated to your operation that the OGVG has
                                                on file. This can be the Postal Code of any of your mailing or physical address(es).
                                            </p>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                        </div>
                                                    </div>
                                                    <input
                                                        value={this.state.postalCode}
                                                        onChange={e => this.setState({ postalCode: e.target.value })}
                                                        name="postalCode"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        placeholder="Postal Code"
                                                        required={true}
                                                        disabled={this.state.isLoading}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <ReCAPTCHA
                                                    theme="light"
                                                    size="normal"
                                                    // className="captcha"
                                                    ref={ref => (this.reCaptcha = ref)}
                                                    sitekey={RECAPTCHA_SITE_KEY || ""}
                                                    onChange={response => this.onRecaptchaResponse(response)}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block">
                                                {this.state.isLoading ? (
                                                    <>
                                                        <LoadingWidget className="mr-2" /> Logging in...
                                                    </>
                                                ) : (
                                                    "Login"
                                                )}
                                            </button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
