import * as React from "react";
import { inject, observer } from "mobx-react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import { ValidationStore } from "../../../stores/ValidationStore";

import FormTextareaField from "./FormTextareaField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export interface Fields {
    label: string;
}

interface State {
    otherValue: string;
}

interface Props {
    applicationStore?: ApplicationStore;
    validationStore?: ValidationStore;

    label: string;
    inputClassName: string;
    formType: string;
    fieldName: string;
    info?: string;
    type?: string;
    required?: boolean;
}

@inject("validationStore")
@inject("applicationStore")
@observer
export default class SimpleTextareaWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            otherValue: ""
        };
    }

    componentDidMount() {
        if (this.props.required) {
            const { formType, label, fieldName } = this.props;
            this.props.validationStore!.offerValidationUpdate(formType, label, (store: ApplicationStore) => {
                const applicationStore = this.props.applicationStore!;
                return !!applicationStore.findGeneralFormValue(formType, fieldName);
            });
        }
    }

    handleValidation = () => {
        const { formType, fieldName } = this.props;
        const store = this.props.applicationStore!;
        return !!store.findGeneralFormValue(formType, fieldName);
    };

    render() {
        const isValid = this.props.required ? this.handleValidation() : false;
        return (
            <div className="card shadow">
                <div className="card-header">
                    <div className={`d-flex ${isValid ? `text-success` : ``}`}>
                        <div>
                            {this.props.label}
                            {this.props.required ? <span className="text-danger"> * </span> : ""}
                        </div>
                        <div className="ml-auto">{isValid && <FontAwesomeIcon icon={faCheck} className="mr-2" />}</div>
                    </div>
                </div>

                <div className="card-body">
                    <form>
                        <div className="form-row col-12 col-lg-8 mx-auto">
                            <div className={`${this.props.inputClassName} mb-3 mr-5`}>
                                <FormTextareaField formType={this.props.formType} fieldName={this.props.fieldName} />
                                {this.props.info && (
                                    <small id="emailHelp" className="form-text text-muted">
                                        {this.props.info}
                                    </small>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
