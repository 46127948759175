import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import Dropzone from "react-dropzone";
import { UncontrolledTooltip } from "reactstrap";

import { faCloudUploadAlt, faDownload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SubmissionEndpoints } from "../../endpoints/SubmissionEndpoints";
import { AttachmentFile } from "../../model/Attachments";
import { ApplicationStore } from "../../stores/ApplicationStore";
import LoadingWidget from "./Loading";

interface Props {
    applicationStore?: ApplicationStore;
    submissionEndpoints?: SubmissionEndpoints;
    attachments: Array<AttachmentFile>;
    addAttachment: (file: File) => Promise<any>;
    deleteAttachment: (attachmentId: number) => void;
    getAttachment: (fileName: string, attachmentId: number) => void;
}

export interface State {
    loading: boolean;
}

@inject("applicationStore")
@observer
export default class AttachmentWidget extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    render() {
        const attachments = this.props.attachments || [];
        const disabled = this.state.loading; // this.props.attachments !== null ? true : false;
        return (
            <>
                <div className="form-row mt-3">
                    <div className="form-group col-sm-12 col-md-12">
                        <Dropzone
                            onDrop={async acceptedFiles => {
                                this.setState({ loading: true });
                                try {
                                    for (let i = 0; i < acceptedFiles.length; i++) {
                                        await this.props.addAttachment(acceptedFiles[i]);
                                    }
                                } finally {
                                    this.setState({ loading: false });
                                }
                            }}
                            multiple={false}
                            disabled={disabled}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} className="py-3 rounded attachmentLog">
                                        <input {...getInputProps()} />
                                        <p className="text-center text-secondary mb-1">
                                            {!this.state.loading && (
                                                <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" className="mt-2" style={{ opacity: 0.6 }} />
                                            )}
                                            {this.state.loading && <LoadingWidget />}
                                        </p>
                                        <p className="text-center text-secondary" style={{ opacity: 0.6 }}>
                                            Drag &amp; drop here or click to upload files
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-12 col-md-12 mt-3">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>File Name</th>
                                    <th>Date Updated</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attachments.length === 0 ? (
                                    <tr>
                                        <td colSpan={3} align="center">
                                            <span>No files attached.</span>
                                        </td>
                                    </tr>
                                ) : (
                                    attachments.map(attachment => {
                                        return (
                                            <tr key={attachment.id}>
                                                <td>{attachment.name}</td>
                                                <td>{`${moment(attachment.dateUploaded).format("YYYY-MM-DD hh:mm a")}`}</td>
                                                <td style={{ width: "100px" }}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-dark btn-sm mr-2"
                                                        id={`tooltipDownload_${attachment.id}`}
                                                        title="Download File"
                                                        onClick={() => this.props.getAttachment(attachment.name, attachment.id)}
                                                    >
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </button>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={`tooltipDownload_${attachment.id}`}
                                                        delay={0}
                                                        className="tooltipstrap"
                                                    >
                                                        Download File
                                                    </UncontrolledTooltip>

                                                    {attachment.applicationSourced && (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-sm"
                                                                id={`tooltipDelete_${attachment.id}`}
                                                                title="Delete File"
                                                                onClick={() => this.props.deleteAttachment(attachment.id)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={`tooltipDelete_${attachment.id}`}
                                                                delay={0}
                                                                className="tooltipstrap"
                                                            >
                                                                Delete File
                                                            </UncontrolledTooltip>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}
