import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowRight, faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface Props {
    required?: boolean;
    completed?: boolean;
    title: string;
    children: React.ReactNode;
    name: string;
    currentAccordion: string;
    onClick: (name: string) => void;
    previousAccordion?: string;
    nextAccordion?: string;
    nextPage?: string;
    backPage?: string;
}

interface State {
    currentHeight: number;
}

export default class Accordion extends React.Component<Props, State> {
    private divChildrenAccordion: HTMLDivElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentHeight: 0
        };
    }

    componentDidMount() {
        if (this.divChildrenAccordion) {
            const height = this.divChildrenAccordion.offsetHeight + 10;
            this.setState({ currentHeight: height });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const height = this.divChildrenAccordion!.offsetHeight + 10;
        if (prevState.currentHeight !== height) {
            this.setState({ currentHeight: height });
        }
        if (this.props.currentAccordion === this.props.name && prevProps.currentAccordion !== this.props.name) {
            window.scrollTo(0, 300);
        }
    }

    render() {
        const showAccordion = this.props.currentAccordion === this.props.name ? true : false;
        return (
            <div className="card accordion">
                <button className="card-header d-flex text-left" onClick={() => this.props.onClick(this.props.name)}>
                    {this.props.completed && (
                        <span>
                            <FontAwesomeIcon className="text-success mr-3" icon={faCheck} />
                        </span>
                    )}
                    {this.props.title} {this.props.required === true ? <span className="text-light">&nbsp;*</span> : ``}
                    <FontAwesomeIcon icon={faAngleRight} rotation={showAccordion ? 270 : 90} className="ml-auto mt-1" />
                </button>
                <div
                    className="card-body py-0"
                    style={showAccordion ? { height: this.state.currentHeight + "px" /*, overflowY: "scroll"*/ } : { height: 0 }}
                >
                    <div className="py-2 accordion-content d-block" ref={ref => (this.divChildrenAccordion = ref)}>
                        <div className="row">
                            <div className="col-12">{this.props.children}</div>
                        </div>
                        <div className="row my-3">
                            <div className="col-12 text-right">
                                {this.props.previousAccordion && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary px-3 mr-2"
                                        onClick={() => this.props.onClick(this.props.previousAccordion!)}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back
                                    </button>
                                )}
                                {this.props.backPage && (
                                    <Link to={this.props.backPage} className="btn btn-secondary px-3 mr-2">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back
                                    </Link>
                                )}
                                {this.props.nextAccordion && (
                                    <button type="button" className="btn btn-info px-3" onClick={() => this.props.onClick(this.props.nextAccordion!)}>
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                    </button>
                                )}
                                {this.props.nextPage && (
                                    <Link to={this.props.nextPage} className="btn btn-info px-3">
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
