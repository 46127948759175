import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";

import { faArrowLeft, faHandPaper, faSave, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MarketerSubmission } from "../../../model/Submission";
import { MarketerValidationRules } from "../../../model/validation/MarketerValidation";
import { EvaluateAllFormRulesToMessages, EvaluateAllRulesToMessages } from "../../../model/validation/Validation";
import { ApplicationStore } from "../../../stores/ApplicationStore";
import { Disclamer } from "../../Common/Disclamer";
import { InputGroup } from "../../Common/form-elements/SimpleFormComponents";
import LoadingWidget from "../../Common/Loading";
import SimpleTextareaField from "../../Common/simple-fields/SimpleTextareaField";
import { SimpleAlert } from "../../Common/SimpleConfirmAlert";
import { errorMessage, successMessage } from "../../Common/Toast";
import ContentHeader from "../../Template/ContentHeader";

interface Props {
    applicationStore?: ApplicationStore;
}

interface State {
    loading: boolean;
    wasValidated: boolean;
    signatureName: string;
    secondSignatureName: string;
}

@inject("applicationStore")
@observer
export default class MarketerSignatureAndSubmission extends React.Component<Props, State> {
    private form: HTMLFormElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            wasValidated: false,
            signatureName: "",
            secondSignatureName: ""
        };
    }

    finalizeApplication = async () => {
        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.finalizeSubmission({
                signatureName: this.state.signatureName,
                secondSignatureName: this.state.secondSignatureName
            });
            SimpleAlert(
                <>
                    <p>Thank you, your application has been received. We will be in touch if we have any questions. </p>
                    <p>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                window.open(`${this.props.applicationStore!.submission!.pdfReceiptLink}?download=true`);
                            }}
                        >
                            Please download a copy of your submitted values by clicking here.
                        </a>
                    </p>
                    <p>After downloading your submission you can safely close this tab.</p>
                </>
            );
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    submit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (this.form && this.form.checkValidity()) {
            this.setState({ wasValidated: false });
            this.finalizeApplication();
        } else {
            this.setState({ wasValidated: true });
        }
    };

    render() {
        const applicationStore = this.props.applicationStore!;
        const completed = (applicationStore.submission as MarketerSubmission).completed;
        const member = applicationStore.submission!.member;
        const currentDate = moment(new Date()).format("MM/DD/YYYY");
        const { form5Validated, marketerAttachmentsValidated, form5AValidated, form5BValidated } = this.props.applicationStore!;
        return (
            <div className="main-content">
                <ContentHeader name={member.businessName} pageTitle="Signature and Submission" />
                <div className="row content">
                    <div className="col-12 mt-3">
                        {!form5Validated && (
                            <div className="alert alert-danger">
                                <div className="row">
                                    <div className="col-12">
                                        <FontAwesomeIcon icon={faHandPaper} />
                                        &nbsp; There are data missing on &nbsp;
                                        <Link to="/marketer/form-5-marketers-information">Form 5 - Marketer's Information</Link>. Please return to
                                        that page and fill in the following sections to continue:
                                        <ul className="mt-3">
                                            {EvaluateAllFormRulesToMessages(MarketerValidationRules.form5, applicationStore.submission!).map(
                                                (msg, idx) => (
                                                    <li key={idx}>{msg}</li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                        {marketerAttachmentsValidated === false && (
                            <div className="alert alert-danger">
                                <FontAwesomeIcon icon={faHandPaper} />
                                &nbsp; There are data missing on the <Link to="/marketer/attachments">Attachments page</Link>. Please return to that
                                page and fill in all fields before continuing.
                            </div>
                        )}
                        {/*Removed Form 5A and 5B for Marketers as they are no longer needed*/}
                        {/*{form5AValidated === false && (*/}
                        {/*    <div className="alert alert-danger">*/}
                        {/*        <FontAwesomeIcon icon={faHandPaper} />*/}
                        {/*        &nbsp; There are data missing on the <Link to="/marketer/form-5a-sales-reporting">Form 5A - Sales Reporting</Link>.*/}
                        {/*        Please return to that page and fill in all fields before continuing.*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{form5BValidated === false && (*/}
                        {/*    <div className="alert alert-danger">*/}
                        {/*        <FontAwesomeIcon icon={faHandPaper} />*/}
                        {/*        &nbsp; There are data missing on the <Link to="/marketer/page-5b-business-overview">Form 5B - Business Overview</Link>*/}
                        {/*        . Please return to that page and fill in all fields before continuing.*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {form5Validated && marketerAttachmentsValidated
                            // && form5AValidated && form5BValidated
                            && (
                            <div className="alert alert-warning text-center">
                                Please review the information entered in the previous sections. Once the application is submitted, it cannot be edited
                                anymore.
                            </div>
                        )}
                    </div>
                    {form5Validated && marketerAttachmentsValidated
                        // && form5AValidated && form5BValidated
                        && (
                        <>
                            <div className="col-12 mt-3">
                                <div className="card shadow">
                                    <div className="card-header">Signature & Submission</div>
                                    <div className="card-body">
                                        <form
                                            id="signature-and-submission"
                                            ref={form => (this.form = form)}
                                            className={"needs-validation" + (this.state.wasValidated ? " was-validated" : "")}
                                            onSubmit={e => this.submit(e)}
                                            noValidate={true}
                                        >
                                            <div className="row mb-0">
                                                <div className="col-12 text-center">
                                                    <div className="alert alert-info">
                                                        The undersigned agrees to engage in the selling of greenhouse vegetables in accordance with
                                                        the provisions of the Farm Products Marketing Act, the regulations under the Farm Products
                                                        Marketing Act, the regulations of the local board and all other regulations, orders and
                                                        directions from the local board or from the Ontario Farm Products Marketing Commission, and
                                                        understands that in default thereof any license to sell greenhouse vegetables may be suspended
                                                        or revoked by the local board. The undersigned also agrees to submit to the local board proof
                                                        of ownership of any proprietary variety (a variety that is held exclusively by one
                                                        individual(s) or individual business(es)) and a list of producer names and addresses who have
                                                        been selected to produce said proprietary variety. The applicant also agrees to regular and
                                                        random audits deemed as required to ensure the applicable regulations and reporting
                                                        requirements are complied with.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-4">
                                                <div className="col-6">
                                                    <InputGroup
                                                        name="signatureName"
                                                        type="text"
                                                        label="Name of Applicant:"
                                                        isRequired={true}
                                                        disabled={this.props.applicationStore!.completed}
                                                        onChange={e => this.setState({ signatureName: e.target.value })}
                                                        value={
                                                            completed && completed.signatureName
                                                                ? completed.signatureName
                                                                : this.state.signatureName || ""
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label>Date:</label>
                                                    <input className="form-control" type="text" value={currentDate} readOnly={true} />
                                                </div>
                                            </div>
                                            <div className="row pt-5">
                                                <div className="col-12 text-center">
                                                    <div className="alert alert-info">
                                                        The Applicant Marketer hereby designates the following individual or organization as the
                                                        contact person whom the local board or its appointed third party is to contact for the purpose
                                                        of securing data and checking the veracity of such data. The data collected is to be used
                                                        solely to further the purposes of the local board.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    {/* <SimpleInputField label="Name of Applicant:" fieldName="secondApplicantName" /> */}
                                                    <InputGroup
                                                        name="secondApplicantNsecondSignatureNameame"
                                                        type="text"
                                                        label="Name of Applicant:"
                                                        isRequired={true}
                                                        disabled={this.props.applicationStore!.completed}
                                                        onChange={e => this.setState({ secondSignatureName: e.target.value })}
                                                        value={
                                                            completed && completed.secondSignatureName
                                                                ? completed.secondSignatureName
                                                                : this.state.secondSignatureName || ""
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label>Date:</label>
                                                    <input className="form-control" type="text" value={currentDate} readOnly={true} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <SimpleTextareaField label="Notes:" rows={3} fieldName="finalNotes" />
                                                </div>
                                            </div>
                                            <div className="row pt-4">
                                                <div className="col-12 text-center">
                                                    <Link to="/marketer/attachments" className="btn btn-secondary px-3">
                                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                                                    </Link>
                                                    <button
                                                        type="submit"
                                                        disabled={this.props.applicationStore!.completed}
                                                        className="btn btn-primary px-3 ml-3"
                                                        form="signature-and-submission"
                                                    >
                                                        {this.state.loading ? (
                                                            <div className="text-center">
                                                                <LoadingWidget className="mr-2" />
                                                                Submitting Application...
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <FontAwesomeIcon icon={faSave} className="mr-3" />
                                                                {completed && completed.dateCompleted > 0
                                                                    ? `Application Submitted`
                                                                    : `Submit Application`}
                                                            </>
                                                        )}
                                                    </button>
                                                    {this.props.applicationStore!.completed && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-success px-3 ml-3"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${this.props.applicationStore!.submission!.pdfReceiptLink}?download=true`
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faDownload} /> Download PDF of Responses
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <Disclamer />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
