import { Fetch } from "./util/InterceptedFetch";
import { BuildUri } from "./util/BuildUri";
import { LoginRequest } from "../requests/LoginRequest";
import { Submission } from "../model/Submission";
import { Configuration } from "../model/Configuration";
import { FinalizeRequest } from "../requests/FinalizeRequest";
import {
    GenericFormRequest,
    ConfirmUpdateUpdatedRequest,
    ConfirmUpdateOutcomeRequest,
    ConfirmUpdateExpectedRequest
} from "../requests/SimpleFieldRequests";
import { FutureExpansionRequest } from "../requests/FutureExpansionRequest";
import { AttachmentRequest } from "../requests/AttachmentRequest";
import { ProprietaryCommodityRequest } from "../requests/ProprietaryCommodityRequest";
import { CropProductionRequest } from "../requests/CropProductionRequest";

export interface TokenResponse {
    token: string;
}

export class SubmissionEndpoints {
    private _fetch: Fetch | null = null;
    private get fetch(): Fetch {
        return this._fetch!;
    }

    login(request: LoginRequest): Promise<Submission> {
        return this.fetch(BuildUri("/login"), {
            method: "POST",
            body: request,
            ignoreUnauthorized: true
        });
    }

    setInterceptedFetch(fetch: Fetch) {
        this._fetch = fetch;
    }

    // All of the below require a token header to work:
    refreshConfiguration(): Promise<Configuration> {
        return this.fetch(BuildUri("/configuration"));
    }

    getSubmission(): Promise<Submission> {
        return this.fetch(BuildUri("/submission"));
    }

    finalizeSubmission(request: FinalizeRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/complete/"), {
            method: "POST",
            body: request
        });
    }

    updateSimpleFields(request: { [fieldName: string]: string }): Promise<Submission> {
        return this.fetch(BuildUri("/submission/simple"), {
            method: "PATCH",
            body: request
        });
    }

    updateConfirmUpdateOutcome(request: ConfirmUpdateOutcomeRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/confirm-update/outcome"), {
            method: "PATCH",
            body: request
        });
    }
    updateConfirmUpdateExpected(request: ConfirmUpdateExpectedRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/confirm-update/expected"), {
            method: "PATCH",
            body: request
        });
    }
    updateConfirmUpdateUpdated(request: ConfirmUpdateUpdatedRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/confirm-update/value"), {
            method: "PATCH",
            body: request
        });
    }

    updateGenericForm(request: GenericFormRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/genform"), {
            method: "PATCH",
            body: request
        });
    }

    createProprietaryCommodity(request: ProprietaryCommodityRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/commodity"), {
            method: "POST",
            body: request
        });
    }

    updateProprietaryCommodity(proprietaryCommodityId: number, request: ProprietaryCommodityRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/commodity/{proprietaryCommodityId}", { proprietaryCommodityId }), {
            method: "PUT",
            body: request
        });
    }

    deleteProprietaryCommodity(proprietaryCommodityId: number): Promise<Submission> {
        return this.fetch(BuildUri("/submission/commodity/{proprietaryCommodityId}", { proprietaryCommodityId }), { method: "DELETE" });
    }

    createCrop(request: CropProductionRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/crop"), {
            method: "POST",
            body: request
        });
    }

    updateCrop(cropId: number, request: CropProductionRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/crop/{cropId}", { cropId: cropId }), {
            method: "PUT",
            body: request
        });
    }

    deleteCrop(cropId: number): Promise<Submission> {
        return this.fetch(BuildUri("/submission/crop/{cropId}", { cropId }), { method: "DELETE" });
    }

    createFutureExpansion(request: FutureExpansionRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/future-expansion"), {
            method: "POST",
            body: request
        });
    }

    updateFutureExpansion(futureExpantionId: string, request: FutureExpansionRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/future-expansion/{futureExpantionId}", { futureExpantionId }), {
            method: "PUT",
            body: request
        });
    }

    deleteFutureExpansion(futureExpantionId: string): Promise<Submission> {
        return this.fetch(BuildUri("/submission/future-expansion/{futureExpantionId}", { futureExpantionId }), { method: "DELETE" });
    }

    addAttachment(request: AttachmentRequest): Promise<Submission> {
        return this.fetch(BuildUri("/submission/attachment/"), {
            method: "POST",
            body: request
        });
    }

    deleteAttachment(attachmentId: number): Promise<Submission> {
        return this.fetch(BuildUri("/submission/attachment/{attachmentId}", { attachmentId }), { method: "DELETE" });
    }

    getAttachment(attachmentId: number): Promise<Blob> {
        return this.fetch(BuildUri("/submission/attachment/{attachmentId}", { attachmentId }), { returnBlob: true });
    }
}
