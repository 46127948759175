import * as React from "react";
import { observer, inject } from "mobx-react";
import { ApplicationStore } from "../../stores/ApplicationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export const ButtonLogout = inject("applicationStore")(
    observer(({ applicationStore }: { applicationStore?: ApplicationStore }) => {
        return (
            <button type="button" onClick={() => applicationStore!.logout()} className="btn btn-outline-light btn-block shadow button-logout">
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                Logout
            </button>
        );
    })
);
