import { inject, observer } from "mobx-react";
import * as React from "react";

import { ApplicationStore } from "../../../stores/ApplicationStore";
import LoadingWidget from "../Loading";
import { errorMessage } from "../Toast";
import { GenericFormRequest } from "../../../requests/SimpleFieldRequests";
import TextareaField from "../form-elements/TextareaField";

interface State {
    loading: boolean;
}

interface PropsComponent {
    label?: string;
    fieldName: string;
    formType: string;
    className?: string;
    rows?: number;
}

interface PropsStore {
    applicationStore?: ApplicationStore;
}

type Props = PropsComponent & PropsStore;

@inject("applicationStore")
@observer
export default class FormTextareaField extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    updateGeneralForm = async (fieldName: string, value: string, formType: string) => {
        const request: GenericFormRequest = {
            form: formType,
            field: fieldName,
            value: value
        };

        try {
            this.setState({ loading: true });
            await this.props.applicationStore!.updateGeneralForm(request);
            //successMessage(`${text} has been successfully updated.`);
        } catch (error) {
            errorMessage(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const formValue = this.props.applicationStore!.findGeneralFormValue(this.props.formType, this.props.fieldName);
        return (
            <div className="input-field-with-loading" style={{ position: "relative" }}>
                <TextareaField
                    fieldName={this.props.fieldName}
                    rows={this.props.rows}
                    className={`${this.props.className} ${this.state.loading ? `input-with-loading` : ``}`}
                    notCheckUpdated={true}
                    value={formValue !== undefined && formValue !== "undefined" ? formValue || "" : ""}
                    onBlur={newValue => this.updateGeneralForm(this.props.fieldName, newValue, this.props.formType)}
                    disabled={this.state.loading || this.props.applicationStore!.completed}
                />
                {this.state.loading && <LoadingWidget className="loading-in-input" />}
            </div>
        );
    }
}
