import { toast } from "react-toastify";

export const errorMessage = async (error: any, autoClose: number | false = false) => {
    let msg = "We were unable to connect to our server. Please refresh the page and try again.";
    if (error.text) {
        msg = await error.text();
        toast.error(msg, { autoClose });
    } else if (error) {
        msg = error.messages || error.message || error.text || error;
        toast.error(msg, { autoClose });
    }
};

export const successMessage = (message: React.ReactNode) => {
    toast.success(message);
};
